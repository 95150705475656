import React, { useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button          from '@components/Button'
import DatePicker      from '@form/DatePicker'
import Input           from '@form/Input'
import MultiselectItem from '@form/Select/MultiselectItem'
import QuestionForm    from '@components/FormQuestion/Form'
import Select          from '@form/Select'
import SelectDoerForm  from '@components/User/SelectDoerForm'
import Textarea        from '@form/Textarea'
import WorkPeriod      from '@components/WorkPeriod'

import * as FormStyle            from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { TaskFormProps } from './types.d'

import { TASK_ACTIONS, taskReducer } from '@reducers/taskReducer'
import Form from '@components/Form/Form'

const TaskForm: React.FC<TaskFormProps> = ({
  report,
  task,
  assignTo,
  // updateTask,
  updatePeriod,
  deletePeriod,
  updateMethods
}) => {

  const {
    i18n,
    initAPI,
    current_company,
    setModalInfoWindowProps,
    fetchApi,
    closeModal,
  } = useGlobalContextState()

  const [_state, dispatch] = useReducer(taskReducer, { report, fetchApi, closeModal, updateMethods })

  const API = initAPI({ reportId: report.id })

  const [showUserForm, setShowUserForm] = useState(!task ? !!report?.suggested_user : !task?.work_periods)

  const [formsTemplates,    setFormsTemplates]    = useState([])
  const [formsSelected,     setFormsSelected]     = useState(task ? task.forms : [])
  const [formsCreated,      setFormsCreated]      = useState([])
  const [showQuestionForm,  setShowQuestionForm]  = useState(false)
  const [invoicingSelected, setInvoicingSelected] = useState(task ? [task.invoice_contact].filter(e => e) : (report.unit?.invoice_contact ? [report.unit.invoice_contact] : []))

  const addFormTemplates = forms => setFormsTemplates([...formsTemplates, ...forms.map(f => f.object)])
  const addForm = form => {
    setFormsCreated([...formsCreated, form])
    setShowQuestionForm(false)
  }
  const removeFormTemplate = form => setFormsTemplates(formsTemplates.filter(f => f.id !== form.id))
  const removeFormSelected = form => setFormsSelected(formsSelected.filter(f => f.id !== form.id))
  const removeFormCreated  = form => setFormsCreated(formsCreated.filter(f => f.tempId !== form.tempId))

  const updateTask     = (data, fieldset) => {
    const taskData = { ...data }
    taskData.form_templates = formsTemplates
    taskData.forms_selected = formsSelected.map(f => f.id).join(',')
    taskData.forms_created  = formsCreated

    dispatch({
      type:      TASK_ACTIONS.UPDATE,
      data:      taskData,
      task,
      fieldset,
      callbacks: [
        () => API.fetchReport().then(updateMethods.report),
        closeModal
      ]})
  }

  const createTask = (data, fieldset) => {
    const taskData = { ...data }
    taskData.form_templates = formsTemplates
    taskData.forms_selected = formsSelected.map(f => f.id).join(',')
    taskData.forms_created  = formsCreated

    return dispatch({
      type:      TASK_ACTIONS.CREATE,
      data:      taskData,
      report,
      fieldset,
      callbacks: [
        () => API.fetchReport().then(updateMethods.report)
      ],
      followUps: [() => setModalInfoWindowProps({})]
    })
  }

  const callback = task?.id ? updateTask : createTask

  const defaultPeriod = useMemo(() => {
    if (!task?.id && report.maintenance_start_task) {
      const startDate = new Date(report.maintenance_start_task)
      const stopDate  = new Date(report.maintenance_start_task)
      if (task?.duration) { stopDate.setSeconds(stopDate.getSeconds() + task.duration) }
      return [startDate, stopDate]
    } else if (task?.planned_start && task?.planned_end) {
      return [task.planned_start, task.planned_end]
    } else {
      return []
    }
  }, [])

  return(
    <Form callback = {callback}>
      <FormStyle.Header>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('shared.general_informations')}
      </FormStyle.Header>
      <Input
        type         = 'text'
        name         = 'title'
        label        = {i18n.t('shared.name')}
        defaultValue = {task ? task.title : report.title}
        marginY      = 'M'
        required
      />
      <Textarea
        name         = 'description'
        label        = {i18n.t('shared.description')}
        defaultValue = {task ? task.description : report.tasks.length ? '' : report.description}
        marginY      = 'M'
      />

      {current_company.permissions.see_estimated_time &&
        <>
          <FormStyle.Label>{i18n.t('todo.expected_duration')}</FormStyle.Label>
          <FormStyle.Group marginY="M">
            <Input
              name         = 'hours'
              label        = {i18n.t('shared.hours')}
              type         = 'number'
              defaultValue = {task ? Math.floor(task.duration / 3600) : undefined}
            />
            <Input
              name         = 'minutes'
              label        = {i18n.t('shared.minutes')}
              type         = 'number'
              min          = {0}
              max          = {59}
              maxLength    = {2}
              defaultValue = {task ? Math.floor((task.duration / 60) % 60) : undefined}
            />
          </FormStyle.Group>
        </>
      }

      {report.permissions.can_assign_task &&
        <>
          <FormStyle.Header marginY='M'>
            <FontAwesomeIcon icon="users" />{i18n.t('todo.actions.assign_to')}
          </FormStyle.Header>
          {task?.id && task.work_periods.map(period =>
            <WorkPeriod
              key          = {period.id}
              task         = {task}
              period       = {period}
              updatePeriod = {updatePeriod}
              deletePeriod = {deletePeriod}
              editable     = {task.permissions.unassign_task}
            />
          )}
          <Button
            icon    = {<FontAwesomeIcon icon={showUserForm ? 'angle-down' : 'plus' } />}
            click   = {() => setShowUserForm(!showUserForm)}
            marginY = 'S'
            color   = 'var(--rep-success)'
            // border  = {showUserForm ? '' : 'var(--rep-success)'}
          >
            {i18n.t('todo.actions.assign_task')}
          </Button>

          {showUserForm &&
            <SelectDoerForm
              assignedTo    = {assignTo ? assignTo : null}
              defaultUsers  = {task?.users || (report?.suggested_user?.type === 'internal' && [report?.suggested_user?.selected]) || []}
              defaultPeriod = {defaultPeriod}
              suggested     = {report?.suggested_user}
              showTime      = {current_company.permissions.dispatch_task_with_hours || !!report.maintenance_start_task}
            />
          }
        </>
      }

      {current_company.permissions.can_see_forms &&
        <>
          <FormStyle.Header marginY='M'>
            <FontAwesomeIcon icon="list-check" />
            {i18n.t('form.forms')}
          </FormStyle.Header>

          <Select
            label        = {i18n.t('form.form_templates')}
            name         = 'forms'
            searchUrl    = '/forms/search'
            defaultFilter={'archived'}
            filters      = {[{
              id:      'archived',
              name:    'archived',
              filters: { archived: false }
            }]}
            defaultValue = {[]}
            callback     = {addFormTemplates}
            format       = {{ content: 'name', value: 'id', details: '' }}
            multiselect  = {true}
            placeholder  = {i18n.t('form.your_forms')}
            marginY      = 'S'
            search
            cleanAfterSelect
          />
          {formsTemplates.map(form =>
            <MultiselectItem
              key          = {form.id}
              name         = {form.name}
              icon         = {<FontAwesomeIcon icon="file-lines" />}
              confirmText  = {i18n.t('actions.confirm_delete')}
              removeAction = {() => removeFormTemplate(form)}
              click        = {() => current_company.beta_access && setModalInfoWindowProps({...form, resourceType: 'forms', fromModal: true })}
            />
          )}
          {formsSelected.map(form =>
            <MultiselectItem
              key          = {form.id}
              name         = {form.name}
              icon         = {<FontAwesomeIcon icon="file-lines" />}
              confirmText  = {i18n.t('actions.confirm_delete')}
              removeAction = {() => removeFormSelected(form)}
              click        = {() => current_company.beta_access && setModalInfoWindowProps({...form, resourceType: 'forms', fromModal: true })}
            />
          )}
          {formsCreated.map(form =>
            <MultiselectItem
              key          = {form.tempId}
              name         = {form.name}
              icon         = {<FontAwesomeIcon icon="file-circle-plus" />}
              confirmText  = {i18n.t('actions.confirm_delete')}
              removeAction = {() => removeFormCreated(form)}
              click        = {() => current_company.beta_access && setModalInfoWindowProps({...form, resourceType: 'forms', fromModal: true })}
            />
          )}
          <Button
            icon    = {<FontAwesomeIcon icon={showQuestionForm ? 'angle-down' : 'plus'} />}
            click   = {() => setShowQuestionForm(!showQuestionForm)}
            marginY = 'S'
            color   = 'var(--rep-success)'
          >
            {i18n.t('form.actions.create')}
          </Button>
          {showQuestionForm && <QuestionForm addForm={addForm} />}
        </>
      }

      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="receipt" />{i18n.t('invoice_contact.invoice_contact')}
      </FormStyle.Header>

      <Select
        name         = 'invoice_contact'
        searchUrl    = '/invoice_contacts/search'
        defaultValue = {invoicingSelected}
        callback     = {invoicing => setInvoicingSelected([invoicing.object])}
        format       = {{ content: 'name', value: 'id', details: 'add_name' }}
        placeholder  = {i18n.t('invoice_contact.your_invoice_contacts')}
        marginY      = 'S'
        search
        withEmpty
      />

      <FormStyle.Header marginY='S'>
        <FontAwesomeIcon icon="receipt" />{i18n.t('todo.todo_expected_date')}
      </FormStyle.Header>

      <DatePicker
        name     = 'planned_end'
        date     = {task?.planned_end}
        tooltip  = {task?.work_periods?.length && i18n.t('todo.tooltip.automatic_planned_end')}
        disabled = {task?.work_periods?.length}
      />
    </Form>
  )
}

export default TaskForm
