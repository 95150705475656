import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Tree } from 'antd'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button          from '@components/Button'
import Callout         from '@components/Callout'
import DatePicker      from '@form/DatePicker'
import MultiselectItem from '@form/Select/MultiselectItem'
import SelectTree      from '@components/Form/SelectTree'
import Toggle          from '@components/Toggle'

import { useGlobalContextState } from '@context/GlobalContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import * as FormStyle from '@components/Form/FormStyles'

import IMaintenancePlan      from '@interfaces/IMaintenancePlan.d'
import IMaintenancePlanGroup from '@interfaces/IMaintenancePlanGroup.d'

interface PlanFormEquipmentsProps {
  data?:      IMaintenancePlan | IMaintenancePlanGroup
  setData?:   React.Dispatch<React.SetStateAction<IMaintenancePlan | IMaintenancePlanGroup>>
  dateSelect: boolean
}

export const parseEquipment = ({ maintenance, class_plural = null, start = null, created = false }) => ({
  maintainable_id:   maintenance.maintainable_id,
  maintainable_name: maintenance.maintainable_name,
  class_plural:      class_plural || maintenance.class_plural,
  start:             start || null,
  path_string:       maintenance.path_string,
  created:           maintenance.created || created
})

const SELECT = 'select'
const TREE   = 'tree'

const PlanFormEquipments: React.FC<PlanFormEquipmentsProps> = ({
  data,
  setData,
  dateSelect = false,
  callback,
  // fromRails = false
}) => {

  const { fetchApi, i18n, fromRails } = useGlobalContextState()

  const [_loading, setLoading] = useState(false)
  const [_state, formDispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    setLoading
  })

  const updatePlan = equipments => {
    if (fromRails) {
      formDispatch({
        type:      MAINTENANCE_ACTIONS.UPDATE_PLAN_EQUIPMENTS,
        plan:      data,
        callbacks: [
          () => {
            window.location = '/maintenance_plans/full_index'
          }
        ],
        equipments: {...equipments, fromRails },
        fromRails,
      })
    } else {
      !!callback && callback(equipments)
    }
  }

  const [view,        setView]        = useState(SELECT)
  const [units,       setUnits]       = useState(data.units.map(u => parseEquipment({ maintenance: u, start: u.start })))
  const [amenities,   setAmenities]   = useState(data.amenities.map(a => parseEquipment({ maintenance: a, start: a.start })))

  const [amenityTypeSelected, selectAmenityType] = useState(null)
  const [amenityResults,      setAmenityResults] = useState([])
  const [amenitySelected,     selectAmenity]     = useState(null)
  const [unitSelected,        selectUnit]        = useState(null)

  const [treeData,    setTreeData]    = useState([])
  const [checkedKeys, setCheckedKeys] = useState(units.map(u => `${u.class_plural}-${u.id}`).concat(amenities.map(a => `${a.class_plural}-${a.id}`)))

  useEffect(() => { setData(data => ({...data, units }))     }, [units])
  useEffect(() => { setData(data => ({...data, amenities })) }, [amenities])

  useEffect(() => {
    selectAmenity(null)
    setAmenityResults([])
  }, [unitSelected, amenityTypeSelected])

  useEffect(() => {
    fetchApi({
      url:      '/units/rep_search?roots=true&unpaginate=true',
      callback: data => {
        const root_units = []
        data.response.results.forEach(unit =>
          root_units.push({
            key:          `units-${unit.id}`,
            class_plural: 'units',
            id:           unit.id,
            name:         unit.name,
            path_string:  unit.path_string,
            title:        <><FontAwesomeIcon icon="location-dot" /> {unit.name} </>,
            children:     unit.has_children ? [] : null
          })
        )
        setTreeData(root_units)
      }
    })
  }, [])

  const addFromSelect = () => {
    if (amenitySelected) {
      if (amenities.find(a => a.maintainable_id === amenitySelected.id)) return toast('Amenity already added')
      setAmenities(amenities => [
        ...amenities,
        parseEquipment({
          maintenance: {
            ...amenitySelected,
            maintainable_name: amenitySelected.name,
            maintainable_id:   amenitySelected.id
          },
          class_plural: 'amenities',
          created:      true
        })])
      // .sort((a, b) => a.path_string.localeCompare(b.path_string))
    }
    if (!amenitySelected && unitSelected) {
      if (units.find(u => u.maintainable_id === unitSelected.id)) return toast('Unit already added')
      setUnits(units => [
        ...units,
        parseEquipment({
          maintenance: {
            ...unitSelected,
            maintainable_name: unitSelected.name,
            maintainable_id:   unitSelected.id
          },
          class_plural: 'units',
          created:      true
        })].sort((a, b) => a.path_string.localeCompare(b.path_string)))
    }
    selectAmenity(null)
    selectUnit(null)
  }

  const removeUnit = id => {
    let unitsCopy = [...units]
    unitsCopy = unitsCopy.filter(unit => unit.maintainable_id !== id)
    setUnits(unitsCopy)
  }

  const removeAmenity = id => {
    let amenitiesCopy = [...amenities]
    amenitiesCopy = amenitiesCopy.filter(unit => unit.maintainable_id !== id)
    setAmenities(amenitiesCopy)
  }

  const setAmenityDate = (id, date) => {
    let amenitiesCopy = [...amenities]
    amenitiesCopy = amenitiesCopy.map(amenity => amenity.maintainable_id === id ? {...amenity, start: date.toISOString() } : amenity)
    setAmenities(amenitiesCopy)
  }

  const setUnitDate = (id, date) => {
    let unitsCopy = [...units]
    unitsCopy = unitsCopy.map(unit => unit.maintainable_id === id ? {...unit, start: date.toISOString() } : unit)
    setUnits(unitsCopy)
  }

  const onCheck = (checkedKeysValue, info) => {
    if (info.checked) {
      if (info.node.class_plural === 'units') {
        setUnits(units => [...units, parseEquipment({ maintenance: {
          ...info.node,
          maintainable_name: info.node.name,
          maintainable_id:   info.node.id
        }, class_plural: 'units', created: true })].sort((a, b) => a.path_string.localeCompare(b.path_string)))
      } else {
        setAmenities(amenities => [...amenities, parseEquipment({ maintenance: {
          ...info.node,
          maintainable_name: info.node.name,
          maintainable_id:   info.node.id
        }, class_plural: 'amenities', created: true })].sort((a, b) => a.path_string.localeCompare(b.path_string)))
      }
    } else {
      if (info.node.class_plural === 'units') {
        removeUnit(info.node.id)
      } else {
        removeAmenity(info.node.id)
      }
    }
    setCheckedKeys(checkedKeysValue)
  }

  // It's just a simple demo. You can use tree map to optimize update perf.
  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        }
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        }
      }
      return node
    })

  // keys, { key, event, node }
  const onLoadData = (node) => {
    return new Promise((resolve) => {
      fetchApi({
        url:      `/${node.class_plural}/${node.id}/tree_search?path=true&path_string=true&serving_assets=true`,
        callback: data => {
          const childrenData = data.map(equipment => ({
            key:          `${equipment.class_plural}-${equipment.id}`,
            class_plural: equipment.class_plural,
            title:        <><FontAwesomeIcon icon={equipment.class_plural === 'units' ? 'location-dot' : 'gear'} /> {equipment.name} </>,
            id:           equipment.id,
            name:         equipment.name,
            path_string:  equipment.path_string,
            children:     equipment.children,
          }))

          setTreeData((origin) => updateTreeData(origin, node.key, childrenData))
        }
      })
      resolve()
    })
  }

  return(
    <>
      <FormStyle.Header>
        <FontAwesomeIcon icon="oil-can" />
        {i18n.t('maintenance.assets')}
      </FormStyle.Header>

      {units.map(maintenance =>
        <MultiselectItem
          key          = {`units-${maintenance.maintainable_id}`}
          name         = {maintenance.maintainable_name}
          icon         = {<FontAwesomeIcon icon="location-dot" />}
          tooltip      = {maintenance.path_string}
          removeAction = {() => removeUnit(maintenance.maintainable_id)}
        >
          <DatePicker
            name     = 'maintenance-unit-start-date'
            label    = {i18n.t('maintenance.start_date')}
            date     = {maintenance.start ? new Date(maintenance.start) : new Date()}
            callback = {date => setUnitDate(maintenance.maintainable_id, date)}
            disabled = {dateSelect ? false : data?.id ? (maintenance.created ? false : true) : false}
            showTime
          />
        </MultiselectItem>
      )}
      {amenities.map(maintenance =>
        <MultiselectItem
          key          = {`amenities-${maintenance.maintainable_id}`}
          name         = {maintenance.maintainable_name}
          icon         = {<FontAwesomeIcon icon="gear" />}
          tooltip      = {maintenance.path_string}
          removeAction = {() => removeAmenity(maintenance.maintainable_id)}
        >
          <DatePicker
            name     = 'maintenance-amenity-start-date'
            label    = {i18n.t('maintenance.start_date')}
            date     = {maintenance.start ? new Date(maintenance.start) : new Date()}
            callback = {date => setAmenityDate(maintenance.maintainable_id, date)}
            disabled = {dateSelect ? false : data?.id ? (maintenance.created ? false : true) : false}
            showTime
          />
        </MultiselectItem>
      )}


      <FormStyle.Label>{i18n.t('maintenance.actions.add_equipments')}</FormStyle.Label>
      <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '8px'}}>
        <Toggle
          initialOption = 'left'
          color         = '--rep-accent'
          callback      = {selected => setView(selected)}
          options       = {{
            left: {
              id:      SELECT,
              content: 'Recherche',
              icon:    <FontAwesomeIcon icon="magnifying-glass" />
            },
            right: {
              id:      TREE,
              content: 'Arborescence',
              icon:    <FontAwesomeIcon icon="folder-tree" />

            }
          }}
        />
      </div>
      {view === TREE &&
          <div style={{color: 'var(--rep-primary)'}}>
            {treeData.length
              ? <Tree
                checkable
                checkStrictly
                showLine
                loadData         = {onLoadData}
                autoExpandParent = {true}
                onCheck          = {onCheck}
                checkedKeys      = {checkedKeys}
                treeData         = {treeData}
              />
              : i18n.t('actions.loading')
            }
          </div>
      }
      {view === SELECT &&
        <>
          <SelectTree
            name           = 'unit'
            label          = {i18n.t('unit.unit')}
            selected       = {unitSelected}
            callback       = {selectUnit}
            withEmpty
            searchUrl      = '/units/rep_search'
            filters        = {[{
              id:      'root',
              name:    'Parent',
              filters: { roots: 'true', path: 'true', path_string: 'true' }
            }]}
            format         = {{ content: 'name', value: 'id' }}
            //  disabled       = {report?.id && !report.permissions.can_update_report}
            marginY        = 'M'
            subFilterName  = 'for_unit'
            subElementName = {i18n.t('unit.subunit')}
          />
          {unitSelected?.has_amenities &&
          <>
            <FormStyle.UnitArrow><FontAwesomeIcon icon="arrow-down" /></FormStyle.UnitArrow>
            <SelectTree
              name      = 'amenity'
              label     = {i18n.t('amenity.amenity')}
              selected  = {amenitySelected}
              callback  = {selectAmenity}
              withEmpty = {true}
              searchUrl = '/amenities/rep_search'
              filters       = {unitSelected
                ? [
                  {
                    id:      'direct_children',
                    name:    i18n.t('amenity.exclude_tree'),
                    filters: {
                      for_unit: unitSelected?.id || '',
                    }
                  },
                  {
                    id:      'all',
                    name:    i18n.t('amenity.include_tree'),
                    filters: {
                      root_unit_id: unitSelected?.id || '',
                    }
                  }
                ]
                :  [{
                  id:      'direct_children',
                  name:    i18n.t('amenity.exclude_tree'),
                  filters: {
                    for_unit: unitSelected?.id || '',
                  }
                }]}
              format         = {{ content: 'name', value: 'id' }}
              marginY        = 'M'
              subFilterName  = 'for_amenity'
              subElementName = {i18n.t('amenity.subamenity')}
            />
          </>
          }
          {unitSelected || amenitySelected
            ? <Button
              marginY    = 'M'
              size       = 'M'
              color      = 'var(--rep-success)'
              background = 'var(--rep-success-light)'
              click      = {addFromSelect}
              icon       = {<FontAwesomeIcon icon="plus" />}
              fullWidth
            >
              {i18n.t('maintenance.actions.link_equipment')}
            </Button>
            : null
          }
        </>
      }

      {(!!callback || fromRails) && updatePlan &&
        <Button
          click       = {() => updatePlan({units: units, amenities: amenities})}
          border      = 'var(--rep-primary-light)'
          background  = 'var(--rep-primary-light)'
          color       = 'var(--rep-primary)'
          icon        = {<FontAwesomeIcon icon="floppy-disk" />}
          size        = 'L'
          marginY     = 'L'
          align       = 'center'
          fullWidth
        >
          {i18n.t('actions.save')}
        </Button>
      }
    </>
  )
}

export default PlanFormEquipments
