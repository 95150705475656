import React from 'react'

import ReportBody from './ReportBody'
import Wrapper    from '../../Wrapper'

import { ReportContextProvider } from '@context/ReportContext'

import { ICompany, IReport, IUser } from '@interfaces/index'

interface ReportMainProps {
  report:          IReport,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  locale:          string
}

const ReportMain: React.FC<ReportMainProps> = ({ report, current_user, current_company, token, locale }) => {
  return (
    <Wrapper
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      locale          = {locale}
    >
      <ReportContextProvider serverReport={report}>
        <ReportBody />
      </ReportContextProvider>
    </Wrapper>
  )
}

export default ReportMain
