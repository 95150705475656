import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Tag from '@components/Tag'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Style from './style'

const TreeItem = ({ item, isFolded, setShowCurrent, indent, highlighted, before, current, readOnly, fromModal }) => {

  const { i18n, current_company, setInfoWindowProps, setModalInfoWindowProps } = useGlobalContextState()

  const [folded,      setFolded]      = useState(isFolded)
  const [children,    setChildren]    = useState({ items: [], count: null })
  const [loading,     setLoading]     = useState(false)
  const [page,        setPage]        = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)

  const toggleItem = () => {
    if (folded) {
      setChildren({ items: [], count: null })
      setFolded(false)
      fetchItems(1)
    } else {
      setFolded(true)
      if (before.find(it => it.id === item.id && item?.class_plural === it.class_plural)) {
        setShowCurrent(true)
      }
    }
  }

  const insertChildren = data => {
    if (data.page > 1) {
      setChildren(current => ({ ...current,  items: [...current.items, ...data.items] }))
    }
    else {
      setChildren(data)
    }

    setLoading(false)
    setPage(data.page)
    setHasNextPage(!!data.next_page)
    if (data.items.find(it => current?.id === it.id && current?.class_plural === it.class_plural)) {
      setShowCurrent(false)
    } else {
      setShowCurrent(true)
    }
  }

  const fetchItems = selectedPage => {
    setLoading(true)
    switch(item.class_plural) {
      case 'amenities': return fetchAmenities(selectedPage)
      case 'units':     return fetchUnits(selectedPage)
    }
  }

  const fetchAmenities = selectedPage => {
    fetch(`/amenities/${item.id}/rep_tree_search?page=${selectedPage}`)
      .then(data => data.json())
      .then(insertChildren)
  }

  const fetchUnits = selectedPage => {
    fetch(`/units/${item.id}/rep_tree_search?page=${selectedPage}`)
      .then(data => data.json())
      .then(insertChildren)
  }

  const itemIcon = useMemo(() => {
    switch(item.class_plural) {
      case 'amenities': return <FontAwesomeIcon icon="gear" />
      case 'units':     return <FontAwesomeIcon icon="building" />
    }
  }, [item])

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  const itemLink = useMemo(() => {
    if (current.id === item.id && item?.class_plural === current.class_plural)return <div style={{ width: '30px'}}></div>
    switch(item.class_plural) {
      case 'amenities': return <Tag
        click          = {() => openDetails(item, 'amenities')
          // () => fromModal
          //   ? setModalInfoWindowProps({ ...item, resourceType: 'amenities', readOnly: true, fromModal: true})
          //   : setInfoWindowProps({ ...item, resourceType: 'amenities' })
        }
        color          = 'var(--rep-primary)'
        hover          = 'var(--rep-primary-light)'
        tooltipContent = {i18n.t('actions.see')}
        icon           = {<FontAwesomeIcon icon="eye" />}
      />
      case 'units':     return <Tag
        click          = {() => openDetails(item, 'units')
          // () => fromModal
          //   ? setModalInfoWindowProps({ ...item, resourceType: 'units', readOnly: true, fromModal: true})
          //   : setInfoWindowProps({ ...item, resourceType: 'units'})
        }
        icon           = {<FontAwesomeIcon icon="eye" />}
        color          = 'var(--rep-primary)'
        hover          = 'var(--rep-primary-light)'
        tooltipContent = {i18n.t('actions.see')}
      />
    }
  }, [item])

  return(
    <>
      <Style.TreeItem
        onClick     = {toggleItem}
        indent      = {indent}
        highlighted = {highlighted}
        style       = {{marginLeft: `${(indent ? indent : 0) * 8}px`}}
      >
        <Style.TreeItemIcon>
          {(item.has_children || item.has_amenities) &&
            (folded
              ? <FontAwesomeIcon icon="caret-right" />
              : <FontAwesomeIcon icon="caret-down" />)
          }
        </Style.TreeItemIcon>

        <Style.TreeItemContent>
          <Style.TreeItemName>
            {itemIcon}
            {<Tag
              icon={<FontAwesomeIcon icon="circle" color={item.stopped ? 'var(--rep-danger)' : 'var(--rep-success)'} />}
              tooltipContent={`Status : ${item.stopped ? 'à l\'arrêt' : 'Fonctionne'}`}
            />
            }
            <div>{item.name}</div>
          </Style.TreeItemName>
          <div style={{display: 'flex'}}>
            {!!item.reports_count &&
            <Tag
              icon={<FontAwesomeIcon icon="receipt" />}
              color='var(--rep-warning)'
              background='var(--rep-warning-light)'
              tooltipContent={`${item.reports_count} tickets en cours`}
            >
              {item.reports_count}
            </Tag>
            }
            {!readOnly && itemLink}
          </div>
        </Style.TreeItemContent>

      </Style.TreeItem>

      {!folded && children.items.map(it =>
        <TreeItem
          key            = {it.id}
          item           = {it}
          current        = {current}
          indent         = {indent + 1}
          highlighted    = {current?.id === it.id && current?.class_plural === it.class_plural}
          before         = {before}
          setShowCurrent = {setShowCurrent}
          fromModal      = {fromModal}
          isFolded
        />
      )}
      {loading &&
        <div style={{ display: 'flex', justifyContent: 'center', margin: '4px 0'}}>
          <FontAwesomeIcon icon="spinner" spin />
        </div>
      }
      {!folded && !loading && hasNextPage &&
      <Style.TreeItem
        indent  = {indent + 3}
        onClick = {() => fetchItems(page + 1)}
        style   = {{marginLeft: `${(indent ? indent + 3 : 3) * 8}px`, cursor: 'pointer '}}
      >
        Load more
        {/* And {children.count - 100} others… */}
      </Style.TreeItem>
      }
    </>
  )
}

export default TreeItem
