import React, { useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState }  from 'react'

import AvatarList       from '../AvatarList'
import Callout          from '../Callout'
import ChatWrite        from './ChatWrite'
import ConversationForm from './Form'
import Dropdown         from '../Dropdown'
import EditUsers        from './EditUsers'
import Message          from './Message'
import Tag              from '../Tag'
import WithTooltip      from '../WithTooltip'

import * as ColumnStyle from '../Column/style'
import * as Style       from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IConversation, IReport } from '@interfaces/index'

import { conversationReducer } from '@reducers/index'

interface IChat {
  report:        IReport
  updateMethods: {
    conversation: React.Dispatch<React.SetStateAction<IConversation>>
  }
  height?:    string
  maxHeight?: string
}

const Chat: React.FC<IChat> = ({
  report,
  updateMethods,
  height,
  maxHeight
}) => {

  const {
    current_user,
    showModal,
    i18n,
    isDesktop,
    CONSTANTS,
    fetchApi,
    closeModal
  } = useGlobalContextState()

  const { CONVERSATION } = CONSTANTS

  const [selected,    setSelected]    = useState(0)
  const [pageLoading, setPageLoading] = useState(false)
  const [timeoutId,   setTimeoutId]   = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const conversationsRef = useRef(null)

  const [_state, dispatch] = useReducer(conversationReducer, { report, fetchApi, updateMethods, closeModal})

  useEffect(() => {
    const urlParams            = new URLSearchParams(window.location.search)
    const selectedConversation = urlParams.get('conversation')

    if (selectedConversation) { setSelected(report.conversations.findIndex(c => c.id === parseInt(selectedConversation, 10))) }
  }, [])

  useLayoutEffect(() => { conversationsRef.current.scrollTop = conversationsRef.current.scrollHeight }, [report.conversations[selected]])
  useLayoutEffect(() => { if (conversationsRef?.current && currentPage === 1) conversationsRef.current.scrollTop = conversationsRef.current.scrollHeight }, [selected, report.conversations])

  const insertFetchedPage = conversation => {
    const conv    = report.conversations[selected]
    conv.messages = [...conversation.messages, ...conv.messages]
    setCurrentPage(page => page + 1)
    setPageLoading(false)
    updateMethods.conversation(conv)
    conversationsRef.current.scrollTop = 60
  }

  const fetchNextPage = () => {
    setPageLoading(true)
    setTimeoutId(null)
    fetchApi({
      url:      `/conversations/${report.conversations[selected].id}/rep_show?page=${currentPage + 1}`,
      callback: insertFetchedPage
    })
  }

  const handleScroll = e => {
    if (e.target.scrollTop > 1) return
    if (timeoutId) {
      clearTimeout(timeoutId)
    } else {
      if (report.conversations[selected].total_pages >= currentPage) {
        setTimeoutId(setTimeout(fetchNextPage, 200))
      }
    }
  }

  const updateConv = conversation => {
    const conv            = report.conversations[selected]
    conv.unseen_msg_count = conversation.unseen_msg_count
    updateMethods.conversation(conv)
  }

  const handleSelect = (key) => {
    setSelected(key)
    fetchApi({
      url:      `/conversations/${report.conversations[selected].id}/rep_notifications_seen`,
      method:   'PATCH',
      callback: updateConv
    })
  }

  const EditForm = useMemo(() =>
    <EditUsers
      conversation  = {report.conversations[selected]}
      updateMethods = {updateMethods}
    />
  , [report.conversations, selected])

  const actions = useMemo(() => {
    const actionsArray = []
    if (report.conversations[selected]?.permissions?.can_add_users ||
        report.conversations[selected]?.permissions?.can_remove_users) actionsArray.push({
      icon:    <FontAwesomeIcon icon="edit" />,
      color:   'var(--rep-primary)',
      content: i18n.t('actions.edit'),
      click:   () => showModal({
        title:   i18n.t('conversation.report_conversation'),
        content: EditForm
      })
    })
    if (report.permissions?.can_create_conversation) actionsArray.push({
      icon:    <FontAwesomeIcon icon="plus" />,
      color:   'var(--rep-success)',
      content: i18n.t('conversation.actions.create'),
      click:   () => showModal({
        title:   i18n.t('conversation.actions.create'),
        content: <ConversationForm dispatch={dispatch} report={report} />
      })
    })
    return actionsArray
  }, [report.permissions, report.conversations, selected])

  const typeTag = ({
    [CONVERSATION.TYPES.REQUESTERS]:
      <Tag
        background = 'transparent'
        color      = 'var(--rep-neutral-primary)'
        marginY    = 'S'
        click      = {() => {}}
        icon       = {<FontAwesomeIcon icon="person-chalkboard" />}
      >
        {i18n.t(`shared.${CONVERSATION.TYPES.REQUESTERS}`)}
      </Tag>,
    [CONVERSATION.TYPES.SERVICE_PROVIDERS]:
      <Tag
        background = 'transparent'
        color      = 'var(--rep-accent)'
        marginY    = 'S'
        click      = {() => {}}
        icon       = {<FontAwesomeIcon icon="truck-arrow-right" />}
      >
        {i18n.t(`shared.${CONVERSATION.TYPES.SERVICE_PROVIDERS}`)}
      </Tag>
  })[report.conversations[selected]?.type] ??
      <Tag
        background = 'transparent'
        color      = 'var(--rep-primary)'
        marginY    = 'S'
        click      = {() => {}}
        icon       = {<FontAwesomeIcon icon={'helmet-safety'} />}
      >
        {i18n.t('shared.techs')}
      </Tag>

  const typeIcon = type => {
    switch(type) {
      case CONVERSATION.TYPES.REQUESTERS:
        return  <WithTooltip content={i18n.t(`shared.${CONVERSATION.TYPES.REQUESTERS}`)}>
          <Tag
            background = 'transparent'
            color      = 'var(--rep-neutral-primary)'
            marginY    = 'S'
            click      = {() => {}}
            icon       = {<FontAwesomeIcon icon="person-chalkboard" />}
          />
        </WithTooltip>
      case CONVERSATION.TYPES.SERVICE_PROVIDERS:
        return  <WithTooltip content={i18n.t(`shared.${CONVERSATION.TYPES.SERVICE_PROVIDERS}`)}>
          <Tag
            background = 'transparent'
            color      = 'var(--rep-accent)'
            marginY    = 'S'
            click      = {() => {}}
            icon       = {<FontAwesomeIcon icon="truck-arrow-right" />}
          />
        </WithTooltip>
      default:
        return <WithTooltip content={i18n.t('shared.techs')}>
          <Tag
            background = 'transparent'
            color      = 'var(--rep-primary)'
            marginY    = 'S'
            click      = {() => {}}
            icon       = {<FontAwesomeIcon icon={'helmet-safety'} />}
          />
        </WithTooltip>
    }
  }

  return (
    <>
      <Style.Conversations
        isDesktop = {isDesktop}
        height    = {height}
        maxHeight = {maxHeight}
        onScroll  = {handleScroll}
      >
        {!!report.conversations?.length &&
          <Style.ConversationList>
            {report.conversations.map((conv, key) =>
              <Style.ConversationBtn
                key      = {key}
                selected = {key === selected}
                onClick  = {() => handleSelect(key)}
              >
                {conv.unseen_msg_count > 0 &&
                  <Style.UnseenMsgCount>{conv.unseen_msg_count}</Style.UnseenMsgCount>
                }
                {typeIcon(conv.type)}
              </Style.ConversationBtn>
            )}
          </Style.ConversationList>
        }
        <Style.MessageList
          ref              = {conversationsRef}
          hasConversations = {!!report.conversations.length}
          onScroll         = {handleScroll}
        >
          <Style.ChatHeader hasConversations={!!report.conversations.length}>
            <Style.ChatTitle>
              <Style.ChatTag>{typeTag}</Style.ChatTag>
              {!!actions.length &&
                <Dropdown
                  options   = {actions}
                  color     = 'var(--rep-neutral)'
                  hover     = 'var(--rep-neutral-light)'
                  withArrow = {false}
                  icon      = {<FontAwesomeIcon icon="gear" />}
                >
                  {!isDesktop && i18n.t('actions.edit')}
                </Dropdown>
              }
            </Style.ChatTitle>
            <AvatarList
              users = {report.conversations[selected]?.participants || []}
              limit = {15}
              title = {i18n.t('conversation.participants')}
            />
          </Style.ChatHeader>
          <Style.Messages>
            <ColumnStyle.ColumnLoader>
              {!!pageLoading && <FontAwesomeIcon icon="spinner" spin />}
              {!!report.conversations.length && report.conversations[selected].total_pages < currentPage &&
                <Callout
                  style      = {{ fontSize: '12px' }}
                  border     = "var(--rep-neutral-light)"
                  color      = "var(--rep-neutral)"
                  background = "var(--rep-neutral-light)"
                >
                  {i18n.t('conversation.no_messages')}
                </Callout>
              }
            </ColumnStyle.ColumnLoader>

            {!!report.conversations[selected]?.messages?.length &&
              report.conversations[selected].messages.map(msg =>
                <Message
                  key          = {msg.id}
                  message      = {msg}
                  current_user = {current_user}
                />
              )
            }
          </Style.Messages>
        </Style.MessageList>
      </Style.Conversations>

      {report.conversations.length
        ? <ChatWrite conversation={report.conversations[selected]} updateMethods={updateMethods} />
        : <div style={{ marginBottom: '12px' }}></div>
      }
    </>
  )
}

export default Chat
