export const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0')
}

export const convertMsToMinutesSeconds = (milliseconds) => {
  // console.log(milliseconds)
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes

  minutes = minutes % 60

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  // hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

export const firstDayOfWeek = (startDayOfWeek, year, week) => {
  const day          = (1 + (week - 1) * 7) // 1st of January + 7 days for each week
  const firstOfMonth = new Date(year, 0, day)
  const difference   = ((7 + startDayOfWeek) - firstOfMonth.getDay()) % 7
  return new Date(year, 0, day + difference)
}

export const lastDayOfWeek = (startDayOfWeek, year, week) => {
  const day          = (1 + (week - 1) * 7)
  const firstOfMonth = new Date(year, 0, day)
  const difference   = ((7 + startDayOfWeek) - firstOfMonth.getDay()) % 7
  return new Date(year, 0, day + difference + 6)
}

export const getWeekIndex = weekDate => {
  const date = new Date(weekDate.getTime())
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7)
}

export const getCurrentWeek = () => getWeekIndex(new Date())

export const getWeekArray = (year, startDayOfWeek) => {
  const result = {}

  // If last year's week index is 1, add it to current year
  if (getWeekIndex(new Date(year - 1, 11, 31)) === 1) {
    result[1] = [firstDayOfWeek(startDayOfWeek, year, 0), lastDayOfWeek(startDayOfWeek, year, 0)]
  }

  Array.from({ length: 52 }, (_x, w) => {
    const firstDay  = firstDayOfWeek(startDayOfWeek, year, w + 1)
    const lastDay   = lastDayOfWeek(startDayOfWeek, year, w + 1)
    const weekIndex = getWeekIndex(firstDay)

    if (result[weekIndex] === undefined) {
      result[weekIndex] = [firstDay, lastDay]
    }
  })
  return result
}

export const getWeeksInMonth = weeks => {
  const weekPerMonth = {}

  Object.keys(weeks).forEach(week => {
    const monthIndex = weeks[week][0].getMonth()

    if (weekPerMonth[monthIndex]) {
      weekPerMonth[monthIndex] += 1
    } else {
      weekPerMonth[monthIndex] = 1
    }
  })

  return weekPerMonth
}
