import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Form  from '@components/Form/Form'
import Input from '@form/Input'

import { IControlRemark, IReport } from '@interfaces/index'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { REMARK_ACTIONS } from '@reducers/index'

interface ControlRemarkProps {
  remark?:  IControlRemark,
  report:   IReport,
  dispatch: React.Dispatch<object>
}

const ControlRemarkForm: React.FC<ControlRemarkProps> = ({
  remark,
  report,
  dispatch,
}) => {

  const { i18n } = useGlobalContextState()

  const createRemark = (data, fieldset) => dispatch({ type: REMARK_ACTIONS.CREATE, report, data, fieldset })
  const updateRemark = (data, fieldset) => dispatch({ type: REMARK_ACTIONS.UPDATE, remark, data, fieldset })
  const callback     = remark?.id ? updateRemark : createRemark

  return(
    <Form
      callback       = {callback}
      submitText     = {i18n.t('actions.save')}
    >
      <FormStyle.Header><FontAwesomeIcon icon="medal" />{i18n.t('control_remarks.title')}</FormStyle.Header>
      <Input
        name         = 'description'
        type         = 'text'
        defaultValue = {remark?.description}
        marginY      = 'M'
        required
      />
    </Form>
  )
}

export default ControlRemarkForm
