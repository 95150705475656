import React from 'react'

import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'

import { useGlobalContextState }       from '@context/GlobalContext'

import IMaintenance from '@interfaces/IMaintenance.d'

interface ReactivateFormProps {
  maintenance: IMaintenance
  callback:    (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const ReactivateForm: React.FC<ReactivateFormProps> = ({ maintenance, callback }) => {

  const { i18n } = useGlobalContextState()

  return (
    <Form
      callback={callback}
      submitText={i18n.t('maintenance.actions.plan_maintenance')}
    >
      <DatePicker
        label = {i18n.t('maintenance.reactivate_from')}
        name  = 'start'
        date  = {maintenance.data.start}
        showTime
        required
      />
    </Form>
  )
}

export default ReactivateForm
