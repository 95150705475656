import styled from 'styled-components'

export const Wizard = styled.div`
  display: flex;
`

export const WizardIndex = styled.div`
  min-width:     fit-content;
  width:         20%;
  max-width:     200px;
  margin-right:  16px;
  border:        1px solid var(--rep-primary-light);
  border-radius: 5px;
  height:        fit-content;
  position:      sticky;
  top:           100px;
`

export const WizardLink = styled.div`
  padding:          16px 8px;
  color:            ${props => props.selected ? 'var(--rep-primary)' : 'var(--rep-neutral-primary)'};
  border-bottom:    1px solid var(--rep-primary-light);
  transition:       .5s ease;
  background-color: ${props => props.selected ? 'var(--rep-primary-light)' : 'transparent'};
  &:hover {
    cursor:     pointer;
    background: var(--rep-primary-light);
  }
`

export const WizardContent = styled.form`
  flex-grow:       1;
  display:         flex;
  flex-direction:  column;
  justify-content: space-between;
  min-height:      75vh;
`
