import styled, { keyframes } from 'styled-components'

const toggleAnimation = keyframes`
  0% {
    opacity:   0;
    transform: translateY(-10px);
  }
  100% {
    opacity:   1;
    transform: translateY(0px);
  }
`

export const Accordion = styled.details`
  width:          100%;
  padding:        8px 4px;
  margin:         8px 0;
  border:         1px solid ${props => props.border || 'var(--rep-primary-light)'};
  background:     ${props => props.background || 'initial'};
  border-radius:  5px;
  &[open] summary ~ * {
    animation-name: ${toggleAnimation} 0.1s ease-in-out;
  }
`

export const Header = styled.summary`
  display:          flex;
  justify-content:  space-between;
  align-items:      center;
  min-height:       calc(1em + 24px);
  color:            ${props => props.colorHeader};
  font-weight:      600;
  list-style-type:  none;
  user-select:      none;
  margin-left: 4px;
  &::-webkit-details-marker { display:none }
  sup { font-weight: 900 }
`
export const Icon = styled.div`
  color:      var(--rep-primary);
  transform:  ${props => props.opened ? 'rotate(90deg)' : 'none'};
  transition: .2s ease;
`

export const HeaderInformations = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  width:           100%;
  margin:          0 8px;
`

export const HeaderTitle = styled.div`
  display:     flex;
  align-items: center;
  gap:         8px;
`

export const Counter = styled.sup`
  /* display: block; */
  /* background: red; */
  color: var(--rep-neutral-primary);
`

export const Body   = styled.div`
  overflow: hidden;
  padding:  4px 0;
  color:    ${props => props.colorBody};
`
