import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo }               from 'react'

import Tag from '@components/Tag'

import FieldTagProps       from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

const FieldTag: React.FC<FieldTagProps> = ({
  // background = 'var(--rep-neutral-light)',
  // border     = 'var(--rep-neutral-light)',
  // color      = 'var(--rep-neutral-primary)',
  field,
  // hover      = 'var(--rep-neutral-primary-light)',
  // icon,
  // marginY,
  // style,
  // value,
  background,
  withName = false,
  renderTag = true
}) => {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { REPLY_TYPES }     = CONSTANTS

  const TagValue = useMemo(() => {
    switch (field.reply_type) {
      case REPLY_TYPES.TRUE_FALSE:
        return ({
          content:    field.name,
          icon:       field.value === 'true' ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />,
          hover:      background || (field.value === 'true' ? 'var(--rep-success-light)' : 'var(--rep-danger-light)'),
          background: background || (field.value === 'true' ? 'var(--rep-success-light)' : 'var(--rep-danger-light)'),
          color:      field.value === 'true' ? 'var(--rep-success)' : 'var(--rep-danger)'
        })
      case REPLY_TYPES.DATE:
        return ({
          content:    field.value ? new Date(field.value).toLocaleDateString(i18n.locale) : '',
          background: background || 'var(--rep-neutral-light)',
          color:      'var(--rep-neutral-primary)'
        })
      case REPLY_TYPES.DATE_TIME:
        return ({
          content:    new Date(field.value).toLocaleDateString(i18n.locale),
          background: background || 'var(--rep-neutral-light)',
          color:      'var(--rep-neutral-primary)'
        })
      default:
        return ({
          content:    field.value,
          background: background || 'var(--rep-neutral-light)',
          color:      'var(--rep-neutral-primary)'
        })
    }
  }, [field])

  return (
    <>
      {renderTag
        ? <Tag
          color          = {TagValue.color}
          background     = {TagValue.background}
          hover          = {TagValue.hover}
          icon           = {TagValue.icon}
          tooltipContent = {field.name}
        >
          {!!withName && field.name} {field.reply_type !== REPLY_TYPES.TRUE_FALSE && <>{<FontAwesomeIcon icon="angle-right" />} {TagValue.content}</>}
        </Tag>
        : field.reply_type === REPLY_TYPES.TRUE_FALSE
          ? <Tag
            color          = {TagValue.color}
            background     = {TagValue.background}
            hover          = {TagValue.hover}
            icon           = {TagValue.icon}
            tooltipContent = {field.name}
          >
            {TagValue.content}
          </Tag>
          : TagValue.content
      }
    </>
  )
}

export default FieldTag
