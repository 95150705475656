import { DatePicker } from 'antd'
import styled from 'styled-components'

const { RangePicker } = DatePicker

export const DatePickerContainer = styled.div`
  margin: 8px 0;
  input { color:  var(--rep-neutral-primary) !important }

  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-year-btn {
    display: ${props => props.disableYear ? 'none !important' : 'initial' };
  }

  #single-month-popup {
    & .ant-picker-header-super-prev-btn,
    & .ant-picker-header-super-next-btn,
    & .ant-picker-year-btn {
      display:    ${props => props.disableYear ? 'none !important' : 'initial' };
    }
  }
`

export const DatePickerStyle = styled(DatePicker)`
  width:  100%;
  border: 1px solid ${props => props.required && !props.defaultValue ? 'var(--rep-warning)' : 'var(--rep-primary-light'};
  & div.ant-picker-input > input { color:  var(--rep-neutral-primary) !important }
`

export const RangePickerStyle = styled(RangePicker)`
  border: 1px solid ${props => props.required  && !props.defaultValue ? 'var(--rep-warning)' : 'var(--rep-primary-light)'};
  & div.ant-picker-input > input { color:  var(--rep-neutral-primary) !important }
  & .ant-picker-dropdown         { z-index: 120000 !important }
`
