import React, { useEffect, useState } from 'react'

import Checkbox from '@form/Checkbox'
import Input    from '@form/Input'

import { IReport } from '@interfaces/index'

import { useGlobalContextState } from '@context/GlobalContext'

const WorkorderPrint: React.FC<{ report: IReport }> = ({ report }) => {

  const { fetchApi } = useGlobalContextState()

  const [workorderUsers, setWorkorderUsers] = useState([])
  const [selectedUsers,  setSelectedUsers]  = useState([])

  useEffect(() => {
    setWorkorderUsers(report.users.map((u) => {
      return {
        value:   u.id,
        content: `${u.first_name} ${u.last_name}`,
        details: u.role
      }}))
  }, [])

  const getWorkorderForUsers = (e) => {
    e.preventDefault()
    selectedUsers.forEach(id => {
      fetchApi({
        url:      `/reports/${report.id}/generate_work_order?user_id=${id}`,
        method:   'POST',
        callback: data => { window.open(`${report.id}/download_pdf?document_id=${data.response.attributes.table.id}`), '_blank', 'noopener,noreferrer' }
      })
    })
  }

  const addOrRemoveUsers = ({ value, isCheck }) => {
    if (isCheck) {
      setSelectedUsers([...selectedUsers, value])
    } else {
      const users = [...selectedUsers]
      const index = users.indexOf(value)
      users.splice(index, 1)
      setSelectedUsers(users)
    }
  }

  return(
    <div>
      <form onSubmit={getWorkorderForUsers}>
        {workorderUsers.map((user, key) =>
          <Checkbox
            name     = {user.value}
            key      = {key}
            label    = {user.content}
            callback = {addOrRemoveUsers}
            marginY  = 'S'
          />
        )}
        <Input
          name         = 'submit'
          type         = 'submit'
          defaultValue = 'Export'
          marginY      = 'S'
        />
      </form>
    </div>
  )
}

export default WorkorderPrint
