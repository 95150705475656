import React, { FieldsetHTMLAttributes } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Form   from '@components/Form/Form'
import Select from '@form/Select'

import * as FormStyle            from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { CONVERSATION_ACTIONS } from '@reducers/index'
import IReport from '@interfaces/IReport.d'

interface NewChatFormProps {
  dispatch: React.Dispatch<object>
  report:   IReport
}

const ConversationForm: React.FC<NewChatFormProps> = ({ dispatch, report }) => {

  const {
    i18n,
    CONSTANTS
  } = useGlobalContextState()

  const { CONVERSATION } = CONSTANTS

  const addFormData        = formData => formData.append('conv_type', CONVERSATION.TYPES.TECHNICIANS)
  const createConversation = (data, fieldset) => dispatch({
    type: CONVERSATION_ACTIONS.CREATE,
    report,
    data,
    fieldset
  })

  return(
    <Form
      addFormData = {addFormData}
      callback    = {createConversation}
      submitText  = {i18n.t('conversation.actions.create')}
    >
      <FormStyle.Header><FontAwesomeIcon icon="users" />{i18n.t('user.users')}</FormStyle.Header>
      <Select
        name             = 'users'
        search           = {true}
        searchUrl        = '/users/search'
        multiselect      = {true}
        defaultValue     = {[]}
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        placeholder      = 'Utilisateurs'
        marginY          = 'M'
      />
    </Form>
  )
}

export default ConversationForm
