import styled, { css } from 'styled-components'

// Dropdown button
export const Dropdown = styled.div`
  user-select: none;
  font-size:   .8rem;
  font-weight: 500;
  width:       fit-content;
  align-self:  ${props => props.alingSelf ? props.alingSelf : 'unset'};
  &:hover      { cursor: pointer  }
`

export const Overlay = styled.div`
  position:   fixed;
  top:        0;
  left:       0;
  width:      100vw;
  height:     100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index:    500;
`

export const DropdownTitle = styled.div`
  display:       flex;
  align-items:   center;
  color:         ${props => props.color};
  background:    ${props => props.background};
  border:        1px solid ${props => props.border};
  padding:       4px;
  border-radius: 4px;
  &:hover {
    cursor:   pointer;
    opacity: .8;
  }
`

export const DropDownIcon = styled.div`
  /* margin-right: ${props => props.withMargin ? '8px' : ''}; */
`

const OptionsMobile = css`
  width:          100vw;
  max-height:     90vh;
  overflow-y:     scroll;
  font-size:      1rem;
  z-index:        4000;
  padding-bottom: 60px;
  border-bottom-left-radius:  0;
  border-bottom-right-radius: 0;
`

export const Options = styled.div`
  z-index:       4000;
  background:  white;
  box-shadow:    2px 1px 5px var(--rep-neutral-primary-light);
  border-radius: 5px;
  padding:       4px;
  border:        1px solid var(--rep-neutral-primary-light);
  ${props => !props.isDesktop && OptionsMobile }

  &:nth-child(1) {
    border-top-left-radius:  5px;
    border-top-right-radius: 5px;
  }
  &:nth-last-child(1) {
    border-bottom-left-radius:  5px;
    border-bottom-right-radius: 5px;
    border-bottom:              none;
  }

  hr     { margin: 4px 0; }
  .arrow { filter: drop-shadow(0px -1px 1px var(--rep-neutral-primary-light)); }
`

// Single option
const OptionDesktop = css`
  z-index:       4000;
  padding: 4px;
`

const OptionMobile = css`
  z-index:         4000;
  border-bottom:   1px solid var(--rep-neutral-light);
  text-align:      center;
  padding:         16px 0;
  justify-content: center;
  font-weight:     400;
  font-size:       1rem;
  /* z-index: 2500; */
`

export const Option = styled.div`
  display:               grid;
  grid-template-columns: ${props => props.hasIcon ? '1.2rem auto' : 'auto' };
  align-items:           center;
  padding:               ${props => !props.selected ? '4px' : ''};
  user-select:           none;
  border-radius:         5px;
  font-size:             .9rem;
  color:                 ${props => props.color || 'var(--rep-neutral-primary)'};
  ${props => props.isDesktop
    ? OptionDesktop
    : OptionMobile
}

  svg {
    color: ${props => props.color || 'var(--rep-neutral-primary)'};
  }

  &:hover {
    background: ${props => props.background || 'var(--rep-neutral-light)'};
    color:      ${props => props.color || ''};
    cursor:     pointer;

    svg { color: ${props => !props.selected ? (props.color || '') : ''}; }
  }
`

export const OptionIcon = styled.div`
  font-size: .7rem;
`
