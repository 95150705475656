import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import CardProps      from './types.d'

/**
 * @description Basic togglable card
 * @example
 * <Card>
 *  This is a card
 * </Card>
 */
const Card: React.FC<CardProps> = ({
  headerTop,
  header,
  bodyReduced,
  bodyExpanded,
  togglable    = false,
  opened       = false,
  setOpened,
  stickyHeader = false,
  animation    = null,
  border       = 'transparent',
  children
}) => {

  const cardRef = useRef(null)

  const toggleCard = () => {
    if (!opened) cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    togglable ? setOpened(!opened) : undefined
  }

  return (
    <Style.Card
      ref       = {cardRef}
      animation = {animation}
    >
      <Style.TopContent
        sticky = {stickyHeader}
        border = {border}
      >
        {headerTop &&
          <Style.CardHeader>
            {headerTop}
          </Style.CardHeader>
        }

        {header &&
          <Style.CardTitle
            togglable = {togglable}
            onClick   = {togglable ? toggleCard : undefined}
          >
            {header}
          </Style.CardTitle>
        }
      </Style.TopContent>

      <Style.CardBody border={border}>
        {children && children}
        <div>{!opened && bodyReduced}</div>
        {!!opened &&
          <Style.HiddenPart visible={opened}>
            {bodyExpanded}
          </Style.HiddenPart>
        }
      </Style.CardBody>

      <Style.CardFooter
        border    = {border}
        togglable = {togglable}
      >
        {!!togglable &&
          <Style.ToggleIcon onClick={toggleCard}>
            <FontAwesomeIcon icon='angle-down' rotation={opened ? 180 : undefined} />
          </Style.ToggleIcon>
        }
      </Style.CardFooter>
    </Style.Card>
  )
}

export default Card
