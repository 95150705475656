import React, { useMemo, useState }  from 'react'
import styled                           from 'styled-components'

import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome'

import Checkbox   from '@form/Checkbox/Checkbox'
import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'
import Input      from '@form/Input'
import Select     from '@components/Form/Select'
import Tag        from '@components/Tag'

import { useGlobalContextState } from '@context/GlobalContext'

import { FileFormProps } from './types.d'

import { matchImage, matchPdf } from '@utils/regex'

import { RemoveFileStyle } from './style'

const FileContainerStyle = styled.div`
  img { width: 100% }
`

const ImageFileStyle = styled.div`
  position: relative;
  img {
    transition:    .3s ease;
    width:         100%;
    border-radius: 4px;
    max-height:    500px;
    object-fit:    contain;
  }
  .download-btn {
    transition:      .3s ease;
    opacity:         0;
    position:        absolute;
    text-align:      center;
    top:             40%;
    right:           40%;
    color:         white;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
    padding:         4px 8px;
    border-radius:   4px;
    z-index:         200;
  }
  &:hover {
    cursor: pointer;
    img           { filter: brightness(50%); }
    .download-btn { opacity: .9; }
  }
`

const FileStyle = styled.div`
  font-size:  2em;
  color:      var(--rep-primary);
  text-align: center;
`

const FileNameStyle = styled.div`
  display: flex;
  gap:     8px;
  margin:  8px 0;
`


const FileForm: React.FC<FileFormProps> = ({
  file,
  certification,
  destroyable = false,
  floatingDestroy,
  updateDocument,
  removeDocument,
}) => {

  const { i18n, current_company, setModalInfoWindowProps } = useGlobalContextState()

  const [fileCertification, setFileCertification] = useState({ value: 'urgent', isCheck: file.certification })

  const isPdf   = useMemo(() => matchPdf(file.doctype), [])
  const isImage = useMemo(() => matchImage(file.doctype), [])

  const openFile = url => window.open(url, '_blank', 'noopener,noreferrer')

  const addFormData = formData => formData.append('certification', fileCertification?.isCheck?.toString())
  const handleSubmit = (data, fieldset) => updateDocument({
    ...data,
    id:       file.id,
    filename: `${data.filename}.${file.name.split('.').slice(-1)}`
  }, fieldset)

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setModalInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  const FileContent = useMemo(() => {
    if (isPdf) {
      return  <div>
        <iframe src={file.url} width="100%" height="500px" />
        <FileStyle onClick={() => openFile(file.url)}>
          <FontAwesomeIcon icon="file-pdf" />
          <div>{i18n.t('document.actions.access_file')}</div>
        </FileStyle>
      </div>
    } else if (isImage) {
      return <img
        onClick = {() => openFile(file.url)}
        src     = {file.url}
      />
    } else {
      return <FileStyle onClick={() => openFile(file.url)}>
        <FontAwesomeIcon icon="file" />
        <div>{i18n.t('document.actions.access_file')}</div>
      </FileStyle>
    }
  }, [])

  return(
    <FileContainerStyle>
      {
        file.report_id &&
      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Tag
          color = 'var(--rep-primary)'
          background = 'var(--rep-primary-light)'
          icon  = {<FontAwesomeIcon icon="window-restore" />}
          click = {() => openDetails({ id: file.report_id }, 'reports')}
          marginY='M'
        >
          {i18n.t('report.actions.view_report')}
        </Tag>
      </div>
      }
      <ImageFileStyle>
        {FileContent}
      </ImageFileStyle>

      <Form
        callback = {(data, fieldset) => handleSubmit(data, fieldset)}
        addFormData = {addFormData}
      >
        <FileNameStyle>
          <Input
            type         = 'text'
            name         = 'filename'
            defaultValue = {file.name.split('.').slice(0, -1)
              .join('.')}
            hint         = {`.${file.name.split('.').slice(-1)}`}
          />
          {!!destroyable &&
            <RemoveFileStyle onClick={removeDocument} floatingDestroy={floatingDestroy}>
              <FontAwesomeIcon icon="trash-alt" />
            </RemoveFileStyle>
          }
        </FileNameStyle>

        {certification &&
          <Checkbox
            name         = 'certification'
            label        = {i18n.t('document.certification_question')}
            defaultValue = {file.certification}
            callback     = {setFileCertification}
            marginY      = 'M'
          />
        }
        {certification && fileCertification.isCheck &&
          <DatePicker
            name   = 'validity_end'
            label  = {i18n.t('document.validity_end')}
            date   = {file?.validity_end}
          />
        }

        <Select
          name         = 'labels'
          label        = {i18n.t('label.labels')}
          searchUrl    = '/labels/search'
          defaultValue = {file?.labels?.length ? file.labels : []}
          placeholder  = {i18n.t('shared.actions.select')}
          format       = {{ content: 'name', value: 'id' }}
          marginY      = 'M'
          // callback     = {selected => setTasks(selected.map(s => s.object))}
          multiselect
          search
        />
      </Form>
    </FileContainerStyle>
  )
}

export default FileForm
