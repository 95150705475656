import React, { useEffect, useMemo, useRef, useState }  from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import InfoAmenity from './Components/InfoAmenity'
import InfoForm    from './Components/InfoForm'
import InfoOngoing from './Components/InfoOngoing'
import InfoReport  from './Components/InfoReport/InfoReport'
import InfoUnit    from './Components/InfoUnit'
import InfoUser    from './Components/InfoUser/InfoUser'

import Tag from '@components/Tag'

import { AmenityContextProvider } from '@context/AmenityContext'
import { ReportContextProvider }  from '@context/ReportContext'
import { UnitContextProvider }    from '@context/UnitContext'
import { useGlobalContextState }  from '@context/GlobalContext'

const InfoWindow = ({
  fromModal = false,
}) => {

  const {
    isDesktop,
    infoWindowProps,
    setInfoWindowProps,
    modalInfoWindowProps,
    setModalInfoWindowProps,
  } = useGlobalContextState()

  const [infoHistory,  setInfoHistory]  = useState([])
  const [sidebarWidth, setSidebarWidth] = useState(500)
  const [item,         setItem]         = useState(fromModal ? modalInfoWindowProps : infoWindowProps)
  const [readOnly,     setReadOnly]     = useState(item?.readOnly)

  useEffect(() => {
    setItem(fromModal ? modalInfoWindowProps : infoWindowProps)
    setInfoHistory([...infoHistory, fromModal ? modalInfoWindowProps : infoWindowProps])
    setReadOnly(fromModal ? modalInfoWindowProps.readOnly : infoWindowProps.readOnly)
  }, [infoWindowProps, modalInfoWindowProps])

  const sidebarRef = useRef(null)

  const closeInfoWindow = () => {
    setItem({})
    setInfoHistory([])
    if (fromModal) {
      setModalInfoWindowProps({})
    } else {
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.delete('info_type')
      urlParams.delete('info_id')
      window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`)
      setInfoWindowProps({})
    }

  }

  const historyBack = () => {
    const newHistory       = [...infoHistory]
    const previousResource = newHistory.slice(-2)[0]
    newHistory.splice(-1, 1)
    setItem(previousResource)
    setInfoHistory(newHistory)
  }

  const infoWindowTypeComponent = useMemo(() => {
    switch(item?.resourceType) {
      case 'units': return (
        <UnitContextProvider serverUnit={item}>
          <InfoUnit
            readOnly        = {readOnly}
            fromModal       = {fromModal}
            sidebarWidth    = {sidebarWidth}
            infoHistory     = {infoHistory}
            historyBack     = {historyBack}
            closeInfoWindow = {closeInfoWindow}
          />
        </UnitContextProvider>
      )

      case 'amenities': return (
        <AmenityContextProvider serverAmenity={item}>
          <InfoAmenity
            readOnly        = {readOnly}
            fromModal       = {fromModal}
            sidebarWidth    = {sidebarWidth}
            infoHistory     = {infoHistory}
            historyBack     = {historyBack}
            closeInfoWindow = {closeInfoWindow}
          />
        </AmenityContextProvider>
      )

      case 'ongoing': return (
        <InfoOngoing equipment={item} />
      )

      // TODO
      case 'users': return (
        <InfoUser item={item} />
      )

      case 'reports': return (
        <ReportContextProvider serverReport={item}>
          <InfoReport
            readOnly        = {readOnly}
            fromModal       = {fromModal}
            sidebarWidth    = {sidebarWidth}
            infoHistory     = {infoHistory}
            historyBack     = {historyBack}
            closeInfoWindow = {closeInfoWindow}
          />
        </ReportContextProvider>
      )

      // TODO
      case 'forms': return (
        <InfoForm form={item} />
        // const fetchForm = () => {
        //   fetchApi({
        //     url:      `/forms/${item.id}/rep_show.json`,
        //     callback: data => setItem({...data, resourceType: 'forms' }),
        //     // followUps: [() => setLoading(false)]
        //   })
        // }

      )
    }
  }, [item])


  const rsMouseDownHandler = (e) => {
    const x = e.clientX
    const sbWidth = window.getComputedStyle(sidebarRef.current).width
    const initialWidth = parseInt(sbWidth, 10)

    const mouseMoveHandler = (e) => {
      document.body.classList.add('moving')
      const dx = x - e.clientX // Resize from left to right
      const newWidth = initialWidth + dx

      if (newWidth >= 350 && newWidth <= (window.innerWidth * 0.8)) {
        setSidebarWidth(newWidth)
      }
    }

    const mouseUpHandler = () => {
      document.body.classList.remove('moving')
      document.removeEventListener('mouseup', mouseUpHandler)
      document.removeEventListener('mousemove', mouseMoveHandler)
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }

  return (
    <>
      {item?.id && isDesktop &&
        <Style.InfoWindowResizer
          right       = {sidebarWidth}
          onMouseDown = {rsMouseDownHandler}
        >
          <FontAwesomeIcon icon="grip-lines-vertical" />
        </Style.InfoWindowResizer>
      }
      <Style.InfoWindow
        ref            = {sidebarRef}
        width          = {sidebarWidth}
        isDesktop      = {isDesktop}
        displayed      = {item?.id}
        fromModal      = {fromModal}
      >
        {/* <Style.InfoHeader width={sidebarWidth} isDesktop={isDesktop}>
          <div style={{ display: 'flex', gap: '4px' }}>
            {infoHistory.length > 1
              ? <Style.BackIcon onClick={historyBack}>
                <FontAwesomeIcon icon="caret-left" />
              </Style.BackIcon>
              : <div></div>
            }

            {item?.resourceType && <Tag
              icon       = {<FontAwesomeIcon icon="circle-info" />}
              background = 'var(--rep-neutral-light)'
              color      = 'var(--rep-neutral-primary)'
            >
              {title}
            </Tag>
            }
          </div>

          <Style.CloseIcon onClick={closeInfoWindow}>
            <FontAwesomeIcon icon="times" />
          </Style.CloseIcon>
        </Style.InfoHeader> */}
        <Style.InfoContent fromModal={fromModal}>
          {infoWindowTypeComponent}
        </Style.InfoContent>

      </Style.InfoWindow>
    </>
  )
}

export default InfoWindow
