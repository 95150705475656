import React from 'react'

import WithTooltip from '@components/WithTooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

interface ExpanderProps extends React.PropsWithChildren {
  expanded:    boolean
  setExpanded: (any) => void
}

const Expander: React.FC<ExpanderProps> = ({
  expanded,
  setExpanded,
  children
}) => {

  const { i18n } = useGlobalContextState()

  return (
    <Style.CroppedText expanded={expanded}>
      {children}
      <Style.Expander
        expanded = {expanded}
        onClick  = {() => setExpanded(actual => !actual)}
      >
        <WithTooltip
          content = {
            expanded
              ? i18n.t('shared.actions.fold')
              : i18n.t('shared.actions.expand')
          }
        >
          {expanded
            ? <FontAwesomeIcon icon='caret-up' />
            // : <FontAwesomeIcon icon='caret-down' />
            : i18n.t('shared.actions.view_all')
          }
        </WithTooltip>
      </Style.Expander>
    </Style.CroppedText>
  )
}

export default Expander
