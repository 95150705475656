import styled from 'styled-components'

export const RemarkStatus = styled.div`
  color: ${props => {
    switch(props.status) {
      case 'open':       return 'var(--rep-warning)'
      case 'closed':     return 'var(--rep-success)'
      case 'canceled':   return 'var(--rep-neutral-primary)'
      default:           return 'var(--rep-neutral)'
    }
  }};

  border-radius:   16px;
  font-weight:     300;
  font-size:       .7rem;
  display:         flex;
  justify-content: center;
  align-items:     center;
  text-transform:  capitalize;
  svg     { margin-right: 2px; }
  &:hover { cursor: default; }
`

export const RemarkTitle = styled.h3`
  font-weight:     bolder;
  font-size:       1rem;
  font-weight:     450;
  text-align:      left;
  font-family:     'Inter', sans-serif;
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  margin-bottom:   0;
`
