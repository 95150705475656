import React, { useReducer } from 'react'

import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'
import Select     from '@components/Form/Select'
import Textarea   from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import { MACHINE_ACTIONS, machineStopReducer } from '@reducers/machineStopReducer'

import IAmenity from '@interfaces/IAmenity.d'
import IUnit from '@interfaces/IUnit.d'

interface MachineStopForm {
  equipment:     IAmenity | IUnit
  updateMethods: {
    machineStop: (object) => void
  }
}

const MachineStopForm: React.FC<MachineStopForm> = ({
  equipment,
  updateMethods
}) => {

  const { i18n, closeModal, fetchApi } = useGlobalContextState()

  const [_stopState, stopDispatch]    = useReducer(machineStopReducer, {
    stop:      equipment.machine_stop,
    equipment: equipment,
    fetchApi,
    updateMethods
  })

  const machineStop = (data, fieldset) => stopDispatch({
    type:      MACHINE_ACTIONS.STOP,
    equipment,
    data,
    fieldset,
    callbacks: [closeModal]
  })

  return (
    <Form callback={machineStop}>
      <FormStyle.Label>
        {i18n.t('machine_stop.machine_stop')}
      </FormStyle.Label>

      <Select
        name         = 'machine_stop_type_id'
        searchUrl    = '/machine_stop_types/rep_search'
        defaultValue = {[]}
        label        = {i18n.t('machine_stop.machine_stop_type')}
        placeholder  = {i18n.t('machine_stop.machine_stop_type')}
        format       = {{ content: 'name', value: 'id' }}
        marginY      = 'M'
        search
        required
      />

      <Textarea
        label = {i18n.t('shared.description')}
        name  = "description"
      />

      <DatePicker
        name = 'stopped_at'
        date = {new Date().toISOString()}
        showTime
      />
    </Form>
  )
}

export default MachineStopForm
