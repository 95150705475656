import styled from 'styled-components'

export const TextareaContainer = styled.div`
  position:   relative;
  margin-top: 16px;
  flex-grow:  1;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
`

export const Textarea = styled.textarea`
  padding:        16px 8px;
  width:          100%;
  outline:        none;
  border:         1px solid transparent;
  transition:     .3s ease;
  box-sizing:     border-box;
  color:          var(--rep-neutral-primary);
  max-height:     ${props => props.maxHeight ? props.maxHeight : 'initial'};
  border:         1px solid ${props => props.required ? 'var(--rep-warning)' : 'var(--rep-primary-light)'};
  border-radius:  4px;
  border-bottom:  1px solid ${props => props.required ? 'var(--rep-warning)' : 'var(--rep-primary-light)'};
  text-size-adjust: none;
  font-size:        1rem;
  ${props => (props.hasValue) && `
      background:     white;
      border:         1px solid var(--rep-primary-light);
      border-radius:  5px;
    `
}
  ${props => (props.focused && props.hasValue) && `
      border:         1px solid var(--rep-primary);
    `
}
  background: ${props => props.disabled ? 'var(--rep-neutral-light)' : null};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-neutral)'};
  }
  :-ms-input-placeholder {
    color: ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-neutral)'};
  }
  @media only screen and (hover: none) and (pointer: coarse) {
    font-size: 11px;
    &:focus { font-size: 16px }
  }
`

export const TextareaLabel = styled.label`
  position:    absolute;
  color:       ${props => props.required ? 'var(--rep-warning)' : 'var(--rep-neutral)'};
  font-size:   ${props => props.active ? '.6rem' : '1em' };
  top:         ${props => props.active ? '-8px' : '1em'};
  left:        8px;
  transition:  .3s ease;
  font-weight: 400;
  padding:     4px;
  font-size:   .7rem;
  background:  ${props => props.active && !props.disabled ? 'white' : 'transparent' };
`

export const LockIcon = styled.div`
  position: absolute;
  right:    8px;
  top:      8px;
  color:    var(--rep-primary);
`
