const useHumanMs = ms => {
  return Math.floor(ms / 3600)
    .toString()
    .padStart(2, '0')
					+ 'h' +
    Math.floor((ms / 60) % 60)
      .toString()
      .padStart(2, '0')
}

export default useHumanMs
