import React, { useEffect, useMemo, useRef, useState }      from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Style from './style'

import IButton from './types.d'
/**
 * @see Interface {@link IButton}
 * @example
 * <Button click={() => { console.log('hey!')}}>
 *  Click me 😊
 * </Button>
 */
const Button: React.FC<IButton> = ({
  children,
  click,
  disabled,
  focusable = true,
  icon,
  background,
  hover,
  border,
  color  = 'var(--rep-primary)',
  loading,
  size,
  type,
  align,
  marginY,
  alingSelf,
  style,
  fullWidth = false,
  confirm   = false,
  confirmText,
  confirmIcon
}) => {

  const { isDesktop } = useGlobalContextState() || { isDesktop: true }

  const buttonRef = useRef(null)

  const uniqueId = useMemo(() => window.btoa(Math.random().toString()).substring(10, 15), [])

  // useOuterClick(buttonRef, () => setShowConfirm(false))
  useEffect(() => {
    const handleClick = (e: Event) => {
      buttonRef.current &&
      e.target.id !== uniqueId &&
      !buttonRef.current.contains(e.target) && setShowConfirm(false)
    }

    if (buttonRef.current) { document.addEventListener('click', handleClick) }

    return () => document.removeEventListener('click', handleClick)
  }, [buttonRef])

  const [animation,   setAnimation]   = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleKeyDown = event => {
    if (['Space', 'Enter'].includes(event.code))
      !disabled ? clickEvent(event) : null
  }

  const clickEvent = (event) => {
    if (confirm && !showConfirm) {
      setShowConfirm(true)
    } else if (!confirm || (confirm && showConfirm))  {
      setAnimation(true)
      setTimeout(() => setAnimation(null), 500)
      !disabled ? click(event) : null
    }
  }

  return (
    <Style.Button
      ref           = {buttonRef}
      isDesktop     = {isDesktop}
      onClick       = {clickEvent}
      type          = {type}
      color         = {color}
      border        = {border}
      background    = {background}
      hover         = {hover}
      size          = {size}
      align         = {align}
      marginY       = {marginY}
      alingSelf     = {alingSelf}
      tabIndex      = {focusable ? 0 : '-1'}
      onKeyDown     = {handleKeyDown}
      showAnimation = {animation}
      fullWidth     = {fullWidth}
      confirm       = {showConfirm}
      stye          = {style}
    >
      {loading
        ? <FontAwesomeIcon icon="spinner" spin />
        : <>
          {confirm && showConfirm && confirmIcon}
          {!!icon && (!confirm || !showConfirm) &&
            <Style.Icon
              id={uniqueId}
            >
              {icon}
            </Style.Icon>
          }
          {showConfirm
            ? confirmText || 'Confirm ?'
            : children
          }
        </>
      }
    </Style.Button>
  )
}

export default Button
