import styled from 'styled-components'

export const Container = styled.div`
  display:     flex;
  align-items: center;
  gap:         8px;
`

export const Label = styled.label`
  color:       var(${props => props.color});
  user-select: none;
  font-weight: 600;
  &:hover { cursor: pointer }
`

export const Toggle = styled.div`
  border-radius: 100px;
  padding:       2px;
  `

export const BowlContainer = styled.div`
  width:          60px;
  display:        flex;
  align-items:    center;
  transition:     1s ease;
  min-height:     2.5em;
  position:       relative;
  border-radius:  100px;
  background:     var(${props => props.color}-light);
  user-select:    none;
  &:hover {
    cursor: pointer;
  }
`

export const Bowl = styled.div`
  transition:      .3s ease;
  width:           24px;
  padding:         1px;
  height:          20px;
  background:      var(${props => props.color});
  position:        absolute;
  border-radius:   25px;
  text-align:      ${props => props.position};
  display:         flex;
  color:           white;
  justify-content: center;
  align-items:     center;
  ${props => props.position === 'right' ? 'margin-left: calc(100% - 28px);' : 'margin-left: 4px;'}
`
