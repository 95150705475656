import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SelectOption }          from '@components/Form/Select/style'

const CustomTypeOption = ({
  option,
  click,
  selected,
  ariaSelected
}) => {

  const TypeStyle = styled.div`
    color: ${props => props.color ? props.color : 'initial'};
  `

  return (
    <SelectOption
      onClick  = {click}
      selected = {selected || ariaSelected}
    >
      <TypeStyle color={option.color}>
        <FontAwesomeIcon icon={option.logo} /> {option.name}
      </TypeStyle>
    </SelectOption>
  )
}

export default CustomTypeOption
