import React, { useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon }                     from '@fortawesome/react-fontawesome'

import Button   from '@components/Button'
import Dropdown from '@components/Dropdown'
import Textarea from '@form/Textarea'

import * as Style     from './style'

import ChatWriteProps from './types.d'

import { uploadFunction } from '@form/FileUploader'

import { useGlobalContextState } from '@context/GlobalContext'

import { CONVERSATION_ACTIONS, conversationReducer } from '@reducers/index'

/** @description Writing part for the Chat
 * @example
 * <ChatWrite
 *    conversation = {conversation}
 *    report       = {report}
 *    updateReport = {updateReport}
 * />
*/
const ChatWrite: React.FC<ChatWriteProps> = ({
  conversation,
  updateMethods
}) => {

  const { i18n, fetchApi, initAPI } = useGlobalContextState()

  const [_state, dispatch] = useReducer(conversationReducer, { initAPI, fetchApi, updateMethods })

  const sendMessage = message => dispatch({ type: CONVERSATION_ACTIONS.ADD_MESSAGE, conversation, message: message, setIsSending })
  const sendFile    = file    => dispatch({ type: CONVERSATION_ACTIONS.ADD_FILE, conversation, file: file })

  const fileInputRef  = useRef(null)

  const [isSending, setIsSending] = useState(false)

  const chatRef = useRef(null)

  const openFileForm     = ()    => fileInputRef.current.click()
  const handleFileChange = event => uploadFunction(sendFile, event.target.files)

  const handleChange = event => {
    if (event.which === 13 && !event.shiftKey) {
      sendMessageFn(event)
    }
  }

  const sendMessageFn = (e = null) => {
    e?.preventDefault()
    if (chatRef.current.value) {
      sendMessage(chatRef.current.value)
      chatRef.current.value = ''
    }
  }

  return (
    <Style.ChatWrite>
      <Dropdown
        icon       = {<FontAwesomeIcon icon='ellipsis' />}
        background = 'var(--rep-primary-light)'
        hover      = 'var(--rep-primary-light)'
        options    = {[
          {
            content: i18n.t('document.file'),
            icon:    <FontAwesomeIcon icon="paperclip" />,
            click:   openFileForm
          }
        ]}
      />

      <input
        type     = "file"
        ref      = {fileInputRef}
        onChange = {handleFileChange}
        hidden
      />

      <Style.ChatForm onSubmit={sendMessageFn}>
        <Textarea
          forwardRef   = {chatRef}
          name         = 'message'
          change       = {handleChange}
          placeholder  = {i18n.t('conversation.message_placeholder')}
          maxHeight    = '4em'
        />
      </Style.ChatForm>
      <Button
        disabled   = {isSending}
        icon       = {<FontAwesomeIcon icon={['far', 'paper-plane']} />}
        click      = {sendMessageFn}
        background = 'var(--rep-primary)'
        color      = 'white'
        alingSelf  = 'stretch'
      />
    </Style.ChatWrite>
  )
}

export default ChatWrite
