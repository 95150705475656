import styled, { css } from 'styled-components'

export const Switch = styled.div`
  display:               grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap:              8px;
  width:                 ${props => props.allWidth ? '100%' : 'initial'};
  border-radius:         5px;
  /* height:                100%; */
  margin:                ${props => props.marginY ? 0 : '8px 0'};
  margin-top: ${props => {
    switch (props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch (props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
`

export const multiselectStyles = css`
  border:        1px solid var(--rep-neutral-primary);
  color:         var(--rep-neutral-primary);
  border-radius: 5px;
`

export const SwitchOption = styled.div`
    box-sizing:              border-box;
    flex-grow:               ${props => props.allWidth ? '0' : '1'};
    text-align:              center;
    font-weight:             500;
    padding:                 8px;
    border:                  1px solid var(--rep-primary-light);
    /* border-bottom:           ${props => props.selected ? '3px solid var(--rep-primary)' : '1px solid var(--rep-primary-light)'}; */
    color:                   var(--rep-primary);
    background:              ${props => props.selected ? 'var(--rep-primary-light)' : 'white'};
    min-height:              3rem;
    height:                  100%;
    display:                 flex;
    justify-content:         center;
    align-items:             center;
    border-radius:           5px;
    border-top-left-radius:  5px;
    border-top-right-radius: 5px;
    user-select:             none;
    transition-property:     background;
    transition:              .3s ease;
    ${props => props.multiselect && props.selected && multiselectStyles}
    > svg   { margin-right: 4px }
    &:hover { cursor: pointer }
`
