import styled from 'styled-components'

export const DropZoneStyles = styled.div`
  display:          flex;
  justify-content:  center;
  align-items:      center;
  border:           2px dashed var(--rep-primary-light);
  padding:          16px;
  color:            var(--rep-neutral);
  flex-direction:   column;
  text-align:       center;
  margin-top:       8px;
  height:           300px;
  &:hover { cursor: pointer };
`

export const FilesStyles = styled.div`
  display:                ${props => props.grid ? 'grid' : 'block'};
  grid-template-columns:  ${props => props.gridSize ? `repeat(${props.gridSize}, ${95 / props.gridSize}%)` : 'repeat(5, 18.5%)'};
  margin:                 8px 0;
  gap:                    8px;
  flex-wrap:              wrap;
`
