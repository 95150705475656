import React, { useEffect, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout    from '@components/Callout'
import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'
import TaskBox    from '@components/Task/TaskBox'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import IMaintenance from '@interfaces/IMaintenance.d'

interface MaintenanceFromProps {
  maintenance:       IMaintenance
  updateMaintenance: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
  fromRails?:        boolean
}
const MaintenanceForm: React.FC<MaintenanceFromProps> = ({
  maintenance,
  updateMaintenance,
  maintenance_id,
  // fromRails = false
}) => {

  const { i18n, fetchApi, CONSTANTS, fromRails } = useGlobalContextState()

  const { RECURRENCY }                     = CONSTANTS.RECURRENCY.MODES

  const [maintenanceData, setMaintenanceData] = useState(maintenance)
  const [_loading,        setLoading]         = useState(false)
  const [_state,          formDispatch]       = useReducer(maintenanceReducer, {
    fetchApi,
    setLoading
  })

  useEffect(() => {
    // Dirty fix to fetch only when RoR modale is open
    const ror_modale = document.querySelector(`#edit-maintenance-react${maintenance_id}`)
    if (fromRails && ror_modale) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.attributeName === 'class' && ror_modale.classList.contains('show')) {
            // console.log('FETCH!')
            fetchApi({
              url:      `/maintenances/${maintenance_id}`,
              callback: data => setMaintenanceData(data),
            })
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(ror_modale, { attributes: true, childList: false, subtree: false })
    }
  }, [])

  const callback = (data, fieldset) => {
    if (fromRails) {
      formDispatch({
        type:        MAINTENANCE_ACTIONS.UPDATE_MAINTENANCE,
        maintenance: { data: maintenanceData },
        data, callbacks:   [
          () => window.location = '/maintenances/listing'
        ],
        fromRails
      })
    } else {
      updateMaintenance(data, fieldset)
    }
  }

  const addUser = (task, users) => {
    // if (task.users.map(u => u.id).includes(user.id)) return
    console.log(task)
    console.log(users)
    const taskData = {...task, users }

    let tasks = maintenanceData.tasks
    tasks = tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  const selectProvider = (task, provider) => {
    const taskData = {...task, provider }
    let tasks = maintenanceData.tasks
    tasks = tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  if (!maintenanceData) return <div>Loading…</div>

  return <Form callback={callback}>
    {maintenanceData.mode == RECURRENCY &&
      <>
        <Callout
          icon       = {<FontAwesomeIcon icon="lightbulb" />}
          color      = 'var(--rep-warning)'
          background = 'var(--rep-warning-light)'
        >
          {i18n.t('maintenance.callout.will_not_affect_previous')}
        </Callout>

        <FormStyle.Label>
          {i18n.t('maintenance.start_date')}
        </FormStyle.Label>
        <DatePicker
          name = 'start'
          date = {maintenanceData.start}
          showTime
        />
      </>
    }

    <FormStyle.Label>
      {i18n.t('maintenance.default_assignment')}
    </FormStyle.Label>

    {maintenanceData.tasks.length
      ? maintenanceData.tasks.map((task, key) =>
        <TaskBox
          key            = {`${task.name}-${key}`}
          task           = {task}
          selectUsers    = {users    => addUser(task, users)}
          selectProvider = {provider => selectProvider(task, provider)}
          defaultNotify = {task.notify}
          // removeUser   = {(task, user) => removeUser(task, user)}
        />)
      : <Callout
        marginY    = 'M'
        background = 'var(--rep-warning-light)'
        color      = 'var(--rep-warning)'
        border     = 'var(--rep-warning)'
      >
        {i18n.t('maintenance.no_tasks')}
      </Callout>
    }
  </Form>
}

export default MaintenanceForm
