import styled from 'styled-components'

export const ChatWrite = styled.div`
  background:    white;
  display:         flex;
  position:        relative;
  justify-content: space-between;
  align-items:     center;
  padding:         8px;
  gap:             8px;
  border:          1px solid var(--rep-neutral-primary-light);
  border-top:      none;
  border-radius:   5px;
  border-top-left-radius:  0;
  border-top-right-radius: 0;
`

export const ChatForm = styled.form`
  flex-grow: 1;
`
