import styled, { keyframes } from 'styled-components/macro'

const nameSize = '200px'
const gridSize = '36px'

export const SwitchMenu = styled.div`
  width:         100%;
  margin-bottom: 8px;
  margin-top:    8px;
`

export const Filters = styled.div`
  background:    white;
  border:        1px solid var(--rep-neutral-primary-light);
  border-radius: 8px;
  width:         100%;
  margin-bottom: 8px;
  padding:       8px;
  margin-top:    8px;
`

export const ButtonGroup = styled.div`
  display:     flex;
  align-items: center;
  gap:         8px;
  margin:      8px;
  flex-wrap:   wrap;
`

export const ButtonGroupBetween = styled.div`
  display:         flex;
  align-items:     center;
  justify-content: space-between;
  gap:             8px;
  margin:          8px;
`

export const CalendarContainer = styled.div`
  display:        flex;
  flex-direction: column;
  padding:        0 8px;
  min-width:      776px;
  max-height:     99dvh;
`

export const CalendarTable = styled.div`
  display: grid;
  grid-template-columns: ${nameSize} repeat(${props => props.length}, ${gridSize});
  grid-template-areas: "header"
                       "content";
  border-radius: 5px;
  width: fit-content;
  grid-gap:1px;
  ${props => props.main && `
    border-radius: 5px;
  `}

  ${props => props.stickyTop && `
    position: sticky;
    top: 0;
  `
}
`

export const CalendarContent = styled.div`
  /* min-height: 80dvh; */
  /* max-height:    calc(76dvh + 8px); */
  /* height: minmax(fit-content, 100vh); */
  flex-grow:     1;
  overflow:      scroll;
  position:      relative;
  border-radius: 5px;
  background:    white;
  border:        1px solid var(--rep-neutral-primary-light);
`

export const CalendarName = styled.div`
  width:                 ${nameSize};
  display:               grid;
  grid-template-columns: 20px auto 30px 10px;
  align-items:           center;
  padding:               0px 8px;
  user-select:           none;
  box-shadow:            0 0 ${props => props.hideShadow ? -1 : 0}px 1px var(--rep-neutral-primary-light);
  background:            ${props => props.opened ? 'var(--rep-primary-light)' : props.background ? props.background : 'white' };
  position:              sticky;
  left:                  0;
  z-index:               100;
  font-weight:           500;
  border-left:           ${props => props.borderLeft ? `${props.borderLeft}px solid var(--rep-primary)` : 'none' };
  color:                 ${props => props.color ? props.color : 'var(--rep-neutral-primary)'};
`

export const CalendarControlName = styled.div`
  overflow:    hidden;
  display:     flex;
  align-items: center;
  .mainline--name {
    width:         100%;
    white-space:   nowrap;
    overflow:      hidden;
    text-overflow: ellipsis;
  }
`

export const CalendarLine = styled.div`
  grid-column: 1 / span ${props => props.length};
  /* Activate when subgrid is widely supported */
  /* grid-template-columns: subgrid; */
  display:  inline-grid;
  grid-gap: 1px;

  grid-auto-rows: minmax(${gridSize}, auto);
  grid-template-columns: ${nameSize} repeat(${props => props.length + 1}, ${gridSize});
  ${props => !props.noAlternateBackground ? `
    &:nth-child(even) {
      background-color: rgb(251, 252, 253);
    }
    &:nth-child(odd) {
      background-color: white;
    }
    ` : ''};

background-color: ${props => props.background ? props.background : 'white'};
${props => props.clickable &&
  `&:hover {
      cursor: pointer;
      background: var(--rep-primary-light);
    }`
}
`

export const CalendarBox = styled.div`
  width:           ${gridSize};
  background:      ${props => props.background ? props.background : 'transparent'};
  box-shadow:      0 0 ${props => props.softBorder ? -1 : 0}px 1px ${props => props.border ? props.border : 'var(--rep-neutral-primary-light)'};
  padding:         4px;
  padding-top:     8px;
  color:           var(--rep-neutral-primary);
  display:         flex;
  justify-content: flex-start;
  align-items:     center;
  flex-direction:  column;
  font-weight:     500;
  gap:             4px;
  font-size:       ${props => props.fontSize || 'auto'};
  ${props => props.currentWeek && `
    background: var(--rep-neutral-primary-light);
    color: var(--rep-black);
    `}
`

export const CalendarMonth = styled.div`
  grid-area:       header;
  text-transform:  capitalize;
  grid-column:     span ${props => props.weeks};
  font-weight:     600;
  display:         flex;
  justify-content: center;
  align-items:     center;
  height:          2em;
  &:first-of-type {
    border-top-left-radius: 5px;
  }
  &:last-of-type {
    border-top-right-radius: 5px;
  }

  color:      var(--rep-neutral-primary);
  background: ${props => props.background ? props.background : 'white'};
  ${props => props.currentMonth && `
    background: var(--rep-neutral-primary-light);
    color: var(--rep-black);
  `}
  ${props => props.sticky && `
    position: sticky;
    left: 0;
  `}
`

export const CalendarEmpty = styled.div`
  height:          ${props => props.size === 'small' ? '3rem': '5dvh'};
  position:        sticky;
  left:            0;
  top:             0;
  display:         flex;
  width:           100%;
  justify-content: center;
  flex-direction:  column;
  align-items:     center;
  font-size:       ${props => props.size === 'small' ? '1rem': '1.2rem'};
  width:           100%;
  color:           var(--rep-neutral-primary);
  grid-column:     1 / span 15;
  padding:         16px;
  &:hover {
    cursor: ${props => props.onClick ? 'pointer' : 'default' };
  }
`

export const CalendarReportBox = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  flex-direction:  column;
  gap:             8px;
`

export const MaintenanceBox = styled.div`
  h3 {
    font-size: 1rem;
    margin:    0;
  }
  p {
    color: var(--rep-neutral);
    margin:       0 0 4px 0;
    padding-left: 4px;
  }
  border:        1px solid var(--rep-primary-light);
  border-radius: 5px;
  padding:       8px;
`

export const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`

export const CalendarLoadingContainer = styled.div`
  position:   relative;
  width:      calc(${nameSize} + calc(${props => props.length} * ${gridSize}));
  background: white;
  padding:    8px 0;
`

export const CalendarLoading = styled.div`
  height:          ${props => props.size === 'small' ? '3rem': '5dvh'};
  position:        sticky;
  left:            0;
  top:             0;
  display:         flex;
  width:           80vw;
  justify-content: center;
  flex-direction:  column;
  align-items:     center;
  font-size:       ${props => props.size === 'small' ? '1rem': '1.2rem'};
  width:           100%;
  color:           var(--rep-neutral-primary);
  grid-column:     1 / span 15;
  padding:         16px;
  &:hover {
    cursor: ${props => props.onClick ? 'pointer' : 'default' };
  }
`

export const Loading = styled.div`
  grid-column: 1 / span ${props => props.length};
  /* Activate when subgrid is widely supported */
  /* grid-template-columns: subgrid; */
  display: inline-grid;
  border-bottom: 1px solid var(--rep-neutral-primary-light);
  grid-gap: 1px;

  grid-auto-rows: minmax(1.5rem, auto);
  grid-template-columns: ${nameSize} repeat(${props => props.length + 1}, 60px);

  height: 1.8rem;
  background: linear-gradient(110deg, var(--rep-neutral-light) 8%, var(--rep-primary-light) 18%, var(--rep-neutral-light) 33%);
  background-size: 200% 100%;
  animation: 1s ${shine} linear infinite;
`
