import styled from 'styled-components'

export const FormContainer = styled.form`
  border:        1px solid var(--rep-primary-middle);
  padding:       8px;
  border-radius: 5px;
`

export const QuestionCheckboxes = styled.div`
  display        : flex;
  flex-direction : column;
  flex-grow      : 1;
`

export const QuestionsContainer = styled.div`
`

export const QuestionContainer = styled.div`
  display:         flex;
  gap:             8px;
  justify-content: space-between;
  align-items:     center;
  margin:          8px 0;
  border:          1px solid var(--rep-primary-light);
  border-radius:   5px;
  padding:         4px 8px;
`

export const QuestionName = styled.div`
  color:       var(--rep-neutral-primary);
  font-weight: 500;
  display:     flex;
  gap:         8px;
  align-items: center;
`
