import React, { useMemo } from 'react'

import Callout from '@components/Callout'

import { useGlobalContextState } from '@context/GlobalContext'

import { IRecurrency } from '@interfaces/IMaintenancePlan.d'

const RecurrencyString: React.FC<{ rules: IRecurrency, type: string }> = ({ rules, type = 'warning' }) => {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { RECURRENCY } = CONSTANTS
  const { FREQUENCIES, FIXED, STOP }= RECURRENCY

  const {
    dayrecurrency,
    interval,
    frequency,
    bysetpos,
    byweekday,
    bymonthday,
    bymonth,
    exclusions,
    stopcondition,
    count,
    until
  } = rules

  const sentence = useMemo(() => {
    let sentence = ''
    sentence += i18n.t('maintenance.recurrence.interval_freq', { interval: interval, frequency: i18n.t(`maintenance.recurrence.frequencies.${frequency}`) })
    sentence += ' '
    if (frequency === FREQUENCIES.WEEKLY && byweekday) {
      sentence += i18n.t('maintenance.recurrence.byweekday', { byweekday: byweekday.split(',').map(day => i18n.t(`maintenance.recurrence.weekdays.${day}`))
        .join(', ') })
    }
    if (dayrecurrency === FIXED.FIXED_DAY && byweekday && bysetpos) {
      sentence += i18n.t('maintenance.recurrence.bysetposweekday', { bysetpos: i18n.t(`maintenance.recurrence.positions.${bysetpos}`), byweekday: i18n.t(`maintenance.recurrence.weekdays.${byweekday}`) })
    }
    if ([FREQUENCIES.MONTHLY, FREQUENCIES.YEARLY].includes(frequency) && dayrecurrency === FIXED.FIXED_DATE && bymonthday) {
      sentence += i18n.t('maintenance.recurrence.bymonthday', { bymonthday: bymonthday })
    }
    if (frequency === FREQUENCIES.YEARLY && bymonth) {
      sentence += ' '
      sentence += i18n.t('maintenance.recurrence.bymonth', { bymonth: i18n.t(`maintenance.recurrence.months.${bymonth}`) })
    }
    if (exclusions?.length) {
      sentence += i18n.t('maintenance.recurrence.exclusion', { start: new Date(exclusions.split(',')[0]).toLocaleDateString(i18n.locale, { day: 'numeric', month: 'numeric' }), stop: new Date(exclusions.split(',')[1]).toLocaleDateString(i18n.locale, { day: 'numeric', month: 'numeric' }) })
    }
    sentence += ' '
    if (stopcondition === STOP.COUNT && count) {
      sentence += i18n.t('maintenance.recurrence.count', { count: count })
    }
    if (stopcondition === STOP.DATE && until) {
      sentence += i18n.t('maintenance.recurrence.until', { until: new Date(until).toLocaleDateString(i18n.locale, { day: 'numeric', month: 'numeric', year: 'numeric' }) })
    }
    if (stopcondition === STOP.NEVER || (!until && !count)) {
      sentence += i18n.t('maintenance.recurrence.forever')
    }
    return sentence
  }, [rules])

  return(
    <Callout
      marginY    = 'S'
      background = {`var(--rep-${type}-light)`}
      color      = {`var(--rep-${type})`}
    >
      {sentence}
    </Callout>
  )
}

export default RecurrencyString
