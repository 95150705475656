import styled from 'styled-components'

export const OngoingTaskStyle = styled.div`
  position        : fixed;
  bottom          : ${props => props.isDesktop ? '0' : '60px'};
  right           : 0;
  width           : ${props => props.isDesktop ? 'calc(100% - 125px)' : '100%'};
  background      : white;
  padding         : 16px;
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  box-shadow      : 0 -20px 20px white;
  overflow-y      : scroll;
  z-index         : 10;
`
export const OngoingInformation = styled.div`
  display         : flex;
  flex-direction  : column;
  justify-content : space-between;
  gap             : 8px;
`
export const OngoingAction = styled.div`
  width      : 100px;
  text-align : center;
`
