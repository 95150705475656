import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGlobalContextState } from '@context/GlobalContext'

import Button from '@components/Button'

import * as Style     from './style'
import ReportCard from '@components/Report/ReportCard'

const InfoOngoing = ({ equipment }) => {

  const { fetchApi } = useGlobalContextState()

  const [ongoingReports,    setOngoingReports]    = useState([])
  const [ongoingPagination, setOngoingPagination] = useState({})
  const [ongoingLoading,    setOngoingLoading]    = useState(false)

  const fetchOngoingReports = initPage => {
    setOngoingLoading(true)
    const page = initPage || ongoingPagination.page || ongoingPagination.pagination?.next || 1
    let equipmentParam
    if (equipment.class_plural === 'units') {
      equipmentParam = `unit_id=${equipment.id}`
    } else if (equipment.class_plural === 'amenities') {
      equipmentParam = `amenity_id=${equipment.id}`
    }
    fetchApi({
      url:       `/reports/rep_ongoing?page=${page}&${equipmentParam}`,
      followUps: [
        data => setOngoingReports(ongoing => [...ongoing, ...data.reports]),
        data => setOngoingPagination(data.pagination),
        () => setOngoingLoading(false)
      ]
    })
  }

  useEffect(() => {
    setOngoingReports([])
    setOngoingPagination({})
    fetchOngoingReports(1)
  }, [equipment])

  return(
    <div>
      <Style.Header>Ongoing tickets for {equipment.name}</Style.Header>

      {equipment.reports_count} tickets

      {ongoingReports.map(report => <ReportCard key={report.id} report={report} fromModal={true} openInfo={false}/>)}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {ongoingLoading
          ? <FontAwesomeIcon icon="spinner" spin />
          : (ongoingPagination.page !== ongoingPagination.last) &&
            <Button
              click={() => fetchOngoingReports(ongoingPagination.next)}
            >
              Load more
            </Button>
        }
      </div>

    </div>
  )
}

export default InfoOngoing
