import styled from 'styled-components'

export const CostHeader = styled.div`
  display:          flex;
  justify-content:  space-between;
  align-items:      center;
  width:            100%;
  gap:              8px;
  flex-wrap:        wrap;
`

export const CostTitle = styled.div`
  font-size:        1rem;
  font-weight:      450;
  display:          flex;
  align-items:      center;
  justify-content:  space-between;
  gap:              4px;
  color:            black;
`

export const CostStatus = styled.div`
  color: ${props => {
    switch(props.status) {
      case 'approved':    return 'var(--rep-success)'
      case 'rejected':    return 'var(--rep-danger)'
      case 'to_approve':  return 'var(--rep-warning)'
      case 'ongoing':     return 'var(--rep-success)'
      default:            return 'var(--rep-neutral)'
    }
  }};
  display:         flex;
  justify-content: center;
  align-items:     center;
  text-transform:  capitalize;

  font-size: .7rem;
  svg     { margin-right: 2px; }
  &:hover { cursor: default; }
`

export const CostTopActions = styled.div`
  display:  flex;
  gap:      8px;
`

export const CostApprove = styled.div`
  display:          flex;
  justify-content:  space-between;
  font-size:        .8rem;
`

export const StatusOption = styled.div`
  display:      flex;
  align-items:  center;
  color:        ${props => props.textColor ? props.textColor : ''};
`

export const Circle = styled.div`
  height:         8px;
  width:          8px;
  border-radius:  4px;
  background:     ${props => props.background ? props.background : ''};
  margin-right:   4px;
`

export const CostDetails = styled.div`
  margin-top:             2px;
  display:                grid;
  grid-template-columns:  1fr 1em 1fr 1fr;
  text-align:             right;
  color:                  var(--rep-neutral-primary);

  h6 {
    color:         var(--rep-neutral);
    font-variant:  small-caps;
    font-size:     .7rem;
    margin-bottom: 0;
  }

  div, h6 {
    text-align:       left;
    display:          flex;
    justify-content:  space-between;
    padding-right:    8px;
  }

  div:nth-child(4n+1), h6:nth-child(4n+1) {
    justify-content: flex-start
  }
  div:nth-child(4n),   h6:nth-child(4n)   {
    justify-content: flex-end;
  }
`

export const costDetailsMaterial = styled.div`
  margin:     8px 0;
  display:    flex;
  text-align: left;
  color:      var(--rep-neutral-primary);

  h6 {
    color:         var(--rep-neutral);
    font-variant:  small-caps;
    font-size:     .7rem;
    margin-bottom: 0;
  }
`

export const CostTotal = styled.div`
  font-weight: 700;
`

export const CostDescription = styled.div`
  text-align:  left;
  color:       var(--rep-neutral-primary);
  display:     flex;
  gap:         2px;
  align-items: center;
  &:hover { cursor: default }
`

export const CostActions = styled.div`
  display:         flex;
  gap:             8px;
  justify-content: flex-end;
  margin:          4px 0;
`

export const CostTypeSelect = styled.div`
  display:  flex;
  gap:      8px;
`

export const CostLine = styled.div`
  border:        1px solid var(--rep-primary);
  border-radius: 5px;
  padding:       8px;
  margin:        8px 0;
`
