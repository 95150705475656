import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button       from '@components/Button'
import Column       from '@components/Column'
import FileUploader from '@components/Form/FileUploader'
import { Filter }   from '@components/Column/Filter'
import Thumbnail    from '@components/Form/FileUploader/Thumbnail'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import * as Style from './style'

interface IRDocument {
  height?:    string
  maxHeight?: string
  grow?:      boolean
  open?:      boolean
}

const ReportDocuments: React.FC<IRDocument> = ({
  open       = true,
  height     = '100%',
  maxHeight  = '100%',
  grow       = true,
  overflow   = 'overflow'
}) => {

  const {
    initAPI,
    i18n,
    showModal,
  } = useGlobalContextState()

  const {
    report,
    updateMethods,
  } = useReportContextState()

  const API = initAPI({
    type:     'reports',
    reportId: report.id
  })

  const headerActions = useMemo(() =>
    [report.permissions.can_create_document &&
      <Button
        key   = 'create-documents'
        icon  = {<FontAwesomeIcon icon="plus" />}
        color = 'var(--rep-primary)'
        hover = 'var(--rep-primary-light)'
        click = {() => showModal({
          title:   i18n.t('document.attach_file'),
          content: <FileUploader
            updateMethods = {updateMethods}
            objectType    = 'report'
            objectId      = {report.id}
            multiple
            certification
            closeModalAfter
          />
        })}
      >
        {i18n.t('actions.add')}
      </Button>], [])

  const filters = useMemo(() => new Filter({
    totalFilteredCount: report.total_documents,
    perPage:            20,
    totalPages:         report.total_documents_pages,
    fetch:              API.fetchDocuments,
    pageCallback:       data => updateMethods.moreDocuments(data),
    callback:           updateMethods.documents
  }), [])

  return (
    <Column
      togglable
      paginated
      open             = {open}
      height           = {height}
      maxHeight        = {maxHeight}
      grow             = {grow}
      overflow         = {'auto'}
      title            = {i18n.t('shared.documents')}
      totalCount       = {report.total_documents}
      currentCount     = {report.documents.length}
      totalPages       = {report.total_documents_pages}
      withFilters      = {false}
      headerActions    = {headerActions}
      filters          = {filters}
      marginBottom     = '8px'
    >
      <Style.FileListStyles>
        {report.documents.map(doc =>
          <Thumbnail
            key              = {doc.id}
            file             = {doc}
            certification    = {!!report?.legal}
            updateMethods    = {updateMethods}
            destroyable      = {report?.id === doc.report_id}
            modaleDetails
            closeModalAfter
          />
        )}
      </Style.FileListStyles>
    </Column>
  )
}

export default ReportDocuments
