import styled from 'styled-components'

export const MachineStop = styled.div`
`

export const MachineStopHeader = styled.div`
  display:     flex;
  align-items: center;
  gap:         4px;
  margin:      4px 0;
  h3 { margin-bottom: 0 }
`

export const MachineStopLine = styled.div`
  display:     flex;
  align-items: center;
  gap:         4px;
`
