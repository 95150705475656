import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FieldInput from '@components/Form/FieldInput'
import Form       from '@components/Form/Form'
import Input      from '@components/Form/Input'
import Select     from '@components/Form/Select'
import SelectTree from '@components/Form/SelectTree'
import Textarea   from '@components/Form/Textarea'

import * as FormStyle from '@form/FormStyles'

import COUNTRIES from './countries.json'

import IUnit from '@interfaces/IUnit.d'

import { useGlobalContextState } from '@context/GlobalContext'

interface UnitFormProps {
  unit:       IUnit,
  createUnit: (any) => void
  updateUnit: (any) => void
}

const UnitForm: React.FC<UnitFormProps> = ({ unit, createUnit, updateUnit }) => {

  const { i18n  } = useGlobalContextState()

  const [selectedCountry, setSelectedCountry] = useState(unit?.country)
  const [parent,          setParent]          = useState(unit?.parent)

  const addFormData = formData => formData.append('ancestry',  parent?.id)
  const callback = unit?.id ? updateUnit : createUnit

  return <Form
    callback       = {callback}
    addFormData    = {addFormData}
    submitDisabled = {unit?.id && !unit.permissions.can_update_unit}
  >
    <FormStyle.Header><FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}</FormStyle.Header>
    <Input
      name         = 'name'
      label        = {i18n.t('shared.name')}
      type         = 'text'
      defaultValue = {unit.name}
      marginY      = 'M'
    />

    <Input
      name         = 'alt_name'
      label        = {i18n.t('unit.alt_name')}
      type         = 'text'
      defaultValue = {unit.alt_name}
      marginY      = 'M'
    />

    <Textarea
      name         = "description"
      label        = {i18n.t('shared.description')}
      defaultValue = {unit.description}
      marginY      = 'M'
    />

    <FormStyle.Header><FontAwesomeIcon icon="folder-tree" />{i18n.t('shared.general_informations')}</FormStyle.Header>
    <SelectTree
      name           = 'ancestry'
      label          = {i18n.t('unit.parent_unit')}
      selected       = {parent}
      callback       = {setParent}
      withEmpty      = {true}
      searchUrl      = '/units/rep_search'
      filters        = {[{
        id:      'root',
        name:    'Parent',
        filters: { roots: 'true' }
      }]}
      format         = {{ content: 'name', value: 'id' }}
      marginY        = 'M'
      subFilterName  = 'for_unit'
      subElementName = {i18n.t('unit.subunit')}
    />

    <FormStyle.Header><FontAwesomeIcon icon="map-pin" />{i18n.t('unit.building_info')}</FormStyle.Header>
    <Input
      name         = "unit_owner"
      label        = {i18n.t('unit.unit_owner')}
      type         = "text"
      marginY      = 'M'
      defaultValue = {unit.unit_owner}
    />

    <Input
      name         = "terrain_owner"
      label        = {i18n.t('unit.terrain_owner')}
      type         = "text"
      marginY      = 'M'
      defaultValue = {unit.terrain_owner}
    />

    <Input
      name         = "cadastre"
      label        = {i18n.t('unit.cadastre')}
      type         = "text"
      marginY      = 'M'
      defaultValue = {unit.cadastre}
    />

    <Input
      name         = "address"
      label        = {i18n.t('shared.address')}
      type         = "text"
      marginY      = 'M'
      defaultValue = {unit.address}
    />

    <Input
      name         = "city"
      label        = {i18n.t('shared.city')}
      type         = "text"
      marginY      = 'M'
      defaultValue = {unit.city}
    />

    <Input
      name         = "zip_code"
      label        = {i18n.t('shared.zip_code')}
      type         = "text"
      marginY      = 'M'
      defaultValue = {unit.zip_code}
    />

    <Select
      name          = 'country'
      label         = { i18n.t('shared.country')}
      defaultValue  = {[COUNTRIES.find(c => c.value === selectedCountry || c.value === selectedCountry?.value)]}
      format        = {{ content: 'label', value: 'value' }}
      marginY       = 'M'
      options       = {COUNTRIES}
      search
      cleanAfterSelect
      callback      = {(data) => setSelectedCountry(data.object)}
    />

    <FormStyle.Header marginY='S'><FontAwesomeIcon icon="list" />{i18n.t('custom_field.custom_fields')}</FormStyle.Header>

    {!!unit.custom_fields?.length &&
      unit.custom_fields.map(field =>
        <FieldInput
          key          = {field.id}
          field        = {field}
          defaultValue = {field.value}
          marginY      = 'M'
        />)
    }

    <FormStyle.Header><FontAwesomeIcon icon="money-bill" />{i18n.t('offer_line.costs')}</FormStyle.Header>

    <Select
      name          = 'invoice_contact_id'
      label         = {i18n.t('invoice_contact.invoice_contact')}
      searchUrl     = '/invoice_contacts/search'
      defaultValue  = {unit?.invoice_contact ? [unit.invoice_contact] : []}
      format        = {{ content: 'name', value: 'id', details: 'add_name' }}
      placeholder   = {i18n.t('invoice_contact.your_invoice_contacts')}
      marginY       = 'M'
      search
      withEmpty
    />

    <Input
      name         = "transport"
      label        = {i18n.t('unit.transport')}
      type         = "number"
      marginY      = 'M'
      hint         = '€'
      defaultValue = {unit.transport_cost}
    />
  </Form>
}

export default UnitForm
