import React, { useState } from 'react'

import DatePicker from '@components/Form/DatePicker'
import Form       from '@components/Form/Form'
import Select     from '@components/Form/Select'

import * as FormStyle from '@form/FormStyles'

import IAmenity from '@interfaces/IAmenity.d'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaintenancePlanCard from '@components/MaintenancePlan/MaintenancePlanCard'

import IUnit from '@interfaces/IUnit.d'

interface AddEquipmentToPlanProps {
  equipment: IAmenity | IUnit
  addToPlan: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const AddEquipmentToPlan: React.FC<AddEquipmentToPlanProps> = ({ equipment, addToPlan }) => {

  const { i18n } = useGlobalContextState()

  const [selectedPlan, setSelectedPlan] = useState()

  return <Form callback={addToPlan}>
    <FormStyle.Header><FontAwesomeIcon icon="circle-info" />Add {equipment.name} to</FormStyle.Header>

    <Select
      name             = 'plan'
      label            = {i18n.t('maintenance.maintenance_plan')}
      defaultValue     = {[selectedPlan]}
      callback         = {plan => setSelectedPlan(plan.object)}
      emptyCallback    = {() => setSelectedPlan(null)}
      searchUrl        = {'/maintenance_plans/search'}
      filters = {[{
        id:      'excluded',
        name:    'excluded',
        filters: { exclude_type: equipment.class_plural, exclude_id: equipment.id}
      }]}
      defaultFilter = 'excluded'
      format           = {{ content: 'name', value: 'id'}}
      marginY          = 'M'
      search
      withEmpty
    />

    {selectedPlan && <>
      <FormStyle.UnitArrow><FontAwesomeIcon icon="arrow-down" /></FormStyle.UnitArrow>
      <MaintenancePlanCard plan={selectedPlan} />
    </>
    }

    <FormStyle.UnitArrow><FontAwesomeIcon icon="arrow-down" /></FormStyle.UnitArrow>

    <FormStyle.Header><FontAwesomeIcon icon="circle-info" />{i18n.t('maintenance.from_date')}</FormStyle.Header>

    <DatePicker
      name = "start"
      date = {new Date()}
    />
  </Form>
}

export default AddEquipmentToPlan
