import React, { useState } from 'react'

import * as Style from './style'

import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import { OrderTagContentProps } from './types.d'

const OrderTag: React.FC<OrderTagContentProps> = ({
  children,
  click,
  marginY,
  value,
  active,
  direction
}) => {

  const states = {
    NULL: {
      value:      value,
      direction:  'NULL',
      icon:       <FontAwesomeIcon icon={['fas', 'arrows-up-down']} />,
      background: 'transparent',
      border:     'var(--rep-primary-light)',
      color:      'var(--rep-neutral-primary-middle)'
    },
    ASC: {
      value:      value,
      direction:  'ASC',
      icon:       <FontAwesomeIcon icon={['fas', 'arrow-up-short-wide']} />,
      background: 'var(--rep-primary-light)',
      border:     'var(--rep-primary)',
      color:      'var(--rep-primary)'
    },
    DESC: {
      value:      value,
      direction:  'DESC',
      icon:       <FontAwesomeIcon icon={['fas', 'arrow-down-short-wide']} />,
      background: 'var(--rep-primary-light)',
      border:     'var(--rep-primary)',
      color:      'var(--rep-primary)'
    },
  }

  const [selectedState, setSelectedState] = useState(active && direction ? states[direction] : states.NULL)
  const [timeoutId,     setTimeoutId]     = useState(null)

  const handleClick = () => {
    const directionOrder = ['NULL', 'ASC', 'DESC']
    const currentIndex   = directionOrder.findIndex(dir => dir === direction)

    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(setTimeout(() => {
        setSelectedState(states[directionOrder[(currentIndex + 1) % Object.keys(states).length]])
        !!click && click(states[directionOrder[(currentIndex + 1) % Object.keys(states).length]])
      }, 300))
    } else {
      setTimeoutId(setTimeout(() => {
        setSelectedState(states[directionOrder[(currentIndex + 1) % Object.keys(states).length]])
        !!click && click(states[directionOrder[(currentIndex + 1) % Object.keys(states).length]])
      }, 300))
    }
  }

  return (
    <Style.OrderTag
      marginY    = {marginY}
      color      = {selectedState.color}
      border     = {selectedState.border}
      background = {selectedState.background}
      onClick    = {handleClick}
    >
      {children}
      {!!selectedState.icon &&
        <Style.OrderTagIcon hasContent={!!children}>
          {selectedState.icon}
        </Style.OrderTagIcon>
      }
    </Style.OrderTag>
  )
}

export default OrderTag
