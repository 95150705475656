import React, { useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WithTooltip from '@components/WithTooltip'

import * as FormStyle   from '../FormStyles'
import * as Style from './style'

import TextareaProps from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  defaultValue,
  placeholder,
  focus,
  blur,
  change,
  click,
  maxHeight,
  marginY,
  tooltip,
  forwardRef = null,
  disabled   = false,
  required   = false
}) => {

  const { i18n } = useGlobalContextState() || { i18n: null }

  const [hasValue,        setHasValue]        = useState(!!defaultValue)
  const [focused,         setFocused]         = useState(false)
  const [controlledValue, setControlledValue] = useState('')

  const textareaRef = forwardRef || useRef(null)
  const uniqueId    = useMemo(() => window.btoa(Math.random().toString()).substring(10, 15), [])

  const handleChange = event => {
    const value = textareaRef.current.value
    value.length ? setHasValue(true) : setHasValue(false)
    setControlledValue(value)
    textareaRef.current.value = value

    Boolean(change) && change(event)
  }

  const handleFocus = event => {
    textareaRef.current.focus()
    setFocused(true)
    Boolean(focus) && focus(event)
  }

  const handleBlur = (event) => {
    setFocused(false)
    Boolean(blur) && blur(event)
  }

  return (
    <Style.TextareaContainer marginY={marginY}>
      {!!required &&
        <FormStyle.Required>
          {i18n.t('shared.required')}
        </FormStyle.Required>
      }
      {!!tooltip &&
        <FormStyle.Tooltip>
          <WithTooltip content={tooltip}>
            <FontAwesomeIcon icon="circle-question" />
          </WithTooltip>
        </FormStyle.Tooltip>
      }
      {!!label &&
      <Style.TextareaLabel
        htmlFor  = {`${name}_${uniqueId}`}
        active   = {hasValue}
        onClick  = {handleFocus}
        disabled = {disabled}
        required = {required}
      >
        {label}
      </Style.TextareaLabel>
      }
      <Style.Textarea
        id           = {`${name}_${uniqueId}`}
        name         = {name}
        disabled     = {disabled}
        ref          = {textareaRef}
        onKeyUp      = {handleChange}
        rows         = {/\n/.test(controlledValue) ? Number(controlledValue.match(/\n/g)?.length) + 1 : 2}
        defaultValue = {defaultValue}
        maxHeight    = {maxHeight}
        placeholder  = {placeholder}
        hasValue     = {hasValue}
        focused      = {focused}
        onBlur       = {handleBlur}
        onClick      = {click}
        required     = {required}
      />
      {disabled && <Style.LockIcon><FontAwesomeIcon icon="lock" /></Style.LockIcon>}
    </Style.TextareaContainer>
  )
}

export default Textarea
