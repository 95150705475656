import React, { useEffect, useRef, useState } from 'react'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CommandBar = () => {

  const {
    setCommandBarOptions,
    commandBarOptions,
    fetchApi,
    isDesktop
  } = useGlobalContextState()

  const [timeoutId, setTimeoutId]     = useState(null)
  const [results, setResults]         = useState([])
  const [listLoading, setListLoading] = useState(false)

  const overlayRef = useRef(null)
  const inputRef   = useRef(null)
  const closeRef   = useRef(null)
  // Should receive filter
  // fetchFN
  // Component to display results

  useEffect(() => {
    if (commandBarOptions && inputRef.current) inputRef.current.focus()
  }, [commandBarOptions])

  const closeCommandBar = event => {
    if (event.target === overlayRef.current) setCommandBarOptions({})
    if (event.currentTarget === closeRef.current) setCommandBarOptions({})
  }

  const showResults = (data) => {
    setResults(data)
    setListLoading(false)
  }

  const search = () => {
    setListLoading(true)
    // console.log(commandBarOptions.setSearchQuery)
    if (commandBarOptions.setSearchQuery) commandBarOptions.setSearchQuery(inputRef.current.value)
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(setTimeout(() => {
        fetchApi({
          url:      `${commandBarOptions.url}?q=${inputRef.current.value}`,
          callback: showResults
        })
      }, 300))
    } else {
      setTimeoutId(setTimeout(() => {
        fetchApi({
          url:      `${commandBarOptions.url}?q=${inputRef.current.value}`,
          callback: showResults
        })
      }, 300))
    }
  }

  if (!Object.prototype.hasOwnProperty.call(commandBarOptions, 'url')) return
  return (
    <Style.Overlay onClick={closeCommandBar} ref={overlayRef}>
      <Style.Box isDesktop={isDesktop} withResults={!!results.length || listLoading}>
        <Style.CloseIcon ref={closeRef} onClick={closeCommandBar}>
          <FontAwesomeIcon icon="times" />
        </Style.CloseIcon>
        <div>
          <Style.GlassIcon><FontAwesomeIcon icon="magnifying-glass" /></Style.GlassIcon>
          <Style.Input onChange={search} placeholder='Task name…' ref={inputRef}></Style.Input>
        </div>
      </Style.Box>
      <Style.ResultBox>
        {listLoading
          ? <Style.Loader><FontAwesomeIcon icon="spinner" spin /></Style.Loader>
          : results.map((object, key) => commandBarOptions.renderComponent(key, object))
        }
      </Style.ResultBox>
    </Style.Overlay>
  )
}

export default CommandBar
