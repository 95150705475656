import styled from 'styled-components'

export const SearchInput = styled.input`
  border:           1px solid transparent;
  border-radius:    4px;
  box-sizing:       border-box;
  font-size:        0.75rem;
  outline:          none;
  padding:          4px 8px;
  transition:       .3s ease;
  text-size-adjust: none;
`

export const SearchIcon = styled.div`
  border-radius: 4px;
  font-size:     0.75rem;
  padding:       4px 8px;
  padding-right: 0;
`

export const SearchBar = styled.div`
  align-items:   center;
  border:        1px solid ${props => props.focused ? 'var(--rep-primary)' : 'var(--rep-primary-light)'};
  border-radius: 4px;
  color:         var(--rep-neutral-primary);
  display:       flex;
  transition:    .3s ease;
  width:         ${props => props.isDesktop ? 'auto' : '100%'};
`
