// file = Html5QrcodePlugin.jsx
import React, { useEffect } from 'react'
import { Html5Qrcode } from 'html5-qrcode'

const qrcodeRegionId = 'html5qr-code-full-region'

// // Creates the configuration object for Html5QrcodeScanner.
// const createConfig = (props) => {
//   const config = {}
//   if (props.fps) {
//     config.fps = props.fps
//   }
//   if (props.qrbox) {
//     config.qrbox = props.qrbox
//   }
//   if (props.aspectRatio) {
//     config.aspectRatio = props.aspectRatio
//   }
//   if (props.disableFlip !== undefined) {
//     config.disableFlip = props.disableFlip
//   }
//   if (props.facingMode) {
//     config.facingMode = props.facingMode
//   }
//   console.log('config', config)
//   return config
// }

const Html5QrcodePlugin = (props => {
  console.log('Html5QrcodeSimple', props)

  const stopScanning = () => {
    props.setShowQrScanner(false)
  }

  useEffect(() => {
    window.html5Qrcode = new Html5Qrcode(qrcodeRegionId)
    html5Qrcode.start(
      { facingMode: 'environment' },
      {
        fps:   10,    // Optional, frame per seconds for qr code scanning
        qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
      }, props.qrCodeSuccessCallback, props.qrCodeErrorCallback)
    return () => {
      html5Qrcode.stop()
    }
  })
  return (

    <div id="scan_div" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999, width: '100vw', borderColor: 'black', backgroundColor: 'white' }} >
      <div id={qrcodeRegionId} />
      <div>
        <div className='btn btn-secondary' style={{ width: '100vw'}} onClick={() => { stopScanning()}}>Stop Scanning</div>
      </div>
    </div>
  )
})

export default Html5QrcodePlugin
