import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout            from '@components/Callout'
import DatePicker         from '@components/Form/DatePicker'
import FileUploader       from '@components/Form/FileUploader'
import Form               from '@components/Form/Form'
import Input              from '@components/Form/Input'
import Select             from '@components/Form/Select'
import Textarea           from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import { Map, Marker } from 'react-map-gl'

import { MEASURE_ACTIONS, measureReducer } from '@reducers/measureReducer'

import IMeasure         from '@interfaces/IMeasure.d'
import IRequestResponse from '@interfaces/IRequestResponse.d'

interface MeasureValueFormProps {
  measure:       IMeasure
  updateMethods: {
    measurment: (response: IRequestResponse) => void
  }
}

const MeasureValueForm: React.FC<MeasureValueFormProps> = ({
  measure,
  updateMethods
}) => {

  const {
    current_user,
    i18n,
    fetchApi,
    closeModal,
    CONSTANTS
  } = useGlobalContextState()

  const { USER, MEASURMENT } = CONSTANTS
  const { TYPES } = MEASURMENT

  const [users,        _setUsers]       = useState([current_user])
  const [documents,    setDocuments]    = useState([])
  const [location, setLocation]         = useState([])

  useEffect(() => {
    if (measure.type === TYPES.LOCATION && 'geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        setLocation([position.coords.latitude, position.coords.longitude])
      })
    }
  }, [])

  const updateMethodsWithDocs = {
    ...updateMethods,
    addDocument:    data => setDocuments(documents => [...documents, data]),
    removeDocument: data => setDocuments(documents => documents.filter(d => d.id !== data.id))
  }

  const [_state, dispatch] = useReducer(measureReducer, { fetchApi, updateMethods })

  const addFormData = formData => {
    formData.append('measurment_id', measure.id)
    formData.append('documents', documents.map(doc => doc.id).toString())
  }

  const createValue = (data, fieldset) => dispatch({
    type:      MEASURE_ACTIONS.ADD_VALUE,
    data,
    fieldset,
    callbacks: [closeModal]
  })

  const textForm = useMemo(() =>
    <Textarea
      name    = 'value'
      label   = {i18n.t('measurment.value')}
      marginY = 'M'
      required
    />, [])

  const numberForm = useMemo(() =>
    <Input
      type    = 'number'
      name    = 'value'
      label   = {i18n.t('measurment.value')}
      marginY = 'M'
      required
    />, [])

  const dragLocationMarker = e => setLocation([e.lngLat.lat, e.lngLat.lng])
  const locationForm = useMemo(() => <>
    {!!location.length &&
    <Map
      mapboxAccessToken = 'pk.eyJ1IjoiYmVydHJhbmRtb3JlbCIsImEiOiJjbGN1Z2o5a3cwbmk1M3dxdG91azJjZmZxIn0.mKd_D3yQCWmGnLC6TAvITw'
      initialViewState  = {{
        latitude:  location[0],
        longitude: location[1],
        zoom:      12
      }}
      style = {{
        borderRadius: '4px',
        top:          0,
        bottom:       0,
        width:        '100%',
        height:       '200px',
      }}
      mapStyle           = "mapbox://styles/mapbox/light-v11"
      attributionControl = {false}
    >
      <Marker
        latitude  = {location[0]}
        longitude = {location[1]}
        anchor    = "bottom"
        color     = 'var(--rep-primary)'
        offset    = {[0, 0]}
        draggable
        onDragEnd = {dragLocationMarker}
      />
    </Map>
    }
  </>, [location])

  return (
    <Form
      callback    = {createValue}
      addFormData = {addFormData}
    >
      <Callout
        type  = "primary"
        title = {measure.name}
        icon  = {<FontAwesomeIcon icon="info-circle" />}
      >
        <div>{measure.description}</div>
      </Callout>

      <Select
        name         = 'user'
        defaultValue = {users}
        label        = 'Relevé par'
        searchUrl    = '/users/search'
        filters      = {[
          {
            id:      'technicians',
            name:    i18n.t('user.role.technicians'),
            filters: { role: `${USER.ROLES.TECHNICIAN},${USER.ROLES.TECHNICIAN_MANAGER}` }
          },
          {
            id:      'managers',
            name:    i18n.t('user.role.managers'),
            filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
          },
          {
            id:      'all',
            name:    i18n.t('shared.all'),
            filters: {}
          }
        ]}
        defaultFilter    = 'technicians'
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        placeholder      = {i18n.t('user.users')}
        marginY          = 'M'
        search
        required
      />

      <DatePicker
        name = 'date'
        date = {new Date()}
        required
      />

      <Input
        type         = 'hidden'
        name         = 'value'
        defaultValue = {location.join(',')}
      />

      {measure.type === TYPES.NUMBER    && numberForm}
      {measure.type === TYPES.FREE_TEXT && textForm}
      {measure.type === TYPES.LOCATION  && locationForm}


      <Textarea
        name    = "comment"
        label   = {i18n.t('measurment.comment')}
        marginY = 'M'
      />

      <FileUploader
        name            = 'document'
        updateMethods   = {updateMethodsWithDocs}
        objectType      = 'measurment_value'
        // objectId        = {measure?.id}
        closeModalAfter = {false}
        destroyable     = {true}
        files           = {documents}
        multiple
      />
    </Form>
  )
}

export default MeasureValueForm
