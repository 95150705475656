
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button      from '@components/Button'
import WithTooltip from '@components/WithTooltip'

import * as FormStyle from '../FormStyles'
import * as Style     from './style'

import ISelect  from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

/**
 * @see Interface {@link ISelect}
 * @description Select component. Can be set with a set of Options or Aucomplete fetching API.
 */
const Select: React.FC<ISelect> = ({
  name,
  label,
  defaultValue      = [],
  options           = [],
  multiselect       = false,
  withEmpty         = false,
  cleanAfterSelect  = false,
  disabled          = false,
  placeholder,
  customOption,
  emptyCallback,
  callback,
  defaultFilter,
  format = { content: 'content', details: 'details', value: 'value'},
  required,
  detailsLocaleKey,
  marginY,
  tooltip,
  // API Search
  search            = false,
  searchUrl,
  filters,
}) => {

  const { i18n, fetchApi } = useGlobalContextState() || { i18n: null }

  /** Show/Hide the list options */
  const [showOptions,    setShowOptions]    = useState(false)
  /** Array of options */
  const [optionList,     setOptionList]     = useState(options)
  /** If fetching options, will display a loading status */
  const [listLoading,    setListLoading]    = useState(false)
  /** Selected options */
  const [selected,       setSelected]       = useState([])
  /** A11y */
  const [ariaSelected,   setAriaSelected]   = useState(0)
  /** [FETCH] Timeout between each fetch */
  const [timeoutId,      setTimeoutId]      = useState(null)
  /** [FETCH] Total options to display */
  const [total,          setTotal]          = useState(null)
  /** [FETCH] Current page of options */
  const [page,           setPage]           = useState(1)
  /** [FETCH] Selected filter to fetch */
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter || (filters?.length === 1) ? filters[0].id : null)

  const inputRef  = useRef(null)
  const selectRef = useRef(null)
  const OptionBox = useRef(null)

  const uniqueId     = useMemo(() => window.btoa(Math.random().toString()).substring(10,15), [])
  const optionsId    = useMemo(() => window.btoa(Math.random().toString()).substring(10,15), [])
  const CustomOption = customOption

  /** Initialize the Select according to the props */
  useEffect(() => {
    if (!defaultValue) setSelected([])
    if (search)        { setSelected(defaultValue.filter(Boolean).map(v => { return { content: v[format.content], value: v[format.value], details: v[format.details], object: v }})) }
    if (!search)       {
      setSelected(
        options?.filter(opt => new Set(defaultValue
          .filter(Boolean)
          .filter(e => e)
          .map(def => def[format.value])).has(opt[format.value]))
          .map(opt => { return { content: opt[format.content], value: opt[format.value], object: opt }}) || []
      )}
  }, [defaultValue])

  /** Build the query string if Filters are present */
  const filterString = useMemo(() => {
    if (!filters || !selectedFilter) return ''
    const queryParam = new URLSearchParams(filters.find(f => f.id === selectedFilter).filters).toString()
    return queryParam
  }, [selectedFilter, filters])

  /** Triggers the fetch to the API with filters if any */
  useEffect(() => {
    if (!showOptions) return
    selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    fetchResults()
  }, [filterString, showOptions])

  const fetchResults  = () => {
    if (!search || !searchUrl || !showOptions) return
    setListLoading(true)
    fetchApi({
      url:      `${searchUrl}.json?q=${inputRef?.current ? inputRef?.current?.value : ''}${selectedFilter ? `&${filterString}` : ''}`,
      callback: setOptionsFromApi
    })
  }

  /** Removes the selected option from the options list */
  useMemo(() => multiselect && setOptionList(opts => opts.filter(opt => !selected.map(s => s.value).includes(opt[format.value]))), [selected])

  /** @todo Replace with useOuterClick or React floating (better!) */
  useMemo(() => {
    const handleClickOutside = event => !!selectRef.current &&
                                        !selectRef.current.contains(event.target) &&
                                        setShowOptions(false)
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [])

  /** Will insert the parsed options in the option list after fetch */
  const injectOptionsFromApi = response => response.json().then(data => {
    if (data.response.results.length) {
      const parsedResults = data.response.results
        .filter(r => !selected.map(s => s.value).includes(r[format.value]))
      setOptionList([...optionList, ...parsedResults])
      setListLoading(false)
      setPage(page + 1)
    } else {
      setListLoading(false)
    }
  })

  /** Display/Hide option list */
  const toggleSelect = (e) => {
    if (disabled) return
    if (e.target == inputRef?.current) return
    if (e.target.classList.contains('not-close-select') || e.target.parentElement.classList.contains('not-close-select')) return
    !showOptions && filterOptionList([])
    setShowOptions(!showOptions)

    // if (showOptions) inputRef.current.focus()
  }

  /** Display/Hide option list when focus */
  const handleFocus = _e => !showOptions && setShowOptions(true)

  /** Filter options when writing in the Select */
  const handleChange = e => {
    setAriaSelected(0)
    setPage(1)
    filterOptionList({ content: e.currentTarget.value })
  }

  /** Accessibility */
  const handleKeyDown  = e => {
    if ((e.key === 'Tab') || e.key === 'Escape') {
      e.stopPropagation()
      setShowOptions(false)
      if (inputRef.current) inputRef.current.value = ''
    }

    if (e.key === 'ArrowRight' && filters?.length && ariaSelected === -1) {
      const filterIndex = filters.findIndex(filter => filter.id === selectedFilter)
      if (filterIndex < filters.length - 1) {
        setSelectedFilter(filters[filterIndex + 1].id)
        setAriaSelected(-1)
      }
    }

    if (e.key === 'ArrowLeft' && filters?.length && ariaSelected === -1) {
      const filterIndex = filters.findIndex(filter => filter.id === selectedFilter)
      if (filterIndex > 0) {
        setSelectedFilter(filters[filterIndex - 1].id)
        setAriaSelected(-1)
      }
    }

    if (e.key === 'ArrowDown') {
      if (!showOptions) setShowOptions(true)

      if (ariaSelected < optionList?.length - 1) {
        setAriaSelected(ariaSelected + 1)
        OptionBox.current?.children[ariaSelected + 1]?.scrollIntoView()
      }
    }

    if (e.key === 'ArrowUp' && (ariaSelected > 0 || filters?.length && ariaSelected >= 0)) {
      OptionBox.current?.children[ariaSelected - 1]?.scrollIntoView()
      setAriaSelected(ariaSelected - 1)
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      if (optionList && optionList[ariaSelected]) {
        addOption(optionList[ariaSelected])
        setAriaSelected(-1)
      }
    }
  }

  /** Will fetch next page when reaching the end of the optionList if API fetch */
  const handleScroll = e => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) <= 1
    if (bottom && search && searchUrl) {
      if (total && total <= (optionList.length + selected.length)) { return }
      setListLoading(true)
      if (timeoutId) {
        clearTimeout(timeoutId)
        setTimeoutId(setTimeout(() => {
          fetch(`${searchUrl}?q=${inputRef?.current ? inputRef?.current?.value : ''}${selectedFilter  ? `&${filterString}` : ''}&page=${page + 1}`)
            .then(injectOptionsFromApi)
        }, 300))
      } else {
        setTimeoutId(setTimeout(() => {
          fetch(`${searchUrl}?q=${inputRef?.current ? inputRef?.current?.value : ''}${selectedFilter ? `&${filterString}` : ''}&page=${page + 1}`)
            .then(injectOptionsFromApi)
        }, 300))
      }
    }
  }

  /** Insert options when fetching API */
  const setOptionsFromApi = data => {
    if (data.response.results.length) {
      const parsedResults = data.response.results
        .filter(r => !selected.map(s => s.value).includes(r[format.value]))
      setOptionList(parsedResults || [])
      setListLoading(false)
      setTotal(data.response.total)
    } else {
      setOptionList([])
      setListLoading(false)
      setTotal(0)
    }
  }
  /** Filter options from searchbar or trigger a new search */
  const filterOptionList = option => {
    setOptionList([])
    setShowOptions(true)
    setPage(1)
    if (search && searchUrl && inputRef?.current?.value?.length >= 3) {
      setTotal(null)
      setListLoading(true)
      if (timeoutId) {
        clearTimeout(timeoutId)
        setTimeoutId(setTimeout(() => {
          fetchApi({
            url:      `${searchUrl}?q=${inputRef?.current ? inputRef?.current?.value : ''}${selectedFilter ? `&${filterString}` : ''}`,
            callback: setOptionsFromApi
          })
        }, 300))
      } else {
        setTimeoutId(setTimeout(() => {
          fetchApi({
            url:      `${searchUrl}?q=${inputRef?.current ? inputRef?.current?.value : ''}${selectedFilter ? `&${filterString}` : ''}`,
            callback: setOptionsFromApi
          })
        }, 300))
      }

    } else {
      const filtered = options.filter(opt =>
        opt[format.content]
          ?.toString()
          ?.toLowerCase()
          ?.match(option.content?.toLowerCase())
      )
      const withoutSelected = filtered.filter(opt => !selected.includes(opt))
      setOptionList(withoutSelected)
    }
  }

  /** Delete an option from selected ones (on click on it) */
  const removeOption = option => {
    if (!multiselect) return
    const filtered = selected.filter(opt => opt.value !== option.value)
    setSelected(filtered)
    !!callback && callback(filtered)
  }

  /** Add option into selected ones */
  const addOption = option => {
    console.log('option', option)
    const formattedOption = { content: option[format.content], value: option[format.value], object: option }
    if (multiselect) {
      !!callback && callback([...selected, formattedOption])
      setSelected([...selected, formattedOption])
    } else {
      !!callback && callback(formattedOption)
      setShowOptions(false)
      setSelected([formattedOption])
    }

    // if (search || !multiselect) { setShowOptions(false) }
    if (cleanAfterSelect)       {
      setShowOptions(false)
      setSelected([])
    }

    if (inputRef.current) inputRef.current.value = ''
  }

  /** Function triggered when select the empty Option */
  const selectEmptyOption = () => {
    setSelected([])
    setShowOptions(false)
    emptyCallback && emptyCallback()
    if (inputRef.current) inputRef.current.value = ''
  }

  return (
    <Style.SelectContainer
      ref       = {selectRef}
      withLabel = {!!label}
      disabled  = {disabled}
      selected  = {!!selected.length}
      required  = {required}
      marginY   = {marginY}
    >
      <label htmlFor={`${name}_${uniqueId}`}>
        {label}
      </label>
      {required &&
        <FormStyle.Required>
          {i18n.t('shared.required')}
        </FormStyle.Required>
      }
      {!!tooltip &&
        <FormStyle.Tooltip>
          <WithTooltip content={tooltip}>
            <FontAwesomeIcon icon="circle-question" />
          </WithTooltip>
        </FormStyle.Tooltip>
      }
      {/* Visible input */}
      <Style.SelectedContainer
        disabled = {disabled}
        required = {required}
        opened   = {showOptions}
        selected = {!!selected.length}
        onClick  = {toggleSelect}
      >
        <Style.SelectedItems>
          {/* <div ref={selectedRef} style={{ display: 'flex', flexWrap: 'wrap'}}> */}
          {selected.map((selected, key) =>
            <Style.Selected key={key} onClick={() => removeOption(selected)} multiselect={multiselect} className={multiselect ? 'not-close-select' : ''}>
              {selected.content} {!!multiselect && <FontAwesomeIcon icon="times" />}
            </Style.Selected>
          )}
          {/* </div> */}
          {(showOptions && !!search) &&
            <Style.SelectInput
              // selectedWidth     = {selectedRef?.current?.offsetWidth}
              background        = {selected.length && showOptions}
              id                = {`${name}_${uniqueId}`}
              ref               = {inputRef}
              selected          = {!!selected.length}
              placeholder       = {selected.length ? '' : placeholder ? placeholder : i18n.t('actions.search')}
              onChange          = {handleChange}
              onKeyDown         = {handleKeyDown}
              onFocus           = {handleFocus}
              autocomplete      = "off"
              readOnly          = {disabled || (!search && !multiselect)}
              role              = "combobox"
              aria-autocomplete = "list"
              aria-haspopup     = "listbox"
              aria-expanded     = {`${showOptions}`}
              aria-controls     = {`options-${optionsId}`}
            />
          }
        </Style.SelectedItems>
        <Style.Caret opened={showOptions}>
          {/* <Button
              click={() => null}
              focusable = {false}
            > */}
          {disabled ? <FontAwesomeIcon icon="lock" /> : <Style.CaretIcon opened={showOptions}><FontAwesomeIcon icon="chevron-down" /></Style.CaretIcon>}
          {/* </Button> */}
        </Style.Caret>
      </Style.SelectedContainer>

      <input
        name     = {name}
        type     = "hidden"
        value    = {selected.map(v => v.value)}
        required = {required}
        tabIndex = {-1}
      />

      {/* Option list */}
      {showOptions &&
        <Style.SelectOptions
          id       = {`options-${optionsId}`}
          ref      = {OptionBox}
          onScroll = {handleScroll}
          tabIndex = {-1}
          role     = 'listbox'
        >
          {!!filters && filters.length > 1 &&
            <Style.FilterOptions selected={ariaSelected === -1}>
              {filters.map(filter =>
                <Button
                  key        = {filter.id}
                  click      = {() => setSelectedFilter(filter.id)}
                  background = 'white'
                  border     = {selectedFilter === filter.id ? 'var(--rep-primary)' : 'var(--rep-neutral-light)'}
                  color      = {selectedFilter === filter.id ? 'var(--rep-primary)' : 'var(--rep-neutral-primary)'}
                >
                  {filter.name}
                </Button>
              )}
            </Style.FilterOptions>
          }

          {withEmpty &&
            <Style.SelectOption
              onClick = {selectEmptyOption}
              none    = {true}
            >
              {i18n.t('shared.none')}
            </Style.SelectOption>
          }

          {optionList?.map((opt, key) => {
            return customOption
              ? <CustomOption
                key          = {key}
                click        = {() => addOption(opt)}
                selected     = {selected.includes(opt)}
                ariaSelected = {ariaSelected === key}
                option       = {opt}
              />
              : <Style.SelectOption
                key          = {key}
                ariaSelected = {ariaSelected === key}
                selected     = {selected.includes(opt)}
                onClick      = {() => addOption(opt)}
              >
                {opt[format.content]} <span>{detailsLocaleKey ? i18n.t(`${detailsLocaleKey}.${opt[format.details]}`) : opt[format.details]}</span>
              </Style.SelectOption>
          }
          )}
          {!!search && !!searchUrl &&
          <>
            {inputRef?.current?.value && inputRef?.current?.value.length < 3 &&
              <Style.SearchHint>{i18n.t('shared.three_minimum_characters')}</Style.SearchHint>
            }
            <Style.OptionTotal>
              {listLoading
                ? optionList ? <FontAwesomeIcon icon="spinner" spin /> : i18n.t('shared.no_result')
                : total
                  ? `Results ${optionList?.length || 0} of ${total - selected.length}`
                  : `${optionList?.length || 0} results`
              }
            </Style.OptionTotal>
          </>
          }
        </Style.SelectOptions>
      }
    </Style.SelectContainer>
  )
}

export default Select
