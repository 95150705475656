import React from 'react'

import Form           from '@components/Form/Form'
import Input          from '@form/Input'
import Select         from '@components/Form/Select'
import SelectDoerForm from '@components/User/SelectDoerForm'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import IAmenity           from '@interfaces/IAmenity.d'
import IFavouriteProvider from '@interfaces/IFavouriteProvider.d'
import IUnit              from '@interfaces/IUnit.d'

interface FavouriteProviderForm {
  favourite?: IFavouriteProvider
  equipment:  IUnit | IAmenity,
  callback:   (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const FavouriteProviderForm: React.FC<FavouriteProviderForm> = ({
  equipment,
  favourite,
  callback
}) => {

  const { i18n } = useGlobalContextState()

  return (
    <Form callback = {(data, fieldset) => callback(data, fieldset)}>
      <FormStyle.Label>
        {i18n.t('user.users')}
      </FormStyle.Label>

      <input type="hidden" name="unit_id" value={equipment.id} />
      {favourite && <input type="hidden" name="favourite_provider_id" value={favourite.id} />}

      <SelectDoerForm
        defaultUsers        = {favourite ? [favourite.favourite_user] : []}
        defaultProvider     = {favourite ? favourite.service_provider : null}
        multiselect         = {false}
        teams               = {false}
        showPeriodSelection = {false}
        showNotify          = {false}
      />

      <Select
        label         = {i18n.t('expertise.expertise')}
        name          = 'expertise'
        search        = {true}
        searchUrl     = '/expertises/search'
        withEmpty     = {true}
        defaultValue  = {favourite ? [favourite.expertise] : []}
        format        = {{ content: 'name', value: 'id' }}
        marginY       = 'M'
        required
      />
    </Form>
  )
}

export default FavouriteProviderForm
