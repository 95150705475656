import React, { useEffect, useReducer, useState } from 'react'

import * as Style from './OngoingTaskStyle'
import * as TaskStyle from './style'

import { FontAwesomeIcon }           from '@fortawesome/react-fontawesome'

import { TASK_ACTIONS, taskReducer } from '@reducers/index'

import { OngoingTaskProps } from './types.d'

import { useGlobalContextState }     from '@context/GlobalContext'
import { useTaskContextState }       from '@context/TaskContext'

import { convertMsToMinutesSeconds } from '@utils/time'

const OngoingTask: React.FC<OngoingTaskProps> = ({
  ongoingTask,
  setOngoingTask,
  setView,
  setData
}) => {

  const {
    i18n,
    fetchApi,
    isDesktop,
  } = useGlobalContextState()

  const { updateMethods } = useTaskContextState()

  const [_state, dispatch] = useReducer(taskReducer,   { task: ongoingTask, updateMethods, fetchApi, setAnimation: () => null })

  const stopTask = () => dispatch({
    type:      TASK_ACTIONS.STOP,
    task:      ongoingTask,
    animation: 'loading',
    params:    { report: true, costs: true },
    callbacks: [
      () => setOngoingTask(null),
      data => {
        setData({ planned: [data.response], expected: [] })
        setView('current')
      }
    ]})

  const [timer, setTimer] = useState(Date.now() - new Date(ongoingTask.action_started_at).getTime())

  useEffect(() => {
    const interval = setInterval(() => { setTimer(_timer => Date.now() - new Date(ongoingTask.action_started_at).getTime()) }, 1000)
    return () => clearInterval(interval)
  }, [])

  const scrollToTask = () => {
    setData({ planned: [ongoingTask], expected: [] })
  }

  if (!ongoingTask) return

  return (
    <Style.OngoingTaskStyle isDesktop={isDesktop}>
      <Style.OngoingInformation>
        <TaskStyle.TaskStatus status='done' justify='flex-start'>
          {i18n.t('todo.ongoing_task')}
        </TaskStyle.TaskStatus>
        <TaskStyle.TaskTitle onClick={scrollToTask}>
          {ongoingTask.title}
        </TaskStyle.TaskTitle>
      </Style.OngoingInformation>

      <Style.OngoingAction>
        <TaskStyle.OngoingTimer>
          <FontAwesomeIcon icon={['far',  'circle']} fade />
          {convertMsToMinutesSeconds(timer)}
        </TaskStyle.OngoingTimer>
        <TaskStyle.StartStop
          onClick = {stopTask}
          status  = 'stop'
        >
          <FontAwesomeIcon icon="stop" />
          <span>stop</span>
        </TaskStyle.StartStop>
      </Style.OngoingAction>
    </Style.OngoingTaskStyle>
  )
}

export default OngoingTask
