import React, { useEffect, useRef, useState }  from 'react'
import { FontAwesomeIcon }       from '@fortawesome/react-fontawesome'

import Button from '../Button'

import * as Style from './style'

import DropdownProps, { DropdownOptionProps } from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react'

/**
 * @see Interface {@link IDropdown}
 * @description Dropdown menu. On click, will display a floating menu on the best place where it can fit on the window.
 * @example
 * <Dropdown options=[{ content: 'test', click: () => console.log('Dropdown clicked !')}]>
 *  This is a Dropdown
 * </Dropdown>
 */
const Dropdown: React.FC<DropdownProps> = ({
  options         = [],
  withArrow       = false,
  icon,
  color,
  background,
  border            = 'transparent',
  hover             = 'transparent',
  alignSelf,
  hideIfNoActions   = true,
  forwardRef        = null,
  forwardOptionsRef = null,
  children,
}) => {

  const { isDesktop, i18n } = useGlobalContextState() || { isDesktop: true }

  const [displayed, toggle]               = useState(false)
  const [ariaSelected, setAriaSelected]   = useState(null)
  const [parsedOptions, setParsedOptions] = useState(options)

  const dropdownRef = forwardRef || useRef(null)
  const optionsRef  = forwardOptionsRef || useRef(null)

  useEffect(() => {
    if (!isDesktop) {
      setParsedOptions([...options, {
        content: i18n.t('actions.close'),
        click:   () => toggle(false)
      }])
    } else {
      setParsedOptions(options)
    }
  }, [isDesktop, options])


  const listRef  = useRef([])
  const arrowRef = useRef(null)

  const { x, y, refs, strategy, context } = useFloating({
    open:                 displayed,
    onOpenChange:         toggle,
    placement:            'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware:           [
      offset(10),
      flip({ fallbackAxisSideDirection: 'start' }),
      shift(),
      arrow({ element: arrowRef }),
    ]
  })

  const click          = useClick(context)
  const dismiss        = useDismiss(context)
  const role           = useRole(context, { role: 'menu'})
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex: ariaSelected,
    onNavigate:  setAriaSelected,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    dismiss,
    role,
    listNavigation
  ])

  const headingId = useId()

  const clickAction = (event, callback) => {
    toggle(false)
    callback(event)
  }

  if (!options.length && hideIfNoActions) return
  return (
    <Style.Dropdown ref={dropdownRef} alignSelf={alignSelf}>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Button
          color      = {color}
          background = {background}
          hover      = {hover}
          border     = {border}
          click      = {() => null}
        >
          <Style.DropDownIcon withMargin={!!children}>
            {Boolean(icon) && icon}
          </Style.DropDownIcon>
          {children}
          {!!options.length && !!withArrow &&
            <div style={{ transform: displayed ? 'rotate(-90deg)' : 'rotate(0deg)'}}>
              <FontAwesomeIcon icon='caret-down' />
            </div>
          }
        </Button>
      </div>
      {/* {!isDesktop && displayed && <Style.Overlay />} */}
      {displayed && !!options.length &&
        <FloatingPortal>
          {isDesktop
            ? <Options
              context           = {context}
              refs              = {refs}
              isDesktop         = {isDesktop}
              headingId         = {headingId}
              strategy          = {strategy}
              y                 = {y}
              x                 = {x}
              getFloatingProps  = {getFloatingProps}
              options           = {options}
              ariaSelected      = {ariaSelected}
              arrowRef          = {arrowRef}
              clickAction       = {clickAction}
              parsedOptions     = {parsedOptions}
              listRef           = {listRef}
              getItemProps      = {getItemProps}
              forwardRef        = {optionsRef}
            />
            : <FloatingOverlay lockScroll style={{ display: isDesktop ? 'none' : 'block', background: 'rgba(100, 120, 141, 0.4)', zIndex: 2500}}>
              <Options
                context           = {context}
                refs              = {refs}
                isDesktop         = {isDesktop}
                headingId         = {headingId}
                strategy          = {strategy}
                y                 = {y}
                x                 = {x}
                getFloatingProps  = {getFloatingProps}
                options           = {options}
                ariaSelected      = {ariaSelected}
                arrowRef          = {arrowRef}
                clickAction       = {clickAction}
                parsedOptions     = {parsedOptions}
                listRef           = {listRef}
                getItemProps      = {getItemProps}
                forwardRef        = {optionsRef}
              />
            </FloatingOverlay>
          }
        </FloatingPortal>
      }
    </Style.Dropdown>
  )
}

const Options = ({
  context,
  refs,
  isDesktop,
  headingId,
  strategy,
  y,
  x,
  getFloatingProps,
  options,
  ariaSelected,
  arrowRef,
  clickAction,
  parsedOptions,
  listRef,
  getItemProps,
  forwardRef
}) => {

  const optionsRef = forwardRef || useRef(null)

  return(
    <FloatingFocusManager context={context}>
      <Style.Options
        ref             = {refs.setFloating}
        isDesktop       = {isDesktop}
        aria-labelledby = {headingId}
        style           = {{
          position: isDesktop ? strategy : 'fixed',
          top:      isDesktop ? (y ?? 0) : 'unset',
          left:     isDesktop ? (x ?? 0) : 'unset',
          bottom:   isDesktop ? 'unset'  : 0,
          zIndex:   3500
        }}
        {...getFloatingProps({
          onKeyDown: e => {
            if (e.key === 'Escape' && context.open) {
              e.stopPropagation()
              context.onOpenChange(false)
            }

            if (context.open && ['Enter', 'Space'].includes(e.code)) {
              e.stopPropagation()
              context.onOpenChange(false)
              options[ariaSelected]?.click()
            }
          }
        })}
      >
        <FloatingArrow
          ref       = {arrowRef}
          context   = {context}
          tipRadius = {2}
          fill      = "white"
          className = "arrow"
        />
        <div ref={optionsRef}>
          {parsedOptions.map((opt: DropdownOptionProps, key) =>
            (!opt.content && !!opt.separator)
              ? !!isDesktop && <hr key={key} />
              : <div
                key     = {key}
                onClick = {e => clickAction(e, opt.click)}
              >
                <Style.Option
                  role       = 'menuitem'
                  isDesktop  = {isDesktop}
                  hasIcon    = {!!opt.icon}
                  key        = {key}
                  background = {opt.background}
                  color      = {opt.color}
                  tabIndex   = {ariaSelected === key ? 0 : -1}
                  ref        = {node => listRef.current[key] = node }
                  {...getItemProps()}
                >
                  {!!opt.icon && <Style.OptionIcon>{opt.icon}</Style.OptionIcon>}
                  {opt.content}
                </Style.Option>
                {!!opt.separator && key !== 0 && <hr />}
              </div>
          )}
        </div>
      </Style.Options>
    </FloatingFocusManager>
  )
}

export default Dropdown
