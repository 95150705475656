import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button      from '../Button'

import ColumnProps from './types'

import * as Style from './style'

import { useGlobalContextState } from '../../context/GlobalContext'

const Column: React.FC<ColumnProps> = ({
  togglable = false,
  open      = true,
  title,
  totalCount,
  totalFiltered,
  totalPages,
  currentCount,
  height = '100%',
  maxHeight,
  grow,
  overflow = 'scroll',
  paginated,
  headerActions,
  withFilters = false,
  filters,
  showEmptyContent,
  emptyContent,
  showEmptyFilters,
  emptyFilters,
  children,
  footer,
  loading,
  setLoading,
  marginBottom,
}) => {

  const { i18n, isDesktop } = useGlobalContextState()

  const [toggled,     toggle]         = useState(open)
  const [showFilters, setShowFilters] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [timeoutId,   setTimeoutId]   = useState(null)

  const columnContent = useRef(null)

  useEffect(() => filters.setColumnContent(columnContent.current), [])

  const toggleFilters = () => {
    if (!toggled) toggle(true)
    setShowFilters(showFilters => !showFilters)
  }

  const handleScroll = e => {
    if (!paginated) return

    const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1
    if (bottom) {
      if (timeoutId) {
        clearTimeout(timeoutId)
        filters.triggerPageFetch({ setLoading: setPageLoading })
      } else {
        setTimeoutId(setTimeout(() => {
          filters.triggerPageFetch({ setLoading: setPageLoading })
        }, 500))
      }
    }
  }

  const toggleColumn = () => {
    if (togglable) {
      toggle(toggled => !toggled)
      columnContent.current.scrollTop = 0
    }
  }

  return(
    <Style.Column
      height       = {toggled ? height : 'unset'}
      maxHeight    = {maxHeight}
      grow         = {grow}
      overflow     = {overflow}
      marginBottom = {marginBottom}
    >
      <Style.StickyHeader>
        <Style.ColumnHeader toggled={toggled}>
          <Style.ColumnTitle onClick={toggleColumn}>
            {!!togglable &&
              <Style.TogglableIcon toggled={toggled}>
                <FontAwesomeIcon icon="angle-right" />
              </Style.TogglableIcon>
            }
            {title}<sup>{totalCount}</sup>
          </Style.ColumnTitle>
          <Style.ColumnHeaderBtn>
            {withFilters &&
              <Style.FilterButton>
                {
                  filters.hasAnySelected()
                    ? <Button
                      icon       = {<FontAwesomeIcon icon={`arrow-${showFilters ? 'up' : 'down'}-wide-short`} />}
                      click      = {toggleFilters}
                      background = 'var(--rep-primary)'
                      color      = 'white'
                    >
                      {i18n.t('actions.filter')}
                    </Button>
                    : <Button
                      icon       = {<FontAwesomeIcon icon={`arrow-${showFilters ? 'up' : 'down'}-wide-short`} />}
                      click      = {toggleFilters}
                      background = 'transparent'
                      color      = 'var(--rep-primary)'
                    >
                      {i18n.t('actions.filter')}
                    </Button>
                }
              </Style.FilterButton>
            }
            {headerActions}
          </Style.ColumnHeaderBtn>
        </Style.ColumnHeader>
      </Style.StickyHeader>
      {showFilters && toggled &&
        <Style.FilterList toggled={toggled}>
          {filters.content.map(filter =>
            filters.isIncluded(filter.id, filter.reference)
              ? <Button
                key        = {filter.id}
                icon       = {filter.icon}
                click      = {() => filters.select(filter.id, filter.reference, setLoading)}
                border     = 'var(--rep-primary-light)'
                color      = 'var(--rep-primary)'
                background = 'var(--rep-primary-light)'
              >
                {filter.name}
              </Button>
              : <Button
                key        = {filter.id}
                icon       = {filter.icon}
                click      = {() => filters.select(filter.id, filter.reference, setLoading)}
                border     = 'var(--rep-neutral-primary-light)'
                color      = 'var(--rep-neutral-primary)'
                background = 'white'
              >
                {filter.name}
              </Button>
          )}
        </Style.FilterList>
      }

      <Style.ColumnContent
        ref       = {columnContent}
        isDesktop = {isDesktop}
        toggled   = {toggled}
        overflow  = {overflow}
        onScroll  = {handleScroll}
      >
        {showEmptyContent && !loading && emptyContent && emptyContent}

        {showEmptyFilters && !loading && emptyFilters && emptyFilters}

        {!loading && children}
        {loading &&
          <Style.ColumnLoader><FontAwesomeIcon icon="spinner" spin /></Style.ColumnLoader>
        }

        <div style={{ display: 'flex', justifyContent: 'center'}}>
          {!loading && filters.page < totalPages &&
            <Button
              click  = {() => filters.triggerPageFetch({ setLoading: setPageLoading })}
              border = 'var(--rep-primary)'
            >
              {i18n.t('shared.more')}
            </Button>
          }
        </div>
        {pageLoading &&
          <Style.ColumnLoader><FontAwesomeIcon icon="spinner" spin /></Style.ColumnLoader>
        }
        {!loading && (!!footer || !!(paginated && currentCount)) &&
          <Style.ColumnFooter>
            {paginated &&
              <Style.FooterCount>{i18n.t('shared.displayed_on_total_pages', { current: currentCount, total: totalFiltered || totalCount })}</Style.FooterCount>
            }
            {footer && footer}
          </Style.ColumnFooter>
        }
      </Style.ColumnContent>
    </Style.Column>
  )
}

export default Column
