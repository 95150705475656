import styled from 'styled-components'

export const Card = styled.div`
  /* border-top: 1px solid var(--rep-neutral-primary-light); */
  padding: 8px 4px;
  /* border-radius: 5px; */
  margin: 16px 0;
`

export const Header = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`

export const Title = styled.div`
  font-weight: 500;
  color: var(--rep-neutral-primary);
`

export const Description = styled.div`
  white-space: pre-line;
  max-height:  100px;
  overflow:    scroll;
  color: var(--rep-neutral-primary);
`

export const LastNext = styled.div`
  display: grid;
  grid-template-columns: 120px 120px;
  gap: 4px;
`
