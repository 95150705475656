import React, { useState } from 'react'

import * as Style from './style'
import Input from '@components/Form/Input'

const Toggle = ({
  initialOption,
  options,
  color = '--rep-success',
  callback
}) => {

  const [position, setPosition] = useState(initialOption)

  const togglePosition = () => {
    callback && callback(options[position === 'right' ? 'left' : 'right'].id)
    setPosition(initPosition => initPosition === 'right' ? 'left' : 'right')
  }

  return(
    <Style.Container>
      <Style.Label for="plan_form_mode" color={position === 'left' ? color : '--rep-neutral-primary-middle'} onClick={togglePosition}>{options.left.content}</Style.Label>
      <Input type="hidden" id="plan_form_mode" name="plan_form_mode" value={options[position].id} />
      <Style.Toggle onClick={togglePosition}>
        <Style.BowlContainer color={color}>
          <Style.Bowl position={position} color={color}>
            {options[position].icon}
          </Style.Bowl>
        </Style.BowlContainer>
      </Style.Toggle>
      <Style.Label for="plan_form_mode" color={position === 'right' ? color : '--rep-neutral-primary-middle'} onClick={togglePosition}>{options.right.content}</Style.Label>
    </Style.Container>
  )
}

export default Toggle
