import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Callout    from '@components/Callout'
import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

interface ExpectedDateFormProps {
  callback: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const ExpectedDateForm: React.FC<ExpectedDateFormProps> = ({
  callback
}) => {

  const { i18n } = useGlobalContextState()

  return (
    <Form
      callback         = {callback}
      submitText       = {i18n.t('report.actions.validate_expected_date')}
      submitBackground = 'var(--rep-success-light)'
      submitColor      = 'var(--rep-success)'
    >
      <Callout
        type    = "warning"
        icon    = {<FontAwesomeIcon icon="hand" />}
        marginY = 'M'
      >
        {i18n.t('report.page.required_expected_date')}
      </Callout>

      <FormStyle.Header><FontAwesomeIcon icon="calendar-check" />{i18n.t('report.expected_date')}</FormStyle.Header>
      <DatePicker
        name    = 'expected_date'
        date    = {new Date()}
      />
    </Form>
  )
}

export default ExpectedDateForm
