import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGlobalContextState } from '@context/GlobalContext'

import Input  from '@components/Form/Input'
import Tag    from '@components/Tag'

import * as Style     from './style'

const InfoForm = ({ form }) => {

  const { i18n } = useGlobalContextState()

  return(
    <div>
      <Style.Header>{form.name}</Style.Header>

      {form.questions.map((question, key) =>
        <Style.QuestionContainer key={key}>
          {console.log(question)}
          <Style.QuestionName>
            <FontAwesomeIcon icon="list" />
            {question.name}
          </Style.QuestionName>
          <div style={{ display: 'flex', gap: '8px' }}>
            {!!question.neg_reply &&
              <Tag
                icon           = {<FontAwesomeIcon icon="circle-exclamation" />}
                color          = {question.neg_reply ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                border         = {question.neg_reply ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                tooltipTitle   = {'Hint'}
                tooltipContent = {question.neg_reply}
              />
            }
            <Tag
              icon           = {<FontAwesomeIcon icon="comment-dots" />}
              color          = {question.show_comment ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
              border         = {question.show_comment ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
              tooltipContent = {question.show_comment ? i18n.t('form.accept_comment') : i18n.t('form.reject_comment')}
            />
            <Tag
              icon           = {<FontAwesomeIcon icon="eye-slash" />}
              color          = {question.allow_not_applicable ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
              border         = {question.allow_not_applicable ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
              tooltipContent = {question.allow_not_applicable ? i18n.t('form.accept_na') : i18n.t('form.reject_na')}
            />
            {/* <Button
              icon   = {<FontAwesomeIcon icon="trash" />}
              border = 'var(--rep-danger)'
              color  = 'var(--rep-danger)'
              click  = {() => removeQuestion(key)}
            /> */}
          </div>
          <Input
            name         = {`question-${key}`}
            type         = "hidden"
            defaultValue = {JSON.stringify(question)}
          />
        </Style.QuestionContainer>
      )}

    </div>
  )
}

export default InfoForm
