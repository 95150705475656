import React, { useMemo } from 'react'

import WithTooltip from '../WithTooltip'

import * as Style   from './style'
import IAvatar from './types.d'

/**
 * @description Function to parse firstName and lastName props
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string} A string of the capitalized initials of the user
 */
const parseName = (firstName, lastName) => `${firstName[0]?.toUpperCase() || ''}${lastName[0]?.toUpperCase() || ''}`

/**
 * @description Displays an Avatar for the user
 * @see Interface {@link IAvatar}
 * @todo Add support for user profile picture.
 * @example
 * <Avatar firstName="Bertrand" lastName="Morel" />
*/
const Avatar: React.FC<IAvatar> = ({
  firstName = '',
  lastName  = '',
  title,
  image,
  fullHeight,
  background = 'var(--rep-primary-light)',
  color      = 'var(--rep-primary)',
  border,
  type = 'circle', // 'left' | 'right' | 'circle
  overlapping = false,
  tooltipContent,
}) => {

  const initials = useMemo(() => parseName(firstName, lastName), [firstName, lastName])

  return (
    <Style.OverlappedStyle overlapping={overlapping}>
      <WithTooltip
        title   = {title}
        content = {tooltipContent ? tooltipContent : `${firstName} ${lastName}`}
        style   = {{ height: fullHeight ? '100%' : '' }}
      >
        <Style.Avatar
          background  = {background}
          color       = {color}
          border      = {border}
          type        = {type}
          height      = {fullHeight ? '100%' : null }
        >
          {initials}
        </Style.Avatar>
      </WithTooltip>
    </Style.OverlappedStyle>
  )
}

export default Avatar
