import React, { useMemo } from 'react'

import WithTooltip from '@components/WithTooltip'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'
import Button from '@components/Button'
import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'
import TaskBox from '@components/Task/TaskBox'

const MaintenancePlanCard: React.FC<{ plan: IMaintenancePlan }> = ({ plan }) => {

  const { i18n } = useGlobalContextState()

  return(
    <Style.Card>
      <Style.Header>
        {plan.archived
          ? <WithTooltip content={i18n.t('plan.plan_archived')}>
            <FontAwesomeIcon icon="folder" color="var(--rep-warning)" style={{ marginRight: '4px' }}/>
          </WithTooltip>
          : null
        }
        {plan.legal
          ?   <WithTooltip content={i18n.t('plan.legal_control')}>
            <FontAwesomeIcon icon="medal" color="var(--rep-warning)"/>
          </WithTooltip>
          : null
        }

        <Style.Title>{plan.name}</Style.Title>
      </Style.Header>
      <div>{plan.description}</div>
      <div>{plan.sentence}</div>
      <div>{plan.tasks.map(task => <TaskBox key={task.id} task={task} />)}</div>
    </Style.Card>
  )
}

export default MaintenancePlanCard
