import React, { useMemo } from 'react'

import MeasureLocation from './MeasureValue/MeasureLocation'
import MeasureNumber   from './MeasureValue/MeasureNumber'
import MeasureText     from './MeasureValue/MeasureText'

import { useGlobalContextState } from '@context/GlobalContext'

const Measure = ({ measure, updateMethods }) => {

  const { CONSTANTS }  = useGlobalContextState()
  const { MEASURMENT } = CONSTANTS

  const block = useMemo(() => {
    switch(measure.type) {
      case MEASURMENT.TYPES.NUMBER:    return <MeasureNumber   measure={measure} updateMethods={updateMethods} />
      case MEASURMENT.TYPES.FREE_TEXT: return <MeasureText     measure={measure} updateMethods={updateMethods} />
      case MEASURMENT.TYPES.LOCATION:  return <MeasureLocation measure={measure} updateMethods={updateMethods} />
    }
  }, [measure])

  return(block)
}

export default Measure
