import styled from 'styled-components'

export const Header = styled.div`
  font-size:      .7rem;
  font-weight:    500;
  color:          var(--rep-primary);
  margin:         0;
  background:     var(--rep-primary-light);
  user-select:    none;
  border-radius:  5px;
  padding:        4px 8px;
  text-transform: uppercase;
  display:        inline-block;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'var(--rep-spacer-s)'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
  svg { margin-right: 4px }
`

export const Group = styled.div`
  display:         flex;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  align-items:     stretch;
  width:           100%;
  gap:             8px;
  position:        relative;
  margin:          8px 0;
  flex-wrap:       wrap;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'var(--rep-spacer-s)'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
  & > label {
    position:      absolute;
    top:           -4px;
    left:          12px;
    z-index:       200;
    color:         var(--rep-neutral);
    font-size:     .7rem;
  }
`

export const Required = styled.div`
  display:          flex;
  justify-content:  flex-end;
  color:            var(--rep-warning);
  text-transform:   uppercase;
  font-size:        8px;
  position:         absolute;
  right:            28px;
  top:              ${props => props.middle ? 0 : '-8px'};
  background:       var(--rep-warning-light);
  padding:          2px 4px;
  border-radius:    4px;
  z-index:          200;
  margin:           ${props => props.margin ? props.margin : 0};
`

export const Tooltip = styled.div`
  display:          flex;
  justify-content:  flex-end;
  color:            var(--rep-neutral);
  text-transform:   uppercase;
  font-size:        1em;
  position:         absolute;
  right:            4px;
  top:              ${props => props.middle ? 0 : '-1em'};
  background:       var(--rep-neutral-light);
  padding:          2px 4px;
  border-radius:    16px;
  z-index:          200;
  margin:           ${props => props.margin ? props.margin : 0};
  transition: .2s ease;
  &:hover {
    cursor:    pointer;
  }
`

export const UnitArrow = styled.div`
  color:           var(--rep-primary);
  display:         flex;
  justify-content: center;
`

export const Label = styled.label`
  display:        block;
  color:          var(--rep-neutral-primary);
  text-transform: uppercase;
  font-size:      .7rem;
  padding-bottom: 4px;
  border-bottom:  .5px dashed var(--rep-neutral-primary);
  margin-left:    4px;
  margin-top:     ${props => props.noMarginTop ? 0 : '16px'};
`

export const DatePickerForm = styled.div`
  display:     flex;
  align-items: center;
  gap:         8px;
  flex-wrap:   wrap;
`

export const TextBlock = styled.div`
  position:      relative;
  border:        1px solid ${props => props.border ? props.border : 'var(--rep-neutral-primary-light)'};
  border-radius: 5px;
  padding:       4px 8px;
  align-self:    stretch;
  display:       flex;
  align-items:   center;
  color:         var(--rep-neutral-primary);
  width:         ${props => props.fullWidth ? '100%' : 'fit-content'};
  font-weight:   500;
  margin-top:    ${props => {
    switch (props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
  margin-bottom: ${props => {
    switch (props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
`

export const BorderedBox = styled.div`
  border:        1px solid var(--rep-primary-middle);
  padding:       8px;
  border-radius: 5px;
  margin:        8px 0;
`
