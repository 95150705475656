import React, { useMemo } from 'react'

import * as Style   from './style'

import MessageProps from './types.d'

import Avatar    from '@components/Avatar'
import Thumbnail from '@components/Form/FileUploader/Thumbnail'

import { useGlobalContextState } from '@context/GlobalContext'


const parseDate = dateString => {
  const date  = new Date(dateString)
  const today = new Date
  let isToday
  if (
    today.getFullYear() === date.getFullYear()  &&
    today.getMonth()    === date.getMonth()     &&
    today.getDate()     === date.getDate()
  ) {
    isToday = true
  }

  if (isToday) return `${date.toTimeString().slice(0, 2)}:${date.toTimeString().slice(3,5)}`

  return date.toLocaleString('fr', { dateStyle: 'short', timeStyle: 'short' })

}
/**
 * @description Renders a message of the Chat
 * @example
 * <Message
 *  message      = {message}
 *  current_user = {current_user}
 * />
 */
const Message: React.FC<MessageProps> = ({
  message,
  current_user
}) => {
  const { i18n } = useGlobalContextState()

  const date = useMemo(() => parseDate(message.sent), [])

  const isOwnMessage = useMemo(() => message.sender_id === current_user.id, [message])

  return (
    <Style.Message self={isOwnMessage}>
      <Style.MessageInfo self={isOwnMessage}>
        <Style.UserName self={isOwnMessage}>
          {isOwnMessage ? i18n.t('conversation.you') : message.username}
        </Style.UserName>

        <Style.Date self={isOwnMessage}>
          {Boolean(message.company) && `[${message.company}]`} {date}
        </Style.Date>
      </Style.MessageInfo>

      <Style.ContentBlock self={isOwnMessage}>
        <Style.Content    self={isOwnMessage}>
          {message.content}
          {!!message.documents.length &&
            message.documents.map(doc =>
              <Thumbnail
                key      = {doc.id}
                file     = {doc}
                color    = 'white'
                wrapText = {false}
              />
            )
          }
        </Style.Content>
        <Avatar
          firstName  = {message.username?.split(' ')[0]}
          lastName   = {message.username?.split(' ')[1]}
          background = {isOwnMessage ? 'var(--rep-primary-light)' : 'var(--rep-neutral-light)'}
          color      = {isOwnMessage ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        />
      </Style.ContentBlock>
    </Style.Message>
  )
}

export default Message
