import styled, { css } from 'styled-components'

export const FileListStyles = styled.div`
  display:               grid;
  border:                1px solid var(--rep-neutral-primary-light);
  grid-template-columns: repeat(2, minmax(0,1fr));
  grid-gap:              8px;
  background:          white;
  padding:               8px;
  border-radius:         4px;
  grid-auto-rows:        calc(100px + 1.5rem);
  margin-bottom:         8px;
  flex-grow: 1;
  overflow: auto;
  scrollbar-color: var(--rep-neutral-middle) white;
`

export const EmptyColumn = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  flex-direction:  column;
  color:           var(--rep-neutral-primary);
  p { font-size: 3em; }
`

export const Column = styled.div`
  max-height:     100%;
  height:         100%;
  display:        flex;
  flex-direction: column;
  grid-col:       ${props => props.gridCol ? props.gridCol : 'unset'};
  grid-row:       ${props => props.gridRow ? props.gridRow : 'unset'};
`

export const StickyHeader = styled.div`
  position:     sticky;
  top:          0;
  background: #F8F8F8;
`

export const ColumnHeader = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  min-height:      3rem;
  border:          1px solid var(--rep-neutral-primary-light);
  border-bottom:   ${props => props.toggled ? 'none' : '1px solid var(--rep-neutral-primary-light)'};
  border-radius:   5px;
  border-bottom-left-radius:  ${props => props.toggled ? '0' : '5px'};
  border-bottom-right-radius: ${props => props.toggled ? '0' : '5px'};
  padding:         0 8px;
  background:    white;
  box-shadow:      0 0 3px var(--rep-neutral-primary-light);
  sup {
    color:        var(--rep-primary);
    font-weight:  900;
    margin-left:  4px;
    top:          unset;
  }
`

export const ColumnTitle = styled.div`
  font-size:    1rem;
  font-family:  'Inter', sans-serif;
  display:      flex;
  flex-grow:    1;
  user-select:  none;
  &:hover { cursor: ${props => props.onClick ? 'pointer' : 'default'} }
`

export const ColumnHeaderBtn = styled.div`
  display:     flex;
  align-items: center;
  font-weight: 500;
`

export const TogglableIcon = styled.div`
  transform:    rotate(${props => props.toggled ? 90 : 0}deg);
  transition:   .2s ease;
  color:        var(--rep-primary);
  margin-right: 8px;
`

export const FilterList = styled.div`
  height:        ${props => props.toggled ? 'auto' : 0};
  display:       flex;
  align-items:   center;
  gap:           4px;
  flex-wrap:     wrap;
  border-right:  1px solid var(--rep-neutral-primary-light);
  border-left:   1px solid var(--rep-neutral-primary-light);
  border-bottom: 1px solid var(--rep-neutral-light);
  background:  white;
  padding:       8px;
`

export const FilterButton = styled.div`
  background:    white;
  display:         flex;
  align-items:     center;
  justify-content: flex-end;
  color:           var(--rep-neutral-primary);
  font-weight:     500;
  padding:         4px 8px;
  &:hover { cursor: default; }
`

export const ColumnContent = styled.div`
  flex-grow:          ${props => props.noGrow  ? 'unset' : (props.toggled ? 1 : 0)};
  height:             ${props => props.toggled ? 'max-content' : 0};
  max-height:         max-content;
  max-height:         100vh;
  min-height:         ${props => props.toggled ? '30%' : 'none'};
  overflow:           ${props => props.toggled ? 'scroll' : 'hidden'};
  display:            flex;
  flex-direction:     column;
  box-shadow:         0 0 3px var(--rep-neutral-primary-light);
  background:         #FBFCFD;
  border-radius:      5px;
  border-top-left-radius:  0;
  border-top-right-radius: 0;
  padding:            ${props => props.toggled ? '8px' : '0'};
  border:             ${props => props.toggled ? '1px solid var(--rep-neutral-primary-light)' : 'none'};
  border-top:         none;
  /* margin-bottom:      12px; */
  padding-bottom:     0;
  -ms-overflow-style: none;
  scrollbar-width:    none;
`

export const ColumnFooter = styled.div`
  display:         flex;
  justify-content: flex-end;
  padding:         8px 0;
  position:        sticky;
  bottom:          4px;
  background:    white;
  border:          1px solid var(--rep-neutral-primary-light);
  border-radius:   4px;
  padding:         8px;
`

export const HeaderContainer = styled.div`
  grid-column:   1 / span 3;
  background:  white;
  border-radius: 8px;
  height:        min-content;
  border:        1px solid var(--rep-neutral-primary-light);
  /* border-bottom-right-radius: 8px; */
  /* border-top:                 none; */
`

export const TopStyle = styled.div`
  display:          flex;
  align-items:      flex-start;
  justify-content:  space-between;
  flex-wrap:        wrap;
  padding:          4px 8px;
  gap:              4px;
`

export const TopTagsStyle = styled.div`
  display:     flex;
  gap:         8px;
  align-items: flex-start;
  flex-wrap:   wrap;
  max-width:   calc(100% - 110px);
`

export const HeaderBottomDesktop = css`
  display:         flex;
  justify-content: space-between;
`

export const HeaderBottomMobile  = css`
  display:        flex;
  gap:            8px;
  flex-direction: column;
`

export const HeaderBottomStyles = styled.div`
  padding: 8px;
  ${props => props.isDesktop
    ? HeaderBottomDesktop
    : HeaderBottomMobile
}
`

export const MainTitleStyle = styled.div`
  background:    white;
  border-radius: 5px;
  padding:       0px 12px;
  width:         100%;
  text-align:    left;
  h1 {
    padding:      0;
    margin:       0;
    font-weight:  700;
    font-size:    2rem;
    font-family:  'Inter', sans-serif;
    text-align:   left;
  }
  p {
    color:  var(--rep-neutral-primary);
    margin: 8px 0 0 0;
  }
`

export const ReportTitleStyle = styled.div`
  display:         flex;
  align-items:     center;
  justify-content: flex-start;
  gap:             8px;
  color:           var(--rep-black);
`

export const ActionStyles = styled.div`
  display:          flex;
  align-items:      flex-start;
  justify-content:  flex-end;
  gap:              8px;
  flex-wrap:        wrap;
`

export const UsersStyles = styled.div`
  display: flex;
  gap:     4px;
`

export const TagStyles = styled.div`
  display:        flex;
  align-items:    center;
  /* flex-direction: column; */
  width:          max-content;
  flex-wrap:      wrap;
  gap:            8px;
`

export const CalloutStyles = styled.div`
  padding: 0 8px;
`

export const ReportMobileStyles = css`
  text-align:     center;
  padding:        0px 8px 100px 8px;

  @media only screen and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea {
      font-size: 11px !important;
    }
    input:focus,
    select:focus,
    textarea:focus {
      font-size: 16px !important;
    }
  }
`

export const ReportDesktopStyles = css`
  display:               grid;
  grid-template-columns: ${props => props.withCosts ? '4fr 6fr 5fr' : '2fr 3fr'};
  grid-template-rows:    min-content minmax(1em, 100vh);
  height:                100vh;
  max-height:            100vh;
  width:                 100%;
  box-sizing:            border-box;
  overflow:              auto;
  overflow-x:            hidden;
  overflow:              hidden;
  width:                 calc(100vw - 125px);
  grid-column-gap:       8px;
  grid-row-gap:          8px;
  padding:               8px;
  position:              relative;
`

export const ReportBodyLayout = styled.div`
  font-family: Inter, sans-serif;
  ${props => props.isDesktop
    ? ReportDesktopStyles
    : ReportMobileStyles
};
  width: 100%;
  position:   ${props => props.modalOpen ? 'relative' : 'relative'};
  overflow-y: ${props => props.modalOpen ? 'hidden' : 'auto'};
`

export const ReportDescription = styled.p`
  white-space: pre-line;
  max-height:  200px;
  overflow:    auto;
  scrollbar-color: var(--rep-neutral-middle) white;
`
