import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React  from 'react'
import styled from 'styled-components'

import Tag from '@components/Tag'

import * as Style from './style'

import ITask from '@interfaces/ITask.d'

import { useGlobalContextState } from '@context/GlobalContext'

import useHumanMs from '@hooks/useHumanMs'

const Line = styled.div`
  background:  rgb(255, 255, 255);
  width:         100%;
  padding:       16px;
  border-bottom: 1px solid var(--rep-neutral-primary-light);

  &:hover {
    cursor:     pointer;
    background: var(--rep-neutral-primary-light);
  }

  &:last-of-type {
    border-bottom:              none;
    border-bottom-left-radius:  5px;
    border-bottom-right-radius: 5px;
  }
`

const TaskLine = ({ task, click }: { task: ITask, click: (any) => void}) => {

  const { i18n, CONSTANTS } = useGlobalContextState()

  const { TASK } = CONSTANTS

  const statusIcon = ({
    [TASK.STATUS.NEW]:      <FontAwesomeIcon fixedWidth={true} icon={['far', 'paper-plane']} />,
    [TASK.STATUS.ASSIGNED]: <FontAwesomeIcon fixedWidth={true} icon={['far', 'user']} />,
    [TASK.STATUS.ONGOING]:  <FontAwesomeIcon fixedWidth icon={['far', 'circle']} fade />,
    [TASK.STATUS.DONE]:     <FontAwesomeIcon fixedWidth={true} icon={['far', 'eye']} />,
    [TASK.STATUS.CLOSED]:   <FontAwesomeIcon fixedWidth={true} icon='check' />,
  })[task.status] ?? <FontAwesomeIcon fixedWidth={true} icon={['far', 'paper-plane']} />

  return (
    <Line onClick={click}>
      <Style.TaskHeader>
        <Style.TaskStatus
          status  = {task.status}
          justify = "flex-start"
        >
          {statusIcon}
          {i18n.t(`todo.status.${task.status}`)}
        </Style.TaskStatus>
        <Style.TaskTopActions>
          {!!task.end_ptt_date &&
            <Tag
              icon           = {<FontAwesomeIcon icon={['far', 'calendar-xmark']} />}
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
              tooltipContent = {i18n.t('todo.stop')}
            >
              {new Date(task.end_ptt_date).toLocaleDateString(i18n.locale)}
            </Tag>
          }
          {!!task.duration &&
            <Tag
              icon           = {<FontAwesomeIcon icon="hourglass" />}
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
              tooltipContent = {i18n.t('todo.expected_duration')}
            >
              {useHumanMs(task.duration)}
            </Tag>
          }
        </Style.TaskTopActions>
      </Style.TaskHeader>
      <Style.TaskTitle>{task.title}</Style.TaskTitle>
    </Line>
  )
}

export default TaskLine
