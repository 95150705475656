import React, { useEffect, useMemo, useState } from 'react'

import Form   from '@components/Form/Form'
import Select from '@components/Form/Select'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import IAmenity from '@interfaces/IAmenity.d'
import IUnit    from '@interfaces/IUnit.d'

interface SelectUserFormProps {
  equipment:    IUnit | IAmenity
  type:         'responsible' | 'default_tech' | 'employee'
  multiselect?: boolean,
  callback:     (any) => void
}

const SelectUserForm: React.FC<SelectUserFormProps> = ({
  equipment,
  type,
  multiselect   = false,
  filters       = ['technicians', 'managers', 'employees', 'all'],
  defaultFilter = 'technicians',
  expertise,
  addOnsTitle,
  addOns,
  callback
}) => {

  const { i18n } = useGlobalContextState()

  const [selectedUsers, setSelectedUsers] = useState([])

  const userFilters = useMemo(() => {
    const resultFilters = []
    if (filters.includes('technicians')) {
      resultFilters.push({
        id:      'technicians',
        name:    i18n.t('user.role.technicians'),
        filters: { role: ['technician', 'technician_manager'] }
      })
    }
    if (filters.includes('managers')) {
      resultFilters.push({
        id:      'managers',
        name:    i18n.t('user.role.managers'),
        filters: { role: ['manager', 'local_manager'] }
      })
    }
    if (filters.includes('employees')) {
      resultFilters.push({
        id:      'employees',
        name:    i18n.t('user.role.employees'),
        filters: { role: ['employee', 'employee_manager'] }
      })
    }
    if (filters.includes('all')) {
      resultFilters.push({
        id:      'all',
        name:    i18n.t('shared.all'),
        filters: {}
      })
    }
    return resultFilters
  }, [filters])

  useEffect(() => {
    switch(type) {
      case 'responsible':  setSelectedUsers(equipment.responsibles); break
      case 'default_tech': setSelectedUsers([equipment.technician]); break
      case 'employee':     setSelectedUsers(equipment.employees);    break
    }
  }, [])

  return (
    <Form callback={callback}>

      <input type="hidden" name="type" value={type} />

      <FormStyle.Label>
        {i18n.t('user.users')}
      </FormStyle.Label>
      <Select
        name             = 'users'
        searchUrl        = '/users/search'
        defaultValue     = {selectedUsers}
        filters          = {userFilters}
        defaultFilter    = {defaultFilter}
        placeholder      = {i18n.t('user.your_users')}
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        marginY          = 'S'
        callback         = {users => setSelectedUsers(Array.isArray(users) ? users.map(u => u.object) : [users.object]) }
        multiselect      = {multiselect}
        search
        required
      />

      {!!expertise &&
      <Select
        label        = {i18n.t('expertise.expertise')}
        name         = 'expertise'
        search       = {true}
        searchUrl    = '/expertises/search'
        withEmpty    = {true}
        defaultValue = {[]}
        format       = {{ content: 'name', value: 'id' }}
        marginY      = 'M'
      />
      }

      {!!addOnsTitle && addOnsTitle}
      {!!addOns && addOns}

    </Form>
  )
}

export default SelectUserForm
