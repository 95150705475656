import React from 'react'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// const FETCH     = 'AMENITY_FETCH'
const FETCH_MEASURE = 'MEASURMENT_FETCH_MEASURE'
const ADD_MEASURE   = 'MEASURMENT_ADD_MEASURE'
const ADD_VALUE     = 'MEASURMENT_ADD_VALUE'

export const MEASURE_ACTIONS = {
  // FETCH:     FETCH,
  FETCH_MEASURE: FETCH_MEASURE,
  ADD_MEASURE:   ADD_MEASURE,
  ADD_VALUE:     ADD_VALUE,
}

export function measureReducer(state, action) {

  const { updateMethods, fetchApi } = state

  const callbacks = action.callbacks || []
  const fieldset  = action.fieldset
  const params    = new URLSearchParams(action.params).toString()

  switch (action.type) {
    case FETCH_MEASURE:
      fetchApi({
        url:       `/measurments/${action.measure.id}/rep_show.json?${params}`,
        // callback:  updateMethods.measure,
        followUps: callbacks
      })
      return state

    case ADD_MEASURE:
      fetchApi({
        url:       '/measurments/rep_create',
        method:    'POST',
        body:      {...action.data, amenity_id: action.amenity?.id },
        fieldset,
        callback:  data => updateMethods.addMeasurment(data.response),
        followUps: callbacks
      })
      return state

    case ADD_VALUE:
      fetchApi({
        url:       '/measurment_values/rep_create',
        method:    'POST',
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.measurment(data.response),
        followUps: callbacks
      })
      return state

    default:
      if (window.env !== 'production') {
        console.error(action)
        toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }
      return state
  }
}
