import styled from 'styled-components'

export const Header = styled.div`
  color: var(--rep-neutral-primary);
  font-size: 2em;
  font-weight: 600;
`

export const QuestionContainer = styled.div`
  display:         flex;
  gap:             8px;
  justify-content: space-between;
  align-items:     center;
  margin:          8px 0;
  border:          1px solid var(--rep-primary-light);
  border-radius:   5px;
  padding:         4px 8px;
`

export const QuestionName = styled.div`
  color:       var(--rep-neutral-primary);
  font-weight: 500;
  display:     flex;
  gap:         8px;
  align-items: center;
`
