import React from 'react'

import Button      from '@components/Button'
import WithTooltip from '@components/WithTooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './multiselectStyle'
import Tag from '@components/Tag'

interface MultiSelectProps {
  name:          string
  icon?:         React.ReactNode
  tooltip?:      string
  confirmText?:  string
  removeAction?: (any) => void
  click:         (any) => void
  children?:     React.ReactNode
}

const MultiselectItem: React.FC<MultiSelectProps> = ({
  name,
  icon,
  tooltip,
  confirmText,
  click,
  removeAction,
  colorName,
  backgroundName,
  children,
  border = 'var(--rep-neutral-primary-light)',
}) => {
  return(
    <Style.Item border={border}>
      <Tag
        tooltipContent = {tooltip}
        icon           = {icon}
        background     = {backgroundName}
        color          = {colorName}
        click          = {() => click ? click : null}
      >
        {name}
      </Tag>
      <Style.Content>{children}</Style.Content>
      {!!removeAction &&
        <Style.removeIcon>
          <Button
            click       = {removeAction}
            icon        = {<FontAwesomeIcon icon="times" />}
            border      = 'var(--rep-danger-light)'
            background  = 'var(--rep-danger-light)'
            color       = 'var(--rep-danger)'
            marginY     = 'S'
            confirmText = {confirmText}
            confirmIcon = {<FontAwesomeIcon icon="trash" />}
            confirm
          />
        </Style.removeIcon>
      }
    </Style.Item>
  )
}

export default MultiselectItem
