import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

const STOP    = 'MACHINE_STOP'
const READY   = 'MACHINE_READY'
const RESTART = 'MACHINE_RESTART'
const RESTOP  = 'MACHINE_RESTOP'

export const MACHINE_ACTIONS = {
  STOP:    STOP,
  READY:   READY,
  RESTOP:  RESTOP,
  RESTART: RESTART,
}

export function machineStopReducer(state, action) {

  const { updateMethods, fetchApi } = state

  const fieldset = action.fieldset

  switch (action.type) {
    case STOP:
      fetchApi({
        url:    '/machine_stops.json',
        method: 'POST',
        body:   {
          machine_stop: {
            equipment_id:   action.equipment.id,
            equipment_type: action.equipment.class_plural,
            ...action.data
          }
        },
        fieldset,
        callback:  data => updateMethods.machineStop(data.response),
        followUps: action.callbacks
      })
      return state

    case READY:
      fetchApi({
        url:      `/machine_stops/${action.stop.id}/ready.js`,
        method:   'PATCH',
        fieldset,
        callback: data => updateMethods.machineStop(data.response),
      })
      return state

    case RESTOP:
      fetchApi({
        url:      `/machine_stops/${action.stop.id}/restop.js`,
        method:   'PATCH',
        fieldset,
        callback: data => updateMethods.machineStop(data.response),
      })
      return state

    case RESTART:
      fetchApi({
        url:      `/machine_stops/${action.stop.id}/restart.js`,
        method:   'PATCH',
        fieldset,
        callback: data => updateMethods.machineStop(data.response),
      })
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state
  }
}
