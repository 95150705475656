import React, { useState }  from 'react'

import TreeProps from './types.d'

import TreeItem from './TreeItem'

const Tree: React.FC<TreeProps> = ({ before = [], current, after = [], icon, readOnly, fromModal }) => {

  const [showCurrent, setShowCurrent] = useState(true)
  return (
    <>
      {before.map((item, key) =>
        <TreeItem
          key            = {item.id}
          item           = {item}
          before         = {before}
          current        = {current}
          indent         = {key}
          setShowCurrent = {setShowCurrent}
          readOnly       = {readOnly}
          fromModal      = {fromModal}
          isFolded
        />
      )}
      {showCurrent &&
        <TreeItem
          item           = {current}
          current        = {current}
          before         = {before}
          indent         = {before.length + 1}
          setShowCurrent = {setShowCurrent}
          readOnly       = {readOnly}
          fromModal      = {fromModal}
          highlighted
          isFolded
        />
      }
    </>
  )
}

export default Tree
