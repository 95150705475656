import { ReactElement } from 'react'

export class Filter {
  /** Reference arrays of filters. It's an object because each key will
   * help to build the query string to fetch
   * will help build an url like 'status=new,ongoing&type=ticket'
   * @example {
   *  status: ['new', 'ongoing', 'closed'],
   *  type:   ['ticket', 'maintenance']
   * }
  */
  references?: { [key: string]: string[] }
  /** Describes the Filter button
   * @example {
   *  id:         'ongoing'
   *  name:       'En cours'
   *  icon:       <FontAwesomeIcon icon="clock" />
   *  reference: 'status'
   * }
   */
  content?:    {
    /** The name of the filter */
    id:        string
    /** The content of the button. Usually a translated `i18n` string */
    name:      string
    /** The icon of the button. A `<FontAwesome />` component */
    icon:      ReactElement,
    /** The reference to which key in the `reference` props it refers to */
    reference: string
  }[]
  /** Object of key being the reference and the value an array of string describing the selected filters */
  selected?:          { [key: string]: string[] }
  /** Current page */
  page:               number
  /** Total count of filtered items */
  totalFilteredCount: number
  /** Number of items to fetch per page */
  perPage:            number
  /** Total count of pages */
  totalPages:         number
  /** fetch Action that should be triggered. Usuallt a `fetchApi` call or an action
   * from the globalContext, like `API.fetchCosts`
  */
  fetch:        (any) => Promise<{
    page:        number
    total_pages: number
    total:       number
    response:    any[]
  }>
  /** Callback to execute after fetching a new page (-> inserting new results)
   * @example data => setItems([...items, ...data])
  */
  pageCallback:  (data) => void
  /** Callback to execute after fetching another result (-> replacing the results)
  * @example data => setItems(data)
 */
  callback:      (data) => void
  columnContent: HTMLElement

  constructor({
    references = {},
    filters    = [],
    totalFilteredCount = 0,
    perPage,
    totalPages,
    fetch,
    callback,
    pageCallback
  }) {
    this.references         = references
    this.selected           = Object.assign({}, ...Object.keys(references).map(key => ({ [key]: [] })))
    this.content            = filters
    this.fetch              = fetch
    this.pageCallback       = pageCallback
    this.callback           = callback
    this.page               = 1
    this.totalFilteredCount = totalFilteredCount
    this.perPage            = perPage
    this.totalPages         = totalPages
  }

  isIncluded(filter: string, reference: string) {
    return this.selected[reference].includes(filter)
  }

  hasAnySelected() {
    return !!Object.keys(this.selected).filter(fil => this.selected[fil].length).length
  }

  select(filter: string, reference: string, setLoading?: (any) => void) {
    this.page = 1
    if (this.selected[reference].includes(filter)) {
      this.selected[reference] = this.selected[reference].filter(stat => stat !== filter)
    } else {
      this.selected[reference] = [...this.selected[reference], filter]
    }
    this.triggerNewFetch({ setLoading })
  }

  url() {
    return `page=${this.page}&per_page=${this.perPage}&${Object.keys(this.selected).map(key => `${key}=${this.selected[key].join(',')}`)
      .join('&')}`
  }

  setPageExternal(page) {
    this.page = page
  }

  setTotalPagesExternal(pages) {
    this.totalPages = pages
  }

  setTotalFilteredCount(total) {
    this.totalFilteredCount = total
  }

  triggerPageFetch({ setLoading }: { setLoading?: (any) => void }) {
    if (this.page >= this.totalPages) { return }
    if (this.page < this.totalPages) this.page += 1

    setLoading && setLoading(true)

    this.fetch(this.url()).then(data => {
      this.totalFilteredCount = data.total
      this.totalPages         = data.total_pages
      this.pageCallback(data.response)
      setLoading && setLoading(false)
    })
  }

  triggerNewFetch({ setLoading }: { setLoading?: (any) => void }) {
    this.page = 1
    setLoading && setLoading(true)

    this.fetch(this.url()).then(data => {
      this.totalFilteredCount = data.total
      this.totalPages         = data.total_pages
      this.callback(data)
      setLoading && setLoading(false)
      if (this.columnContent) this.columnContent.scrollTop = 0
    })
  }

  setColumnContent(div) {
    this.columnContent = div
  }
}
