import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button   from '@components/Button'
import Checkbox from '@form/Checkbox'
import Input    from '@form/Input'
import Tag      from '@components/Tag'
import Textarea from '@form/Textarea'

import * as FormStyle from '@form/FormStyles'
import * as Style     from './FormStyle'

import { useGlobalContextState } from '@context/GlobalContext'

const QuestionForm = ({ addForm }) => {

  const { i18n } = useGlobalContextState()

  const [questions, setQuestions]: { name: string, comment: boolean, na: boolean }[] = useState([])

  const [name,               setName]               = useState('')
  const [template,           setTemplate]           = useState(false)

  const [questionName,       setQuestionName]       = useState('')
  const [questionComment,    setQuestionComment]    = useState(false)
  const [questionUnanswered, setQuestionUnanswered] = useState('')
  const [questionNA,         setQuestionNA]         = useState(false)
  const [resetForm,          setResetForm]          = useState(false)

  const formRef       = useRef(null)
  const inputRef      = useRef(null)
  const unansweredRef = useRef(null)

  useEffect(() => {
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  })

  const createForm = () => {
    const tempId = Array(1).fill()
      .map(z => Math.random().toString(36)
        .slice(2))
      .join('')
    addForm({ tempId, name, questions, template })
    setName('')
    setQuestions([])
    setTemplate(false)
  }

  const detectEnterKey = key => key == 'Enter' ? addQuestion() : null

  const addQuestion = () => {
    if (!questionName) return
    setQuestions([...questions, {
      name:      questionName,
      comment:   questionComment,
      na:        questionNA,
      neg_reply: questionUnanswered
    }])
    setResetForm(true)
    setQuestionName('')
    setQuestionComment(false)
    setQuestionNA(false)
    setQuestionUnanswered('')
    unansweredRef.current.value = ''
    setTimeout(() => setResetForm(false), 500)
    inputRef.current.focus()
    inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  const removeQuestion = key => {
    const newQuestions = [...questions]
    newQuestions.splice(key, 1)
    setQuestions(newQuestions)
  }

  return(
    <Style.FormContainer ref={formRef}>
      <FormStyle.Label>{i18n.t('form_reply.actions.create')}</FormStyle.Label>
      <FormStyle.Group>
        <Input
          type         = "text"
          label        = {i18n.t('form.name')}
          name         = "form-name"
          defaultValue = {name}
          change       = {e => setName(e.currentTarget.value)}
          tooltip      = {i18n.t('form.tooltip.form_name')}
        />
      </FormStyle.Group>

      <Style.QuestionsContainer>
        {questions.map((question, key) =>
          <Style.QuestionContainer key={key}>
            <Style.QuestionName>
              <FontAwesomeIcon icon="list" />
              {question.name}
            </Style.QuestionName>
            <div style={{ display: 'flex', gap: '8px' }}>
              {!!question.neg_reply &&
                <Tag
                  icon           = {<FontAwesomeIcon icon="circle-exclamation" />}
                  color          = {question.neg_reply ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                  border         = {question.neg_reply ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                  tooltipTitle   = {'Hint'}
                  tooltipContent = {question.neg_reply}
                />
              }
              <Tag
                icon           = {<FontAwesomeIcon icon="comment-dots" />}
                color          = {question.comment ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                border         = {question.comment ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                tooltipContent = {question.comment ? i18n.t('form.accept_comment') : i18n.t('form.reject_comment')}
              />
              <Tag
                icon           = {<FontAwesomeIcon icon="eye-slash" />}
                color          = {question.na ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                border         = {question.na ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
                tooltipContent = {question.na ? i18n.t('form.accept_na') : i18n.t('form.reject_na')}
              />
              <Button
                icon   = {<FontAwesomeIcon icon="trash" />}
                border = 'var(--rep-danger)'
                color  = 'var(--rep-danger)'
                click  = {() => removeQuestion(key)}
              />
            </div>
            <Input
              name         = {`question-${key}`}
              type         = "hidden"
              defaultValue = {JSON.stringify(question)}
            />
          </Style.QuestionContainer>
        )}
      </Style.QuestionsContainer>
      {!!name &&
        <>
          <FormStyle.Group marginY='M'>
            <Input
              forwardRef   = {inputRef}
              type         = "text"
              label        = {i18n.t('form.question_name')}
              name         = "template-name"
              defaultValue = {questionName}
              change       = {event => setQuestionName(event.currentTarget.value)}
              keyUp        = {detectEnterKey}
              tooltip      = {i18n.t('form.tooltip.question_name')}
            />
          </FormStyle.Group>

          <Textarea
            forwardRef   = {unansweredRef}
            label        = {i18n.t('form.hint_unanswered')}
            name         = "template-unanswered"
            change       = {event => setQuestionUnanswered(event.currentTarget.value)}
            // hint         = '!'
            tooltip      = {i18n.t('form.tooltip.unanswered')}
          />
          <FormStyle.Group marginY='M'>
            <Style.QuestionCheckboxes>
              <Checkbox
                label        = {i18n.t('form.na')}
                name         = "question-n-a"
                defaultValue = {false}
                callback     = {check => setQuestionNA(check.isCheck)}
                shouldReset  = {resetForm}
                tooltip      = {i18n.t('form.tooltip.allow_na')}
              />
              <Checkbox
                label        = {i18n.t('form.comment')}
                name         = "question-comment"
                defaultValue = {false}
                callback     = {check => setQuestionComment(check.isCheck)}
                shouldReset  = {resetForm}
                tooltip      = {i18n.t('form.tooltip.allow_comment')}
              />
            </Style.QuestionCheckboxes>
            <Button
              icon       = {<FontAwesomeIcon icon="plus" />}
              color      = {questionName ? 'var(--rep-success)' : 'var(--rep-neutral)'}
              border     = {questionName ? 'var(--rep-success)' : 'var(--rep-neutral)'}
              click      = {addQuestion}
              alingSelf  = 'stretch'
            >
              {i18n.t('form.actions.add_form_question')}
            </Button>
          </FormStyle.Group>
        </>
      }

      {!!name && !!questions.length &&
        <>
          <Checkbox
            label        = {i18n.t('form.as_template')}
            name         = "form-template"
            marginY      = 'M'
            defaultValue = {template}
            callback     = {e => setTemplate(e.isCheck)}
          />
          <Button
            border      = 'var(--rep-primary)'
            color       = 'white'
            background  = 'var(--rep-primary)'
            icon        = {<FontAwesomeIcon icon="plus" />}
            click       = {createForm}
          >
            {i18n.t('form.actions.add_new_form')}
          </Button>
        </>
      }
    </Style.FormContainer>
  )
}

export default QuestionForm
