import React, { useRef, useState }   from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar   from '@components/Avatar'
import Callout  from '@components/Callout'
import Checkbox from '@components/Form/Checkbox'
import Input    from '@form/Input'

import * as Style from './style'

import FormQuestionProps from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

/** @description FormQuestion component. Display the current answer and updates it */
const FormQuestion: React.FC<FormQuestionProps> = ({
  question,
  form,
  answerQuestion,
}) => {

  const { i18n, CONSTANTS } = useGlobalContextState()

  const { FORM_ANSWER } = CONSTANTS

  const [currentQuestion, setCurrentQuestion] = useState(question)

  const formRef = useRef(null)
  const commentRef = useRef(null)

  const submitForm = e => {
    if (!form.permissions.update_answer)  return
    if (currentQuestion.answer === FORM_ANSWER.NA) return

    const comment   = currentQuestion.allow_comment && commentRef.current.value ? commentRef.current.value : ''
    const newAnswer = { ...currentQuestion, answer: !currentQuestion.answer, comment: comment }
    setCurrentQuestion(newAnswer)
    answerQuestion(newAnswer, form)
  }

  const submitComment = () => {
    const comment   = currentQuestion.allow_comment && commentRef.current.value ? commentRef.current.value : ''
    const newAnswer = { ...currentQuestion, comment: comment }
    setCurrentQuestion(newAnswer)
    answerQuestion(newAnswer, form)

  }

  const submitNonApplicable = () => {
    if (!form.permissions.update_answer) return

    const newAnswer = { ...currentQuestion, answer: currentQuestion.answer === FORM_ANSWER.NA ? false : FORM_ANSWER.NA }
    setCurrentQuestion(newAnswer)
    answerQuestion(newAnswer, form)
  }

  return (
    <Style.Container
      ref           = {formRef}
      onSubmit      = {e => e.preventDefault()}
      valid         = {!!currentQuestion.answer}
      notApplicable = {currentQuestion.answer === FORM_ANSWER.NA}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <Style.Checkbox
          valid    = {currentQuestion.answer}
          onClick  = {submitForm}
          disabled = {currentQuestion.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
        >
          {currentQuestion.answer === FORM_ANSWER.NA
            ? <Style.Check><FontAwesomeIcon icon='lock' /></Style.Check>
            : currentQuestion.answer
              ? <Style.Check><FontAwesomeIcon icon='square-check' /></Style.Check>
              : <Style.Check><FontAwesomeIcon icon={['far', 'square']} /></Style.Check>
          }
          {currentQuestion.name}
          {currentQuestion.answer === FORM_ANSWER.NA || !form.permissions.update_answer &&
            <FontAwesomeIcon icon="lock" />
          }
        </Style.Checkbox> */}

        <Checkbox
          name         = 'test'
          label        = {currentQuestion.name}
          border       = {currentQuestion.answer ? currentQuestion.answer !== FORM_ANSWER.NA ? 'var(--rep-success)'       : 'var(--rep-neutral)' : 'var(--rep-primary)'}
          color        = {currentQuestion.answer ? currentQuestion.answer !== FORM_ANSWER.NA ? 'var(--rep-success)'       : 'var(--rep-neutral)' : 'var(--rep-primary)'}
          background   = {currentQuestion.answer ? currentQuestion.answer !== FORM_ANSWER.NA ? 'var(--rep-success-light)' : 'var(--rep-neutral)' : 'var(--rep-primary-light)'}
          callback     = {submitForm}
          defaultValue = {!!currentQuestion.answer}
          disabled     = {currentQuestion.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
        />
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          {!!currentQuestion.allow_na &&
          <Style.NA
            onClick = {submitNonApplicable}
            active  = {currentQuestion.answer === FORM_ANSWER.NA}
          >
            <FontAwesomeIcon icon="eye-slash" />
            <p>{i18n.t('form_reply.not_applicable')}</p>
          </Style.NA>
          }
          {currentQuestion.answer && currentQuestion.user &&
            <Avatar
              firstName      = {currentQuestion.user.first_name}
              lastName       = {currentQuestion.user.last_name}
              tooltipContent = {`Answered the ${new Date(currentQuestion.answered_at).toLocaleDateString(i18n.locale)}`}
            />
          }
        </div>
      </div>

      {!currentQuestion.answer && !!currentQuestion.unanswered_text && currentQuestion.answer !== FORM_ANSWER.NA &&
        <Callout
          border     = 'var(--rep-neutral-primary)'
          background = 'var(--rep-neutral-primary-light)'
          color      = 'var(--rep-neutral-primary)'
          border     = 'none'
          icon       = {<FontAwesomeIcon icon="question-circle" />}
          marginY    = 'S'
        >
          {currentQuestion.unanswered_text}
        </Callout>
      }

      {!!currentQuestion.allow_comment &&
        <Input
          forwardRef   = {commentRef}
          type         = 'text'
          name         = "comment"
          label        = {i18n.t('form_reply.comment')}
          defaultValue = {currentQuestion.comment}
          blur         = {submitComment}
          marginY      = 'M'
          disabled     = {currentQuestion.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
        />
      }

    </Style.Container>
  )
}

export default FormQuestion
