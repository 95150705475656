import styled from 'styled-components'

export const Message = styled.div`
  margin:        4px 0;
  padding-right: ${props => props.self ? '0px' : '40px'};
  padding-left:  ${props => props.self ? '40px' : '0px'};
`

export const Date = styled.div`
  text-align: ${props => props.self ? 'right' : 'left'};
  color:      var(--rep-neutral-primary);
  font-size:  .8em;
`

export const MessageInfo = styled.div`
  display:         flex;
  justify-content: ${props => props.self ? 'flex-start' : 'flex-start'};
  flex-direction:  ${props => props.self ? 'row-reverse' : 'row'};
  align-items:     center;
  gap:             4px;
  margin-top:      16px;
  margin-bottom:   4px;
  margin-right:    ${props => props.self ? '40px' : '0px'};
  margin-left:     ${props => props.self ? '0px' : '40px'};
`
export const UserName = styled.div`
  font-size:   .8em;
  font-weight: 500;
`


export const Content = styled.div`
  word-break:                 break-word;
  width:                      min-content;
  border-radius:              5px;
  padding:                    8px;
  flex-grow:                  1;
  white-space:                pre-line;
  text-align:                 ${props => props.self ? 'right' : 'left'};
  background:                 ${props => props.self ? 'var(--rep-primary-light)' : 'var(--rep-neutral-light)'};
  color:                      ${props => props.self ? 'var(--rep-primary)' : 'var(--rep-neutral-primary)'};
  border-bottom-left-radius:  ${props => props.self ? '5px' : '2px' };
  border-bottom-right-radius: ${props => props.self ? '2px' : '5px' };
`

export const ContentBlock = styled.div`
  display:        flex;
  align-items:    flex-end;
  flex-direction: ${props => props.self ? 'row' : 'row-reverse'};
  gap:            8px;
`

export const Image = styled.img`
  width:         100%;
  height:        120px;
  object-fit:    cover;
  border-radius: 5px;
  &:hover { cursor: pointer }
`
