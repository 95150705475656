import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    color: var(--rep-black);
  }

  :root {
    --rep-black:           #122b3d;

    --rep-primary:        #2e9af1;
    --rep-primary-middle: #abd7fb;
    --rep-primary-light:  #D5EAFC;

    --rep-success:        #50c6a6;
    --rep-success-middle: #8eead2;
    --rep-success-light:  #d5f6ed;

    --rep-warning:        #FFAB0A;
    --rep-warning-middle: #f3ce88;
    --rep-warning-light:  #FFF3CD;

    --rep-danger:         #FF5C00;
    --rep-danger-middle:  #ff9778;
    --rep-danger-light:   #F9D7DA;

    --rep-neutral:        #949494;
    --rep-neutral-middle: #c3c3c3;
    --rep-neutral-light:  #F6F6F6;
    --rep-neutral-primary:#64788d;
    --rep-neutral-primary-middle:#b7c2cd;
    --rep-neutral-primary-light: #dde3e9;

    --rep-accent:         #9799CA;
    --rep-accent-middle:  #b8bae3;
    --rep-accent-light:   #e4e4fa;

    --rep-new:        var(--rep-primary);
    --rep-new-light:  var(--rep-primary-light);

    --rep-assigned:   #9799CA;
    --rep-assigned-light:   #e4e4fa;
    --rep-ongoing:    #9799CA;

    --rep-on-hold:    #CC82E5;
    /* --rep-done:     #666BDB; */
    --rep-done:       var(--rep-warning);
    --rep-done-light: var(--rep-warning-light);
    --rep-to-approve: var(--rep-warning);
    --rep-to_approve: var(--rep-warning);
    --rep-to_approve-light: var(--rep-warning-light);
    --rep-approved:   var(--rep-success);
    --rep-approved-light: var(--rep-success-light);
    --rep-closed:     var(--rep-success);
    --rep-closed-light:     var(--rep-success-light);
    --rep-late:       var(--rep-danger);
    --rep-rejected:   var(--rep-danger);

    --rep-spacer-s:  4px;
    --rep-spacer-m:  1rem;
    --rep-spacer-l:  2rem;
    --rep-spacer-xl: 3rem;
  }
`
