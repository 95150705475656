import React, { useCallback, useMemo, useState } from 'react'
import { FontAwesomeIcon }                       from '@fortawesome/react-fontawesome'

import Accordion     from '@components/Accordion'
import Button        from '@components/Button'
import Callout       from '@components/Callout'
import ControlRemark from '@components/ControlRemark/ControlRemark'
import DatePicker    from '@components/Form/DatePicker'
import FieldInput    from '@form/FieldInput'
import Form          from '@components/Form/Form'
import Input         from '@components/Form/Input'
import Select        from '@components/Form/Select/Select'
import Tag           from '@components/Tag/Tag'
import Textarea      from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '../Form/FormStyles'

import { IReport }        from '@interfaces/index'
import { REPORT_ACTIONS } from '@reducers/index'

import useHumanMs from '@hooks/useHumanMs'

const SEND_FOR_APPROVAL = 'send_for_approval'
const APPROVE           = 'approve'
const CLOSE             = 'close'
const CANCEL            = 'cancel'
const STEPS             = [SEND_FOR_APPROVAL, APPROVE, CLOSE, CANCEL]

interface EndReportProps {
  step:          typeof STEPS[number]
  report:        IReport,
  dispatch:      React.Dispatch<unknown>
  updateMethods: { [name: string]: (any) => void }
}

const EndReport: React.FC<EndReportProps> = ({
  step,
  report,
  dispatch,
  updateMethods
}) => {

  const {
    i18n,
    initAPI,
    current_user,
    CONSTANTS
  } = useGlobalContextState()

  const { TYPE_VALUES } = CONSTANTS.REPORT_TYPES
  const { ROLES }       = CONSTANTS.USER

  const [closingDate, _setClosingDate] = useState(new Date())

  // TODO : REFACTOR
  const [measurments, setMeasurments]  = useState(report.measurments_closing.map((amMeasurment) => {
    return ({
      id:      amMeasurment.id,
      name:    amMeasurment.name,
      value:   report.measurment_values.find(f => f.measurment.id === amMeasurment.id)?.value || '',
      comment: report.measurment_values.find(f => f.measurment.id === amMeasurment.id)?.comment || ''
    })
  }))

  // TODO : REFACTOR
  const [fields, setFields] = useState(report.type.custom_fields.map(field => ({
    field_id: field.id,
    value:    report.custom_fields.find(f => f.id === field.id)?.value
  })))

  // TODO : REFACTOR
  const handleMeasurmentChange = (measurmentValue) => {
    const newMeasurments = [...measurments]
    const measurment     = newMeasurments.find(m => m.id === measurmentValue.id)
    if (measurment) {
      measurment.value   = measurmentValue.value || measurment.value
      measurment.comment = measurmentValue.comment || measurment.comment
    }
    setMeasurments(newMeasurments)
  }

  // TODO : REFACTOR
  const handleFieldChange = (fieldValue) => {
    const newFields = [...fields]
    newFields.find(field => field.field_id === fieldValue.field_id).value = fieldValue.value
    setFields(newFields)
  }

  const API = initAPI({
    reportId:      report.id,
    updateMethods: updateMethods
  })

  // TODO : REFACTOR
  const addFormData = formData => {
    formData.append('measurment_values', JSON.stringify(measurments))
    formData.append('report_fields',     JSON.stringify(fields))
  }

  const submitAction = useCallback((data, fieldset) => {
    switch(step) {
      case SEND_FOR_APPROVAL: return dispatch({
        type:      REPORT_ACTIONS.SEND_FOR_APPROVAL,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
      case CANCEL: return dispatch({
        type:      REPORT_ACTIONS.CANCEL,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
      case APPROVE: return dispatch({
        type:      REPORT_ACTIONS.APPROVE,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
      case CLOSE: return dispatch({
        type:      REPORT_ACTIONS.CLOSE,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
    }
  }, [])

  const submitValue = useMemo(() => {
    switch(step) {
      case SEND_FOR_APPROVAL: return i18n.t('report.actions.send_for_approval')
      case APPROVE:           return i18n.t('report.actions.approve_report')
      case CANCEL:            return i18n.t('report.actions.cancel_report')
      case CLOSE:             return i18n.t('report.actions.close_report')
    }
  }, [])

  const fieldVisible = (field) => {
    switch (current_user.role) {
      case ROLES.EMPLOYEE_MANAGER:
        return field.visible_to_employee_managers
      case ROLES.EMPLOYEE:
        return field.visible_to_employees
      case ROLES.TECHNICIAN_MANAGER:
        return field.visible_to_technician_managers
      case ROLES.TECHNICIAN:
        return field.visible_to_technicians
      default:
        return true
    }
  }

  return (
    <Form
      callback           = {submitAction}
      addFormData        = {addFormData}
      submitText         = {submitValue}
      submitBackground   = {[CANCEL].includes(step) ? 'var(--rep-danger-light)' : 'var(--rep-primary-light)'}
      submitColor        = {[CANCEL].includes(step) ? 'var(--rep-danger)' : 'var(--rep-primary)'}
    >
      {/* {!report.permissions.require_report_approve && step !== CANCEL &&
        <Callout
          icon       = {<FontAwesomeIcon icon="triangle-exclamation" />}
          color      = 'var(--rep-warning)'
          background = 'var(--rep-warning-light)'
          border     = 'var(--rep-warning)'
        >
          {i18n.t('report.closing_with_open_task_warning_message')}
        </Callout>
      } */}
      {step === CANCEL &&
        <Callout
          icon       = {<FontAwesomeIcon icon="triangle-exclamation" />}
          color      = 'var(--rep-warning)'
          background = 'var(--rep-warning-light)'
          border     = 'var(--rep-warning)'
        >
          {i18n.t('report.deleting_warning_message')}
        </Callout>
      }
      {report.permissions.can_see_costs && report.permissions.require_report_approve && ![CANCEL].includes(step) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="money-bill-transfer" />{i18n.t('offer_line.cost_resume')}</FormStyle.Header>
          <Accordion
            open      = {false}
            border    = 'var(--rep-primary-light)'
            togglable = {false}
            header    = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="user-clock" /> {i18n.t('offer_line.line_types.hours')} × {useHumanMs(report.hours_quantity)}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.hours_amount)}
                  </Tag>
                }
              </>
            }
          />

          <Accordion
            open      = {false}
            border    = 'var(--rep-primary-light)'
            togglable = {false}
            header    = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="truck" /> {i18n.t('offer_line.line_types.transport')}  × {report.transports_quantity}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.transports_amount)}
                  </Tag>
                }
              </>
            }
          />

          <Accordion
            open      = {false}
            border    = 'var(--rep-primary-light)'
            togglable = {false}
            header    = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="cubes" /> {i18n.t('offer_line.line_types.inventory')}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.materials_amount)}
                  </Tag>
                }
              </>
            }
          />

          <Accordion
            open      = {false}
            border    = 'var(--rep-primary-light)'
            togglable = {false}
            header    = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="file-invoice-dollar" /> {i18n.t('offer_line.line_types.external_invoice')}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.invoices_amount)}
                  </Tag>
                }
              </>
            }
          />

          {!!report.permissions.can_see_prices &&
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Tag
                icon       = {<FontAwesomeIcon icon="receipt" />}
                background = 'white'
                color      = 'var(--rep-success)'
                border     = 'var(--rep-success)'
              >
                {i18n.t('offer_line.total_approved', { total: Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.all_costs_amount) })}
              </Tag>
            </div>
          }
        </>
      }

      {[APPROVE, CLOSE].includes(step) && !!report.remark &&
          <>
            <FormStyle.Header><FontAwesomeIcon icon="section" />{i18n.t('control_remarks.linked_remark')}</FormStyle.Header>
            <div style={{margin: '8px 0'}}></div>
            <ControlRemark
              remark               = {report.remark}
              report               = {report}
              showOriginReport
              showFollowingReports = {false}
              displayOptions       = {false}
              updateMethods        = {updateMethods}
            />
          </>
      }

      {/* Fix visibility */}
      <FormStyle.Header><FontAwesomeIcon icon="receipt" />{i18n.t('anomaly.anomaly')}</FormStyle.Header>
      {[SEND_FOR_APPROVAL, APPROVE].includes(step) || (step === CLOSE && !report.permissions.require_report_approve)
        ? <Select
          name          = 'anomaly'
          search        = {true}
          searchUrl     = '/anomalies/search'
          withEmpty     = {true}
          defaultValue  = {[report.anomaly]}
          format        = {{ content: 'name', value: 'id' }}
          marginY       = 'M'
          required      = {report.permissions.require_anomaly}
          filters       = {[
            {
              id:      'report_type',
              name:    i18n.t('anomaly.anomalies'),
              filters: { report_type_id: `${report.type.id}` }
            }
          ]}
        />
        : <Tag
          background = 'var(--rep-neutral-light)'
          color      = 'var(--rep-neutral-primary)'
          marginY    = 'M'
        >
          {report.input_code ? report.input_code.label : 'No input code'}
        </Tag>
      }

      {/* Fix visibility */}
      <FormStyle.Header><FontAwesomeIcon icon="receipt" />{i18n.t('charge_number.charge_number')}</FormStyle.Header>
      {[SEND_FOR_APPROVAL, APPROVE].includes(step) || (step === CLOSE && !report.permissions.require_report_approve)
        ? <Select
          name          = 'charge_number'
          search        = {true}
          searchUrl     = '/charge_numbers/search'
          withEmpty     = {true}
          defaultValue  = {[report.input_code]}
          format        = {{ content: 'number', value: 'id', details: 'type' }}
          marginY       = 'M'
          required      = {report.permissions.require_charge_number}
        />
        : <Tag
          background = 'var(--rep-neutral-light)'
          color      = 'var(--rep-neutral-primary)'
          marginY    = 'M'
        >
          {report.input_code ? report.input_code.label : 'No input code'}
        </Tag>
      }

      {report.measurments_closing.map((measurment) => {
        if (([SEND_FOR_APPROVAL, APPROVE].includes(step) || (step === CLOSE && !report.permissions.require_report_approve)) ) {
          return(
            <>
              <FormStyle.Header><FontAwesomeIcon icon="ruler" />{measurment.name}</FormStyle.Header>
              <Input
                background   = {'var(--rep-primary-light)'}
                border       = {'var(--rep-primary)'}
                color        = {'var(--rep-primary)'}
                defaultValue = {report.measurment_values.find(f => f.measurment.id === measurment.id)?.value}
                name         = {`measurments[${measurment.id}][value]`}
                label        = {i18n.t('measurment.value')}
                required     = {true}
                marginY      = 'M'
                type         = "text"
                change       = {(event) => handleMeasurmentChange({ id: measurment.id, value: event.currentTarget.value })}
              />
              <Textarea
                name         = {`measurments[${measurment.id}][comment]`}
                label        = {i18n.t('shared.comment')}
                defaultValue = {report.measurment_values.find(f => f.measurment.id === measurment.id)?.comment}
                marginY      = 'M'
                change       = {(event) => handleMeasurmentChange({ id: measurment.id, comment: event.currentTarget.value })}
              />
            </>
          )
        }
      })}

      {!!report.type.custom_fields.length && ([SEND_FOR_APPROVAL, APPROVE].includes(step) || (step === CLOSE && !report.permissions.require_report_approve)) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="layer-group" />{i18n.t('custom_field.custom_fields')}</FormStyle.Header>
          {report.type.custom_fields.map((field) => {
            return (fieldVisible(field) && <FieldInput
              key          = {field.id}
              field        = {field}
              defaultValue = {report.custom_fields.find(f => f.id === field.id)?.value}
              marginY      = 'M'
              disabled     = {report?.id && !report.permissions.can_update_report}
              callback     = {handleFieldChange}
              required     = {[TYPE_VALUES.ON_CLOSE, TYPE_VALUES.MANDATORY].includes(field.mandatory)}
            />)
          })}
        </>
      }

      {step === SEND_FOR_APPROVAL &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="user-check" />{i18n.t('report.to_be_validated_by', { name: '' })}</FormStyle.Header>
          <Select
            name             = 'approver'
            search           = {true}
            label            = {i18n.t('report.select_validator')}
            searchUrl        = '/users/search'
            defaultValue     = {[]}
            filters          = {[{
              id:      'role',
              name:    'Managers',
              filters: { role: 'manager,local_manager' }
            }]}
            defaultFilter    = 'role'
            format           = {{ content: 'name', value: 'id', details: 'role' }}
            required         = {true}
            marginY          = 'M'
            detailsLocaleKey = 'shared'
          />
        </>
      }

      {step === CLOSE &&
        <>
          {report.permissions.can_choose_closing_date &&
            <div>
              <FormStyle.Header>
                <FontAwesomeIcon icon="calendar-check" />{i18n.t('report.closing_date')}
              </FormStyle.Header>
              <DatePicker
                name     = 'ended_at'
                date     = {closingDate}
                // maxDate  = {new Date()}
                required
              />
            </div>
          }
          <div>
            <FormStyle.Header>
              <FontAwesomeIcon icon="comment" />{i18n.t('report.closing_comment')}
            </FormStyle.Header>
            <Textarea
              name         = 'closing_comment'
              label        = {i18n.t('report.closing_comment')}
              defaultValue = {report.closing_comment}
              disabled     = {!report.permissions.can_close_report && !report.permissions.can_approve_and_close}
              marginY      = 'M'
              required     = {report.permissions.require_closing_comment}
            />
          </div>

          {!!report.maintenance_plan &&
            (report.maintenance_plan.mode === 'recurrency' || report.maintenance_plan.auto_generate) &&
            <>
              <FormStyle.Header>
                <FontAwesomeIcon icon="oil-can" />{i18n.t('report.next_maintenance')}
              </FormStyle.Header>
              <Callout
                type    = 'warning'
                icon    = {<FontAwesomeIcon icon="info-circle" />}
                marginY = 'S'
                title   = {i18n.t('maintenance.maintenance_recurrence')}
              >
                {report.maintenance_plan.mode === 'interval'
                  ? i18n.t('report.maintenance_recurrency', { recurrency: report.maintenance_plan.sentence, done_date: new Intl.DateTimeFormat(i18n.locale).format(new Date(report.done_at)) })
                  : report.maintenance_plan.sentence
                }
              </Callout>
              {report.next_maintenance.id
                ?
                <Button
                  border = 'var(--rep-primary)'
                  hover  = 'var(--rep-primary-light)'
                  icon   = {<FontAwesomeIcon icon="receipt" />}
                  click  = {() => window.open(`/reports/${report.next_maintenance.id}`)}
                >
                  {i18n.t('report.maintenance_already_generated', { date: new Intl.DateTimeFormat(i18n.locale).format(new Date(report.next_maintenance.date)) })}
                </Button>
                : <>
                  <DatePicker
                    name     = 'next_maintenance'
                    date     = {report.next_maintenance.date}
                    required
                  />
                  <Input
                    type         = 'hidden'
                    name         = 'exclusion'
                    defaultValue = {report.next_maintenance.date}
                  />
                </>
              }
            </>
          }
        </>
      }

      {[CANCEL].includes(step) &&
        <div>
          <FormStyle.Header>
            <FontAwesomeIcon icon="comment" />{i18n.t('report.closing_comment')}
          </FormStyle.Header>
          <Textarea
            name         = 'closing_comment'
            label        = {i18n.t('report.closing_comment')}
            marginY      = 'M'
            required     = {report.permissions.require_closing_comment}
          />
        </div>
      }
    </Form>
  )
}

export default EndReport
