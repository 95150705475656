import styled from 'styled-components'

export const Tooltip = styled.div`
  display:        inline-block;
  background:     var(--rep-neutral-primary);
  padding:        4px 8px;
  border-radius:  5px;
  z-index:        9000;
  font-weight:    400;
  font-size:      .8rem;
  color:          var(--rep-neutral-light);
  text-align:     left;
  h6 {
    font-size:      10px;
    font-weight:    400;
    margin:         0;
    color:          var(--rep-neutral-light);
    text-align:     left;
  }
  &:hover {
    cursor: default;
  }
`
