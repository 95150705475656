import React, { useMemo, useReducer } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MachineStop from './MachineStop'

import { useGlobalContextState } from '@context/GlobalContext'

import { MACHINE_ACTIONS, machineStopReducer } from '@reducers/machineStopReducer'

import { IAmenity, IMachineStop, IUnit } from '@interfaces/index'
import Dropdown from '@components/Dropdown'
import MachineStopForm from './Form'

interface MachineStopTagProps {
  machine_stop?: IMachineStop
  equipment:     IAmenity | IUnit
  updateMethods: {
    machineStop: (object) => void
  }
  readOnly?:  boolean,
  alignSelf?: 'flex-start' | 'flex-end' | 'center'
}

const MachineStopTag: React.FC<MachineStopTagProps> = ({
  machine_stop,
  equipment,
  updateMethods,
  alignSelf,
  readOnly = false
}) => {

  const { i18n, CONSTANTS, fetchApi, showModal } = useGlobalContextState()
  const { MACHINE_STOP } = CONSTANTS

  const [_stopState, stopDispatch]    = useReducer(machineStopReducer, { stop: equipment.machine_stop, equipment: equipment, fetchApi, updateMethods })

  const machineReady     = () => stopDispatch({ type: MACHINE_ACTIONS.READY,   stop: equipment.machine_stop })
  const machineRestop    = () => stopDispatch({ type: MACHINE_ACTIONS.RESTOP,  stop: equipment.machine_stop })
  const machineRestarted = () => stopDispatch({ type: MACHINE_ACTIONS.RESTART, stop: equipment.machine_stop })

  const machineStopActions = useMemo(() => {
    const machineStopOptions = []

    if (readOnly) return machineStopOptions

    if (!!equipment.machine_stop && equipment.machine_stop.permissions.can_ready_machine_stop) machineStopOptions.push({
      icon:       <FontAwesomeIcon icon="wrench" />,
      color:      'var(--rep-warning)',
      background: 'var(--rep-warning-light)',
      content:    i18n.t('machine_stop.mark_as_ready'),
      click:      machineReady
    })
    if (!!equipment.machine_stop && equipment.machine_stop.permissions.can_end_machine_stop) machineStopOptions.push({
      icon:       <FontAwesomeIcon icon="plug-circle-check" />,
      color:      'var(--rep-success)',
      background: 'var(--rep-success-light)',
      content:    i18n.t('machine_stop.mark_as_restarted'),
      click:      machineRestarted
    })
    if (!!equipment.machine_stop && equipment.machine_stop.permissions.can_restop_machine_stop) machineStopOptions.push({
      icon:       <FontAwesomeIcon icon="wrench" />,
      color:      'var(--rep-danger)',
      background: 'var(--rep-danger-light)',
      content:    i18n.t('machine_stop.mark_as_stopped'),
      click:      machineRestop
    })
    if (!equipment.machine_stop) machineStopOptions.push({
      icon:       <FontAwesomeIcon icon="wrench" />,
      content:    i18n.t('machine_stop.mark_as_stopped'),
      color:      'var(--rep-danger)',
      background: 'var(--rep-danger-light)',
      separator:  true,
      click:      () => showModal({
        title:   i18n.t('machine_stop.actions.signal'),
        content: <MachineStopForm
          equipment     = {equipment}
          updateMethods = {updateMethods}
        />
      })
    })
    if (!!equipment.machine_stop && equipment.machine_stop.permissions.can_use_machine_stop) machineStopOptions.push({
      icon:    <FontAwesomeIcon icon="circle-stop" />,
      content: i18n.t('machine_stop.actions.see'),
      click:   () => showModal({
        title:   i18n.t('machine_stop.machine_stop'),
        content: <MachineStop equipment={equipment} stop={equipment.machine_stop} />
      })
    })
    if (equipment.permissions?.can_use_machine_stop) machineStopOptions.push({
      icon:    <FontAwesomeIcon icon="clock-rotate-left" />,
      content: 'View history',
      click:   () => window.open(`/amenities/${equipment.id}?detail=machine_stop`, '_blank')

    })
    return machineStopOptions
  }, [equipment, machine_stop])

  const machineStopTag = ({
    [MACHINE_STOP.STATUS.STOPPED]: {
      content:    i18n.t('machine_stop.status.stopped'),
      icon:       <FontAwesomeIcon icon="explosion" />,
      color:      'var(--rep-danger)',
      colorLight: 'var(--rep-danger-light)'
    },
    [MACHINE_STOP.STATUS.READY]: {
      content:    i18n.t('machine_stop.status.ready'),
      icon:       <FontAwesomeIcon icon="wrench" />,
      color:      'var(--rep-warning)',
      colorLight: 'var(--rep-warning-light)'
    },
    [MACHINE_STOP.STATUS.RESTARTED]: {
      content:    i18n.t('machine_stop.status.restarted'),
      icon:       <FontAwesomeIcon icon="plug-circle-check" />,
      color:      'var(--rep-success)',
      colorLight: 'var(--rep-success-light)'
    }
  })[machine_stop?.status] ?? {
    content:    i18n.t('machine_stop.status.working'),
    icon:       <FontAwesomeIcon icon="circle" fade/>,
    color:      'var(--rep-success)',
    colorLight: 'var(--rep-success-light)'
  }


  return(
    <Dropdown
      withArrow
      hideIfNoActions = {false}
      icon		        = {machineStopTag.icon}
      color           = {machineStopTag.color}
      background      = {machineStopTag.colorLight}
      hover           = {machineStopTag.colorLight}
      options         = {machineStopActions}
      alignSelf       = {alignSelf}
    >
      {machineStopTag?.content}
    </Dropdown>
  )
}

export default MachineStopTag
