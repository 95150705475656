import React, { useReducer } from 'react'

import Form from '@components/Form/Form'
import Input              from '@components/Form/Input'
import Select             from '@components/Form/Select'
import Textarea           from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import { MEASURE_ACTIONS, measureReducer } from '@reducers/measureReducer'

import IAmenity from '@interfaces/IAmenity.d'
import IRequestResponse from '@interfaces/IRequestResponse.d'

interface MeasureFormProps {
  equipment:     IAmenity,
  updateMethods: {
    addMeasurment: (data: IRequestResponse) => void
  }
}
const MeasureForm: React.FC<MeasureFormProps> = ({
  equipment,
  updateMethods
}) => {

  const { i18n, fetchApi, closeModal } = useGlobalContextState()

  const [_measureState, measureDispatch]   = useReducer(measureReducer, { fetchApi, updateMethods })

  const createCounter = (data, fieldset) => measureDispatch({
    type:      MEASURE_ACTIONS.ADD_MEASURE,
    amenity:   equipment,
    data,
    fieldset,
    callbacks: [closeModal]
  })

  return (
    <Form callback={createCounter}>
      <Select
        name             = 'measurment_type'
        defaultValue     = {[]}
        label            = {i18n.t('measurment_type.measurment_type')}
        searchUrl        = '/measurment_types/search'
        format           = {{ content: 'name', value: 'id', details: 'value_type' }}
        detailsLocaleKey = 'measurment.types'
        placeholder      = {i18n.t('user.users')}
        marginY          = 'M'
        search
        required
      />

      <Input
        type  = 'text'
        name  = 'name'
        label = {i18n.t('shared.name')}
      />

      <Textarea
        name    = "description"
        label   = {i18n.t('shared.description')}
        marginY = 'M'
      />
    </Form>
  )
}

export default MeasureForm
