import React from 'react'

import FileUploader from '@form/FileUploader'

import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'

interface PlanFormDocumentsProps {
  data?:    IMaintenancePlan
  setData?: React.Dispatch<React.SetStateAction<IMaintenancePlan>>
}

const PlanFormDocuments: React.FC<PlanFormDocumentsProps> = ({ data, setData }) => {

  const addDocument    = file => setData(prevData => ({ ...prevData, documents: [...prevData.documents, file] }))
  const removeDocument = file => setData(prevData => ({ ...prevData, documents: prevData.documents.filter(f => f.id !== file.id)}))

  return(
    <FileUploader
      files           = {data.documents}
      objectType      = 'maintenance-plan'
      objectId        = {null}
      closeModalAfter = {false}
      grid            = {false}
      updateMethods   = {{ addDocument, removeDocument }}
      multiple
      thumbnailDestroy
      destroyable
    />
  )
}

export default PlanFormDocuments
