import React from 'react'

import * as Style from './style'

import Button from '@components/Button'

import { IAlert } from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

const Alert: React.FC<IAlert> = () => {

  const { i18n, closeAlert, alertDisplay, alertContent, alertActions } = useGlobalContextState()

  return (
    <>
      <Style.AlertOverlay
        displayed = {alertDisplay}
        onClick   = {closeAlert}
      />

      <Style.Alert displayed={alertDisplay}>
        <Style.AlertContent>
          {alertContent}
        </Style.AlertContent>

        <Style.AlertActions>
          <Button
            click   = {closeAlert}
            border  = 'var(--rep-neutral)'
            color   = 'var(--rep-neutral-primary)'
          >
            {i18n.t('actions.cancel')}
          </Button>
          {alertActions.map((action: any, key: number) =>
            <Button
              key        = {key}
              icon       = {action.icon}
              click      = {action.click}
              background = {action.background}
              color      = {action.color}
            >
              {action.content}
            </Button>
          )}
        </Style.AlertActions>
      </Style.Alert>
    </>
  )
}

export default Alert
