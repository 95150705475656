export const buildFilterString = filtersObj => {
  for (const param in filtersObj) { /* You can get copy by spreading {...query} */
    if (
      filtersObj[param] === undefined       || /* In case of undefined assignment */
      filtersObj[param] === null            ||
      filtersObj[param] === false           ||
      typeof filtersObj[param] === 'object' ||
      filtersObj[param] === ''              ||
      filtersObj[param].length === 0
    ) {
      delete filtersObj[param]
    }
  }

  const params = new URLSearchParams(filtersObj)

  return params.toString()
}
