import styled, { keyframes } from 'styled-components'

const LoadingAnimation = keyframes`
  from  { opacity:  1 }
  to    { opacity: .5 }
`

const ErrorAnimation = keyframes`
  0%   { transform: translateX(0)    }
  25%  { transform: translateX(-10%) }
  50%  { transform: translateX(10%)  }
  75%  { transform: translateX(-5%)  }
  90%  { transform: translateX(5%)   }
  100% { transform: translateX(0)    }
`

export const Card = styled.div`
  background:                white;
  border-radius:             5px;
  margin-bottom:             8px;
  transition:                height .3s ease;
  text-align:                left;
  flex-grow:                 0 !important;
  animation-name:            ${props => props.animation === 'loading' && LoadingAnimation};
  animation-name:            ${props => props.animation === 'error'   && ErrorAnimation};
  animation-duration:        .3s;
  animation-iteration-count: 1;
  animation-fill-mode:       forwards;
`

export const TopContent = styled.div`
    border-top-left-radius:  5px;
    border-top-right-radius: 5px;
    border-top:              1px solid ${props => props.border};
    border-left:             1px solid ${props => props.border};
    border-right:            1px solid ${props => props.border};
    min-height:              5px;
  ${props => props.sticky && `
    position:       sticky;
    top:            -1px;
    z-index:        100;
    transition:     .3s ease;
    background:   white;
    padding-bottom: 4px;
    padding-bottom: 12px;
    border-top-left-radius: none;
    border-top-right-radius: none;
    border-bottom:  1px solid ${props.border};
  `}
`

export const CardHeader = styled.div`
  display:         flex;
  justify-content: space-between;
  flex-direction:  row-reverse;
  align-items:     center;
  padding:         8px 12px 0 12px;
  border-radius:   4px;
`

export const CardTitle = styled.div`
  padding: 0 8px;
  margin:  0;
  &:hover {
    cursor: ${props => props.togglable ? 'pointer' : 'default'};
  }
`

export const CardBody = styled.div`
  padding:      4px 12px;
  border-left:  1px solid ${props => props.border};
  border-right: 1px solid ${props => props.border};
`

export const CardFooter = styled.div`
  border:                     1px solid ${props => props.border ? props.border : 'transparent'};
  border-top:                 ${props => props.togglable ? `1px solid ${props.border}` : 'none'};
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
  min-height: 5px;
`

export const HiddenPart = styled.div`
  height:     ${props => props.visible ? '' : 0};
  transition: 1s ease;
`

export const ToggleIcon = styled.div`
  padding:         8px 0;
  display:         flex;
  justify-content: center;
  align-items:     center;
  color:           var(--rep-neutral-primary-middle);
  svg     { transition: .3s ease; }
  &:hover { cursor: pointer; }
`
