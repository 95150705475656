import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import Callout     from '@components/Callout'
import Dropdown    from '@components/Dropdown'
import PlanForm    from '../../Form/PlanForm'
import SubLine     from '../SubLine/SubLine'
import Tag         from '@components/Tag'
import WithTooltip from '@components/WithTooltip'

import PlanFormEquipments from '@pages/Maintenances/Form/PlanFormEquipments'

import * as Style from '../style'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import { IMainLine } from '@interfaces/ICalendar.d'

interface MainLineProps {
  line:       IMainLine
  isSubItem?: boolean
}

const MainLine: React.FC<MainLineProps> = ({
  line,
  isSubItem = false,
}) => {

  const { i18n, current_company, fetchApi, showModal, closeModal, showAlert, closeAlert, setInfoWindowProps } = useGlobalContextState()
  const {
    weekCount,
    currentWeek,
    isCurrentYear,
    filters,
    MAINTENANCE_CONSTANTS
  } = useMaintenancesContextState()

  const { VIEWS } = MAINTENANCE_CONSTANTS

  const [render,       setRender]       = useState(true)
  const [mainline,     setMainline]     = useState<IMainLine>(line)
  const [item,         setItem]         = useState(line.content.data)
  const [showDetails,  setShowDetails]  = useState(false)
  const [maintenances, setMaintenances] = useState([])
  const [pagination,   setPagination]   = useState({ page: 0, next: 1, last: null })
  const [loading,      setLoading]      = useState(false)

  useEffect(() => setItem(line.content.data),     [line])
  useEffect(() => setItem(mainline.content.data), [mainline])

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    // pagination,
    closeModal,
    setLoading,
    setPagination,
    VIEWS,
    setMainline,
    setSubLines: setMaintenances,
  })

  const fetchMainLine = ()   => dispatch({ type: MAINTENANCE_ACTIONS.FETCH_MAINLINE,  mainline: item })
  const fetchSubLines = () => dispatch({ type: MAINTENANCE_ACTIONS.FETCH_SUBLINES,  item, pagination })
  const updatePlan    = data => dispatch({
    type:      MAINTENANCE_ACTIONS.UPDATE_PLAN,
    data,
    callbacks: [
      () => fetchMainLine(),
      () => fetchSubLines(),
      () => setShowDetails(false)
    ]
  })
  const archivePlan = () => dispatch({
    type:      MAINTENANCE_ACTIONS.ARCHIVE_MAINTENANCE_PLAN,
    plan:      item,
    callbacks: [
      () => fetchMainLine(),
      () => fetchSubLines(),
      () => setShowDetails(false)
    ]
  })
  const unarchivePlan = () => dispatch({
    type:      MAINTENANCE_ACTIONS.UNARCHIVE_MAINTENANCE_PLAN,
    plan:      item,
    callbacks: [
      () => fetchMainLine(),
      () => fetchSubLines(),
      () => setShowDetails(false)
    ]
  })
  const updateEquipments = (data) => dispatch({
    type:       MAINTENANCE_ACTIONS.UPDATE_PLAN_EQUIPMENTS,
    plan:       item,
    equipments: data,
    callbacks:  [
      () => fetchMainLine(),
      () => fetchSubLines(),
    ]
  })
  const destroyMaintenancePlan = () => dispatch({
    type:      MAINTENANCE_ACTIONS.DESTROY_MAINTENANCE_PLAN,
    plan:      item,
    callbacks: [
      () => closeAlert(),
      () => setRender(false)
    ]
  })

  const maintenancePlanActions = useMemo(() => {
    const planOptions = []

    if (filters.view !== 'plans') return planOptions

    if (filters.view == 'plans' && item.permissions.can_update_maintenance_plan) planOptions.push({
      icon:      <FontAwesomeIcon icon="pen" />,
      color:     'var(--rep-primary)',
      content:   i18n.t('maintenance.actions.plan_edit'),
      separator: true,
      click:     () => showModal({
        title:   i18n.t('maintenance.actions.plan_edit', {name: item.name }),
        content: <PlanForm
          item       = {item}
          updatePlanProps = {updatePlan}
        />,
        asWizard: true
      })
    })
    if (filters.view == 'plans' && item.permissions.can_update_equipments) planOptions.push({
      icon:    <FontAwesomeIcon icon="code-pull-request" />,
      color:   'var(--rep-primary)',
      content: i18n.t('maintenance.actions.manage_equipments'),
      click:   () => showModal({
        title:   i18n.t('maintenance.actions.plan_edit', {name: item.name }),
        content: <PlanFormEquipments
          data     = {item}
          setData  = {setItem}
          callback = {updateEquipments}
          dateSelect
        />,
        asWizard: true
      }),
      separator: true
    })
    if (filters.view == 'plans' && item.permissions.can_archive_plan) planOptions.push({
      icon:    <FontAwesomeIcon icon="folder" />,
      color:   'var(--rep-warning)',
      content: i18n.t('maintenance.actions.archive_plan'),
      click:   archivePlan
    })
    if (filters.view == 'plans' && item.permissions.can_unarchive_plan) planOptions.push({
      icon:    <FontAwesomeIcon icon="folder-open" />,
      color:   'var(--rep-warning)',
      content: i18n.t('maintenance.actions.unarchive_plan'),
      click:   unarchivePlan
    })
    if (filters.view == 'plans' && item.permissions.can_destroy_plan) planOptions.push({
      icon:    <FontAwesomeIcon icon="trash-can" />,
      color:   'var(--rep-danger)',
      content: i18n.t('actions.destroy'),
      click:   () => showAlert(
        <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
          {i18n.t('maintenance.actions.delete_maintenance_plan')}
        </Callout>,
        [{
          content:    i18n.t('actions.delete'),
          color:      'white',
          background: 'var(--rep-danger)',
          icon:       <FontAwesomeIcon icon="trash-can" />,
          click:      destroyMaintenancePlan
        }]
      )
    })
    return planOptions
  }, [item, filters.view])

  const dropdownRef = useRef(null)
  const optionsRef  = useRef(null)

  const toggleDetails = event => {
    if (dropdownRef?.current?.contains(event.target) || optionsRef?.current?.contains(event.target)) return
    if (!showDetails) fetchSubLines()
    setShowDetails(!showDetails)
    if (!showDetails) return
  }

  if (!render) return null

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <Style.CalendarLine
        length     = {weekCount + 1}
        onClick    = {toggleDetails}
        background = {showDetails ? '#e4e9ee' : item.archived ? 'var(--rep-neutral-light)' : null}
        clickable
        noAlternateBackground
      >
        <Style.CalendarName
          hideShadow={showDetails}
          borderLeft={showDetails ? (isSubItem ? 8 : 4) : (isSubItem ? 4 : 0)}
          background={showDetails ? '#e4e9ee' : item.archived ? 'var(--rep-neutral-light)' : null}
        >
          {filters.view == VIEWS.PLANS
            ? item.legal
              ? <WithTooltip content={i18n.t('maintenance.legal_control')}>
                <FontAwesomeIcon icon="medal" color="var(--rep-warning)"/>
              </WithTooltip>
              : <div></div>
            : item.class_plural === 'amenities'
              ? <FontAwesomeIcon icon="gears" />
              : <FontAwesomeIcon icon="location-dot" />
          }
          <Style.CalendarControlName>
            {item.archived
              ? <WithTooltip content={i18n.t('maintenance.plan_archived')}>
                <FontAwesomeIcon icon="folder" color="var(--rep-warning)" style={{ marginRight: '4px' }}/>
              </WithTooltip>
              : item.late &&
              <WithTooltip content={i18n.t('shared.late')}>
                <FontAwesomeIcon icon="triangle-exclamation" color="var(--rep-danger)" style={{ marginRight: '4px' }} />
              </WithTooltip>
            }

            <div className="mainline--name">
              <WithTooltip title={filters.view == 'plans' && item.name} content={filters.view == 'plans' ? item.sentence : item.path_string}>
                {item.name}
              </WithTooltip>
            </div>
          </Style.CalendarControlName>
          <div>
            {filters.view == 'plans'
              ? <Dropdown
                icon              = {<FontAwesomeIcon icon="cog" />}
                options           = {maintenancePlanActions}
                forwardRef        = {dropdownRef}
                forwardOptionsRef = {optionsRef}
              />
              : <Tag
                tooltipContent = {i18n.t('maintenance.calendar.visualize_asset')}
                icon           = {<FontAwesomeIcon icon="window-restore" />}
                color          = 'var(--rep-primary)'
                click          = {() => openDetails(item, item.class_plural)}
              />
            }
          </div>
          <FontAwesomeIcon icon={showDetails ? 'angles-down' : 'grip-lines'} color="var(--rep-primary)" />
        </Style.CalendarName>

        {Array.from({ length: weekCount }, (_x, i) =>
          <Style.CalendarBox key={i} currentWeek={(isCurrentYear && ((i + 1) === currentWeek))}>
            {mainline.content.weeks_count[i + 1] &&
              <div style={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
                <WithTooltip content={i18n.t('maintenance.calendar.for_week', {count: mainline.content.weeks_count[i + 1].total })}>
                  <FontAwesomeIcon
                    icon  = "circle"
                    color = {
                      mainline.content.weeks_count[i + 1].late
                        ? 'var(--rep-danger)'
                        : mainline.content.weeks_count[i + 1].planned
                          ? 'var(--rep-warning)'
                          : mainline.content.weeks_count[i + 1].assigned
                            ? 'var(--rep-accent)'
                            : mainline.content.weeks_count[i + 1].done
                              ? 'var(--rep-primary)'
                              : !!mainline.content.weeks_count[i + 1].closed &&
                         'var(--rep-success)'
                    }
                    size  = "xs"
                  />
                </WithTooltip>
              </div>
            }
          </Style.CalendarBox>
        )}
      </Style.CalendarLine>

      {showDetails &&
        <>
          {maintenances.map(line =>
            <SubLine
              key           = {line.data.id}
              maintenance   = {line}
              mainline      = {item}
              setMainline   = {setMainline}
              isSubItem     = {isSubItem}
              fetchSubLines = {fetchSubLines}
            />
          )}

          {loading
            ? <Style.Loading length={weekCount + 1} />
            : pagination.page < pagination.last &&
              <Style.CalendarLoadingContainer length={weekCount + 1}>
                <Style.CalendarEmpty length={weekCount + 1} onClick={fetchSubLines} size='small' >
                  <FontAwesomeIcon icon="arrow-down" />
                  {i18n.t('shared.load_more')}
                </Style.CalendarEmpty>
              </Style.CalendarLoadingContainer>
          }
        </>
      }
    </>
  )
}

export default MainLine
