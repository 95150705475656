import React, { useReducer, useState } from 'react'

import Callout    from '@components/Callout'
import Checkbox   from '@form/Checkbox'
import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'
import Input      from '@form/Input'
import TaskBox    from '@components/Task/TaskBox'
import Textarea   from '@components/Form/Textarea'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import * as FormStyle from '@form/FormStyles'

import { ISubLine } from '@interfaces/ICalendar.d'

interface PlanifyFormProps {
  maintenance:    ISubLine
  date:           string
  from:           string
  fetchMainLine:  () => void
  fetchSubLine:   () => void
  cancelPrevious: boolean
}

const PlanifyForm: React.FC<PlanifyFormProps> = ({
  maintenance,
  date,
  from,
  fetchMainLine,
  fetchSubLine,
  cancelPrevious = false
}) => {

  const { i18n, current_company, fetchApi, closeModal, setInfoWindowProps } = useGlobalContextState()
  const { filters, MAINTENANCE_CONSTANTS }        = useMaintenancesContextState()

  const { VIEWS }    = MAINTENANCE_CONSTANTS

  const [maintenanceData, setMaintenanceData] = useState(maintenance?.data)
  const [cancelCheck, setCancelCheck]         = useState(false)

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    closeModal,
    VIEWS,
  })

  const selectUsers = (task, users) => {
    console.log(users)
    const taskData = {...task, users: users }

    let tasks = maintenanceData.tasks
    tasks = tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  const selectProvider = (task, provider) => {
    const taskData = {...task, provider: provider, users: [] }

    let tasks = maintenanceData.tasks
    tasks = tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  const planifyMaintenance = (data, fieldset) => {
    dispatch({
      type:      MAINTENANCE_ACTIONS.PLANIFY,
      maintenance,
      fieldset,
      data:      { ...data, auto_assign: false },
      callbacks: [
        () => fetchSubLine(),
        () => fetchMainLine(),
        response => openDetails({ id: response.response.id }, 'reports'),
        closeModal
      ],
    })
  }

  return (
    <Form
      callback   = {planifyMaintenance}
      submitText = {i18n.t('maintenance.actions.plan_maintenance')}
    >
      <Input
        type         = "hidden"
        name         = "original_date"
        defaultValue = {date}
      />
      <Input
        type         = "hidden"
        name         = "maintenance_id"
        defaultValue = {maintenance?.data?.id}
      />
      <Input
        type         = "hidden"
        name         = "exclusion"
        defaultValue = {from}
      />

      <DatePicker
        label = {i18n.t('report.expected_date')}
        name  = 'date'
        date  = {date}
        showTime
        required
      />

      <FormStyle.Label>
        {i18n.t('maintenance.default_assignment')}
      </FormStyle.Label>

      {maintenanceData.tasks.length
        ? maintenanceData.tasks.map((task, key) =>
          <TaskBox
            key                = {`${task.name}-${key}`}
            task               = {task}
            selectUsers        = {user => selectUsers(task, user)}
            selectProvider     = {prov => selectProvider(task, prov)}
            defaultNotify      = {task.notify}
            // removeUser     = {(task, user) => removeUser(task, user)}
          />)
        : <Callout
          marginY    = 'M'
          background = 'var(--rep-warning-light)'
          color      = 'var(--rep-warning)'
          border     = 'var(--rep-warning)'
        >
          {i18n.t('maintenance.no_tasks')}
        </Callout>
      }

      {/* <Checkbox
        name         = 'notify'
        label        = {i18n.t('maintenance.form.notify_associated_generation')}
        defaultValue = {true}
        marginY      = 'M'
      /> */}

      {cancelPrevious &&
        <>
          <Checkbox
            name         = 'cancel_previous'
            label        = {i18n.t('maintenance.form.cancel_previous_tickets')}
            callback     = {check => setCancelCheck(check.isCheck)}
            defaultValue = {cancelCheck}
            marginY      = 'M'
          />
          {cancelCheck &&
            <Textarea
              label = {i18n.t('maintenance.form.cancel_reason')}
              name  = 'cancel_comment'
              required
            />
          }
        </>
      }
    </Form>
  )
}

export default PlanifyForm
