import styled from 'styled-components'

export const Overlay = styled.div`
  position:         fixed;
  height:           100vh;
  width:            100vw;
  z-index:          200;
  background:      #64788d66;
  display:          flex;
  justify-content:  flex-start;
  align-items:      center;
  flex-direction:   column;
  padding: 80px 0;
`

export const Box = styled.div`
  background:   white;
  color:          var(--rep-neutral-primary);
  border-radius:  5px;
  ${props => props.withResults ? `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0px;
  ` : ''}
  left:           0;
  width:          70%;
  padding:        24px;
  display:        flex;
  flex-direction: column;
  align-items:    ${props => props.isDesktop ? 'flex-start' : 'flex-end'};
  position:       relative;
`

export const CloseIcon = styled.div`
  position:  absolute;
  right:     16px;
  top:       16px;
  font-size: 1rem;
  `

export const GlassIcon = styled.div`
  position:  absolute;
  left:      16px;
  top:       calc(45%  - .6rem);
  font-size: 1.2rem;
`

export const Input = styled.input`
  font-size:    1.5rem;
  width:        100%;
  border:       none;
  padding-left: 1rem;
  &:focus { outline: none }
`

export const ResultBox = styled.div`
  width: 70%;
  max-height: 50vh;
  overflow-y: scroll;
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
`

export const Loader = styled.div`
  display:         flex;
  justify-content: center;
  padding:         16px;
  align-items:     center;
  background:      white;
  color:           var(--rep-neutral-primary);
  font-size:       1rem;
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
`
