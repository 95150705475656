import React, { useEffect, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button     from '@components/Button'
import Checkbox   from '@components/Form/Checkbox'
import DatePicker from '@form/DatePicker'
import OrderTag   from '@components/OrderTag'
import ReportForm from '@components/Report/Form'
import Search     from '@components/Search'
import Select     from '@form/Select'
import SelectTree from '@form/SelectTree'
import Switch     from '@components/Switch'
import Tabs       from '@components/Tabs'
import Tag        from '@components/Tag'

import ReportList from './ReportColumn'

import { buildFilterString } from '@utils/filterString'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

interface ReportIndexProps {
  new_reports,
  done_reports,
  ongoing_reports,
}

const ReportIndex: React.FC<ReportIndexProps> = ({
  new_reports,
  done_reports,
  ongoing_reports,
  tabs,
}) => {
  const {
    i18n,
    setInfoWindowProps,
    table_display,
    fetchApi,
    current_company,
    current_user,
    CONSTANTS,
    showModal
  } = useGlobalContextState()

  const VIEW = {
    NEW:      'new',
    ONGOING:  'ongoing',
    TO_CHECK: 'done'
  }

  const [currentTable, setCurrentTable] = useState(table_display)

  const changeTable = table => {
    console.log(table.filters)
    const parsedFilters = {}
    const oldFilters = {}
    Object.entries(filters).forEach((key, val) => oldFilters[key] = null)
    table.filters.forEach(filter => {
      console.log(filter)
      parsedFilters[Object.keys(filter)[0]] = filter[Object.keys(filter)[0]].value
    })
    setCurrentTable(table)
    console.log({
      ...oldFilters,
      ...parsedFilters
    })
    setFilters({
      // ...oldFilters,
      ...parsedFilters
    })
  }

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), [])

  const [filters, setFilters] = useState({
    responsible_id:       searchParams.get('responsible_id')       || null,
    responsible_name:     searchParams.get('responsible_name')     || null,
    requester_id:         searchParams.get('requester_id')         || null,
    requester_name:       searchParams.get('requester_name')       || null,
    created_by_id:        searchParams.get('created_by_id')        || null,
    created_by_name:      searchParams.get('created_by_name')      || null,
    doer_id:              searchParams.get('doer_id')              || null,
    doer_name:            searchParams.get('doer_name')            || null,
    unit:                 searchParams.get('unit')                 || null,
    unit_name:            searchParams.get('unit_name')            || null,
    expertise:            searchParams.get('expertise')            || null,
    expertise_name:       searchParams.get('expertise_name')       || null,
    anomaly:              searchParams.get('anomaly')              || null,
    anomaly_name:         searchParams.get('anomaly_name')         || null,
    amenity_type_id:      searchParams.get('amenity_type_id')      || null,
    amenity_type_name:    searchParams.get('amenity_type_name')    || null,
    amenity_id:           searchParams.get('amenity_id')           || null,
    amenity_name:         searchParams.get('amenity_name')         || null,
    report_type:          searchParams.get('report_type')          || null,
    report_type_name:     searchParams.get('report_type_name')     || null,
    report_priority:      searchParams.get('report_priority')      || null,
    report_priority_name: searchParams.get('report_priority_name') || null,
    status:               searchParams.get('status')               || null,
    late:                 searchParams.get('late')                 || null,
    maintenance:          searchParams.get('maintenance') === 'true'          || null,
    deadline:             searchParams.get('deadline')             || null,
    expected_date:        searchParams.get('expected_date')        || null,

    info_type: searchParams.get('info_type')      || null,
    info_id:   searchParams.get('info_id')        || null,

    order:     current_user.preferences?.reports?.index?.order?.value     || searchParams.get('order') || 'expected_date',
    direction: current_user.preferences?.reports?.index?.order?.direction || searchParams.get('direction') || 'ASC',
  })

  useEffect(() => {
    const resourceType = searchParams.get('info_type')
    const id           = searchParams.get('info_id')

    if (current_company.beta_access && resourceType && id && id !== 'null') setInfoWindowProps({ id, resourceType })
  }, [])

  const [view,         setView]         = useState(VIEW.NEW)
  const [showFilter,   setShowFilter]   = useState(false)
  const [selectedZone, setSelectedZone] = useState({ id: filters.unit, name: filters.unit_name })

  const filterUrl   = useMemo(() => buildFilterString(filters), [filters])
  const filterCount = useMemo(() => {
    const filtersCopy = {... filters}
    const excludedKeys = [
      'order',
      'direction',
      'responsible_id',
      'requester_id',
      'created_by_id',
      'doer_id',
      'unit',
      'expertise',
      'anomaly',
      'amenity_type_id',
      'amenity_id',
      'report_type',
      'report_priority',
      'info_type',
      'info_id'
    ]
    return Object.keys(
      Object.fromEntries(
        Object.entries(filtersCopy)
          .filter(([k, v]) => !excludedKeys.includes(k) && !!v)
      )
    ).length
  }, [filters])

  const setSearchValue       = value       => setFilters(oldFilters => ({...oldFilters, search: value }))
  const selectResponsible    = responsible => {
    setFilters(oldFilters => ({...oldFilters, responsible_id: responsible?.object?.id, responsible_name: responsible?.object?.name }))
    updateDefaultFilter('responsible', [responsible?.object?.id?.toString()])
  }
  const selectRequester      = requester   => {
    setFilters(oldFilters => ({...oldFilters, requester_id: requester?.object?.id,   requester_name: requester?.object?.name }))
    updateDefaultFilter('requester', [requester?.object?.id?.toString()])
  }
  const selectCreator        = creator     => {
    setFilters(oldFilters => ({...oldFilters, created_by_id: creator?.object?.id,     created_by_name: creator?.object?.name }))
    updateDefaultFilter('created_by', [creator?.object?.id?.toString()])
  }
  const selectDoer           = doer        => {
    setFilters(oldFilters => ({...oldFilters, doer_id: doer?.object?.id,        doer_name: doer?.object?.name }))
    updateDefaultFilter('performer', [doer?.object?.id?.toString()])
  }
  const selectExpertise      = expertise   => {
    setFilters(oldFilters => ({...oldFilters, expertise: expertise?.object?.id, expertise_name: expertise?.object?.name }))
    updateDefaultFilter('expertise', [expertise?.object?.id?.toString()])
  }
  const selectAnomaly        = anomaly     => {
    setFilters(oldFilters => ({...oldFilters, anomaly: anomaly?.object?.id, anomaly_name: anomaly?.object?.name }))
    updateDefaultFilter('anomaly', [anomaly?.object?.id?.toString()])
  }
  const selectAmenityType    = type        => {
    setFilters(oldFilters => ({...oldFilters, amenity_type_id: type?.object?.id, amenity_type_name: type?.object?.name }))
    updateDefaultFilter('amenity_type', [type?.object?.id?.toString()])
  }
  const selectAmenity        = amenity     => {
    setFilters(oldFilters => ({...oldFilters, amenity_id: amenity?.object?.id, amenity_name: amenity?.object?.name }))
    updateDefaultFilter('amenity', [amenity?.object?.id?.toString()])
  }
  const selectReportType     = type        => {
    setFilters(oldFilters => ({...oldFilters, report_type: type?.object?.id, report_type_name: type?.object?.name }) )
    updateDefaultFilter('report_type', [type?.object?.id?.toString()])
  }
  const selectReportPriority = priority    => {
    setFilters(oldFilters => ({...oldFilters, report_priority: priority?.object?.id, report_priority_name: priority?.object?.name }))
    updateDefaultFilter('priority', [priority?.object?.id?.toString()])
  }
  const selectStatus         = (statuses = []) => {
    setFilters(oldFilters => ({...oldFilters, status: statuses.map(s => s && s.value).join(',') }))
    updateDefaultFilter('status', statuses.map(s => s && s.value))
  }
  const selectLate           = late        => {
    setFilters(oldFilters => ({...oldFilters, late: late.isCheck }))
    updateDefaultFilter('late', late.isCheck)
  }
  const selectMaintenance    = maintenance => {
    setFilters(oldFilters => ({...oldFilters, maintenance: maintenance.isCheck }))
    updateDefaultFilter('maintenance', maintenance.isCheck)
  }
  const selectOrder          = order       => setFilters(oldFilters => ({...oldFilters, order: order?.direction !== 'NULL' && order?.value, direction: order?.direction !== 'NULL' && order?.direction }) )
  const selectDeadline       = deadline    => {
    setFilters(oldFilters => ({...oldFilters, deadline: deadline?.filter(e => e).map(d => d.toISOString())
      .join(' - ') }))
    updateDefaultFilter('deadline', deadline?.filter(e => e).map(d => d.toISOString())
      .join(' - '))
  }
  const selectExpected       = expected    => {
    setFilters(oldFilters => ({...oldFilters, expected_date: expected?.filter(e => e).map(d => d.toISOString())
      .join(' - ') }))
    updateDefaultFilter('expected_date', expected?.filter(e => e).map(d => d.toISOString())
      .join(' - '))
  }
  const selectUnit           = unit        => {
    setSelectedZone(unit)
    setFilters(oldFilters => ({...oldFilters, unit: unit?.id, unit_name: unit?.name }))
    updateDefaultFilter('unit', [unit?.object?.id?.toString()])
  }

  useEffect (() => {
    fetchApi({
      url:    'users/update_preferences',
      method: 'PATCH',
      body:   {
        preferences: {
          reports: {
            index: {
              order: { value: filters?.order?.value, direction: filters?.order?.direction }
            }
          }
        }
      }
    })
  }, [filters.order])

  const updateDefaultFilter = (key, value) => {
    fetchApi({
      url:    '/table_displays/update_default_filters',
      method: 'PATCH',
      body:   { table_name: currentTable.table_name, filter_column: key, filter_value: value }
    })
  }

  const userFilter = useMemo(() => {
    return current_company.reports_limited_to_expertise
      ? [{
        id:      'expertise',
        name:    filters.expertise_name,
        filters: { expertise: filters.expertise, role: 'manager,local_manager' }
      }]
      : [{
        id:      'role',
        name:    'Role',
        filters: { role: 'manager,local_manager' }
      }]
  }, [filters.expertise])

  const statusOptions = useMemo(() =>
    CONSTANTS.REPORT.STATUSES.map(status => ({ value: status, text: i18n.t(`report.status.${status}`)}))
  , [])

  const switchOptions = useMemo(() => {
    const options = [
      { content: i18n.t('report.status.new'),     icon: <FontAwesomeIcon icon="star" />,       value: VIEW.NEW },
      { content: i18n.t('report.status.ongoing'), icon: <FontAwesomeIcon icon="stopwatch" />,  value: VIEW.ONGOING },
      { content: i18n.t('report.status.done'),    icon: <FontAwesomeIcon icon="user-check" />, value: VIEW.TO_CHECK },
    ]
    return options
  }, [])

  // const reorderColumns = (orderState) => {
  //   setOrder({ value: orderState.value, direction: orderState.direction })
  // }

  const [isDesktop, setIsDesktop] = useState(false)

  const childrenContainer = useRef(null)

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.target.clientWidth > 770) { setIsDesktop(true)  }
      else                                { setIsDesktop(false) }
    }
  })

  useEffect(() => { resizeObserver.observe(childrenContainer.current) }, [])

  return (
    <Style.ReportListLayout
      isDesktop = {isDesktop}
      filters   = {filters}
      ref       = {childrenContainer}
    >
      <Style.HeaderStyles isDesktop={isDesktop}>
        {/* <Tabs
          current        = {currentTable}
          options        = {tabs}
          setTabSelected = {changeTable}
        /> */}
        <Style.HeaderContent>
          <Style.HeaderFiltersAndOrdering>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button
                icon   = {<FontAwesomeIcon icon="list" />}
                click  = {() => window.location = '/to_do_items'}
                border = 'var(--rep-primary-light)'
                color  = 'var(--rep-primary)'
                hover  = 'var(--rep-primary-light)'
              >
                {i18n.t('todo.task_list')}
              </Button>
              <Button
                icon   = {<FontAwesomeIcon icon="receipt" />}
                click  = {() => window.open('/reports/full_index')}
                border = 'var(--rep-primary-light)'
                color  = 'var(--rep-primary)'
                hover  = 'var(--rep-primary-light)'
              >
                {i18n.t('report.full_list')}
              </Button>
              <Button
                icon   = {<FontAwesomeIcon icon="plus" />}
                border = 'var(--rep-primary-light)'
                color  = 'var(--rep-primary)'
                hover  = 'var(--rep-primary-light)'
                click  = {() => showModal({
                  title:   'Créer un ticket',
                  content: <ReportForm updateMethods={{}} />
                })}
              >
                {i18n.t('report.actions.create_report')}
              </Button>
            </div>
            <Search callback={setSearchValue} />
          </Style.HeaderFiltersAndOrdering>
          <Style.HeaderFiltersAndOrdering
            isDesktop = {isDesktop}
          >
            <Button
              icon       = {<FontAwesomeIcon icon={['fas', `wand-magic${filterCount ? '-sparkles' : ''}`]} />}
              background = {filterCount ? 'var(--rep-primary-light)' : null}
              border     = 'var(--rep-primary)'
              click      = {() => setShowFilter(!showFilter)}
            >
              {i18n.t('actions.filter')}
              {!!filterCount &&
                <Tag
                  color="white"
                  background="var(--rep-danger)"
                >
                  {filterCount}
                </Tag>
              }
            </Button>

            <OrderTag
              click        = {orderState => selectOrder({ value: orderState.value, direction: orderState.direction })}
              value        = 'deadline'
              active       = {filters?.order === 'deadline'}
              direction    = {filters?.direction || 'NULL'}
            >
              {i18n.t('report.deadline')}
            </OrderTag>
            <OrderTag
              click        = {orderState => selectOrder({ value: orderState.value, direction: orderState.direction })}
              value        = 'created_at'
              active       = {filters?.order === 'created_at'}
              direction    = {filters?.direction || 'NULL'}
            >
              {i18n.t('shared.created_at')}
            </OrderTag>
          </Style.HeaderFiltersAndOrdering>
        </Style.HeaderContent>
        {showFilter &&
          <Style.FilterList
            toggled   = {showFilter}
            isDesktop = {isDesktop}
          >
            <DatePicker
              label    = {i18n.t('report.deadline')}
              mode     = 'range'
              name     = 'deadline'
              date     = {filters?.deadline?.split(' - ')}
              showTime = {false}
              callback = {selectDeadline}
              allowClear
            />
            <DatePicker
              label    = {i18n.t('report.expected_date')}
              mode     = 'range'
              name     = 'expected_date'
              date     = {filters?.expected_date?.split(' - ')}
              showTime = {false}
              callback = {selectExpected}
              allowClear
            />
            <div></div>
            <Select
              name             = 'responsible'
              label            = {i18n.t('shared.responsible')}
              withEmpty
              search
              searchUrl        = '/users/search'
              filters          = {userFilter}
              defaultFilter    = 'expertise'
              defaultValue     = {filters.responsible_id ? [{ name: filters.responsible_name, id: filters.responsible_id }] : []}
              callback         = {selectResponsible}
              emptyCallback    = {selectResponsible}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name             = 'requester'
              label            = {i18n.t('shared.requested_by')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              defaultValue     = {[{ name: filters.requester_name, id: filters.requester_id }]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              callback         = {selectRequester}
              emptyCallback    = {selectRequester}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name             = 'created_by'
              label            = {i18n.t('shared.created_by')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              defaultValue     = {[{ name: filters.created_by_name, id: filters.created_by_id }]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              callback         = {selectCreator}
              emptyCallback    = {selectCreator}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name             = 'doer_id'
              label            = {i18n.t('report.performer')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              defaultValue     = {[{ name: filters.doer_name, id: filters.doer_id }]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              callback         = {selectDoer}
              emptyCallback    = {selectDoer}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name          = 'expertise'
              label         = {i18n.t('expertise.expertise')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/expertises/search'
              defaultValue  = {[{ id: filters.expertise, name: filters.expertise_name }]}
              callback      = {selectExpertise}
              emptyCallback = {selectExpertise}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'anomaly'
              label         = {i18n.t('anomaly.anomaly')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/anomalies/search'
              defaultValue  = {[{ id: filters.anomaly, name: filters.anomaly_name }]}
              callback      = {selectAnomaly}
              emptyCallback = {selectAnomaly}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'amenity_type_id'
              label         = {i18n.t('amenity.amenity_type_long')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/amenity_types/search'
              defaultValue  = {[{ id: filters.amenity_type_id, name: filters.amenity_type_name }]}
              callback      = {selectAmenityType}
              emptyCallback = {selectAmenityType}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'report_type'
              label         = {i18n.t('report.report_type')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/report_types/search'
              defaultValue  = {[{ id: filters.report_type, name: filters.report_type_name }]}
              callback      = {selectReportType}
              emptyCallback = {selectReportType}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'report_priority'
              label         = {i18n.t('report_priority.report_priority')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/report_priorities/search'
              defaultValue  = {[{ id: filters.report_priority, name: filters.report_priority_name }]}
              callback      = {selectReportPriority}
              emptyCallback = {selectReportPriority}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'status'
              label         = {i18n.t('report.report_status')}
              withEmpty
              options       = {statusOptions}
              defaultValue  = {filters.status ? filters.status?.split(',').map(s => ({ value: s, text: i18n.t(`report.status.${s}`)})) : []}
              callback      = {selectStatus}
              emptyCallback = {selectStatus}
              format        = {{ content: 'text', value: 'value' }}
              multiselect
              marginY       = 'S'
            />
            <SelectTree
              name          = 'unit'
              label         = {i18n.t('unit.unit')}
              selected      = {selectedZone}
              withEmpty     = {true}
              initUrl       = '/units'
              searchUrl     = '/units/rep_search'
              callback      = {selectUnit}
              emptyCallback = {selectUnit}
              filters       = {[{
                id:      'root',
                name:    'Parent',
                filters: { roots: 'true' }
              }]}
              format         = {{ content: 'name', value: 'id' }}
              marginY        = 'S'
              subFilterName  = 'for_unit'
              subElementName = {i18n.t('unit.subunit')}
            />
            <Select
              name          = 'amenity_id'
              label         = {i18n.t('amenity.amenity')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/amenities/rep_search'
              defaultValue  = {filters.amenity_id ? [{ id: filters.amenity_id, name: filters.amenity_name }] : []}
              callback      = {selectAmenity}
              emptyCallback = {selectAmenity}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
              filters       = {filters.unit ?
                [
                  {
                    id:      'direct_children',
                    name:    i18n.t('amenity.exclude_tree'),
                    filters: {
                      for_unit:        filters.unit || '',
                      amenity_type_id: filters.amenity_type_id || ''
                    }
                  },
                  {
                    id:      'all',
                    name:    i18n.t('amenity.include_tree'),
                    filters: {
                      root_unit_id:    filters.unit || '',
                      amenity_type_id: filters.amenity_type_id || ''
                    }
                  }
                ]
                : [
                  {
                    id:      'direct_children',
                    name:    i18n.t('amenity.exclude_tree'),
                    filters: {
                      for_unit:        filters.unit || '',
                      amenity_type_id: filters.amenity_type_id || ''
                    }
                  }
                ]}
              defaultFilter  = 'direct_children'
            />
            <Checkbox
              name         = 'late'
              label        = {i18n.t('maintenance.late')}
              defaultValue = {filters.late}
              callback     = {selectLate}
            />
            <Checkbox
              name         = 'maintenance'
              label        = {i18n.t('maintenance.actions.include_maintenances')}
              defaultValue = {filters.maintenance}
              callback     = {selectMaintenance}
            />
          </Style.FilterList>
        }
      </Style.HeaderStyles>
      {!isDesktop &&
        <Switch
          name     = 'report-view'
          options  = {switchOptions}
          selected = {view}
          callback = {setView}
          marginY  = 'S'
        />
      }
      {(isDesktop || view === VIEW.NEW) &&
        <Style.Column>
          <ReportList listType={VIEW.NEW} filterUrl={filterUrl} reports={new_reports} />
        </Style.Column>
      }
      {(isDesktop || view === VIEW.ONGOING) &&
        <Style.Column>
          <ReportList listType={VIEW.ONGOING} filterUrl={filterUrl} reports={ongoing_reports} />
        </Style.Column>
      }
      {(isDesktop || view === VIEW.TO_CHECK) &&
        <Style.Column>
          <ReportList listType={VIEW.TO_CHECK} filterUrl={filterUrl} reports={done_reports} />
        </Style.Column>
      }
    </Style.ReportListLayout>
  )
}

export default ReportIndex
