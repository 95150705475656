import React, { useCallback, useMemo, useReducer } from 'react'
import { FontAwesomeIcon }             from '@fortawesome/react-fontawesome'

import Callout     from '@components/Callout'
import WithTooltip from '@components/WithTooltip/WithTooltip'

import FileForm    from './FileForm'

import { useGlobalContextState } from '@context/GlobalContext'

import ThumbnailProps from './types.d'

import * as Style from './style'

import { matchImage, matchPdf } from '@utils/regex'

import { DOCUMENT_ACTIONS, documentReducer } from '@reducers/index'
import Tag from '@components/Tag'

/**
 * @see Interface {@link ThumbnailProps}
 * @description File Thumbnail component
 * @example
 * <Thumbnail file={file} />
 */
const Thumbnail: React.FC<ThumbnailProps> = ({
  file,
  updateMethods,
  color,
  thumbnailDestroy,
  size            = '100px',
  wrapText        = true,
  modaleDetails   = false,
  showFileName    = true,
  destroyable     = false,
  closeModalAfter = false,
  certification   = false,
}) => {

  const {
    showModal,
    closeModal,
    fetchApi,
    showAlert,
    closeAlert,
    i18n
  } = useGlobalContextState()

  const [_state, dispatch] = useReducer(documentReducer, { file, fetchApi, updateMethods, closeModal })

  const updateDocument = (data, fieldset) => dispatch({ type: DOCUMENT_ACTIONS.UPDATE, file, data, fieldset, closeModal: closeModalAfter })
  const removeDocument = ()               => dispatch({ type: DOCUMENT_ACTIONS.DESTROY, file, closeModal: closeModalAfter, callbacks: [closeAlert] })

  const isPdf   = useMemo(() => matchPdf(file.doctype), [])
  const isImage = useMemo(() => matchImage(file.doctype), [])

  // const [errorCount, setErrorCount]           = useState(0)

  const onImageError = (e, file) => {
    // setErrorCount(errorCount => errorCount + 1)
    // console.log(errorCount)
    e.target.src = 'https://user-images.githubusercontent.com/28399150/84750757-fdcc6f00-afbb-11ea-908a-1074b026b06b.png'
    // retryFile(e)
    // if (errorCount <= 5) {
    //   setTimeout(() => {
    //     e.target.src = file.thumb_url
    //   }, 3000)
    // }
  }

  const openFile  = useCallback(() => window.open(file.url, '_blank', 'noopener,noreferrer'), [])
  const openModal = useCallback(() => showModal({
    title:   i18n.t('document.attached'),
    content: <FileForm
      file               = {file}
      certification      = {certification}
      destroyable        = {destroyable}
      removeDocument     = {removeDocument}
      updateDocument     = {updateDocument}
    />
  }), [file])

  const FileDisplay = useMemo(() => {
    if (file.preview) return <img src={file.preview} onError = {(e) => onImageError(e, file)}/>
    if (isPdf) {
      return <Style.FileIcon color={color}>
        <FontAwesomeIcon icon="file-pdf" />
      </Style.FileIcon>
    } else if(isImage) {
      return <img src={file.thumb_url} onError = {(e) => onImageError(e, file)}/>
    } else {
      return <Style.FileIcon color={color}>
        <FontAwesomeIcon icon="file" />
      </Style.FileIcon>
    }
  }, [])

  return (
    <Style.FileContainer size = {size}>
      {!!destroyable && thumbnailDestroy &&
        <Style.RemoveFileStyle
          onClick={() => showAlert(
            <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
              {i18n.t('actions.delete_file')}
            </Callout>,
            [{
              content:    i18n.t('actions.delete'),
              color:      'white',
              background: 'var(--rep-danger)',
              icon:       <FontAwesomeIcon icon="trash-can" />,
              click:      removeDocument
            }]
          )
          }
          thumbnailDestroy={thumbnailDestroy}
        >
          <FontAwesomeIcon icon="times" />
        </Style.RemoveFileStyle>
      }
      <Style.FileStyles onClick = {modaleDetails ? openModal : openFile}>
        {FileDisplay}
      </Style.FileStyles>

      {showFileName &&
        <Style.FileName wrapText={wrapText}>
          {file.certification &&
            <WithTooltip content="certification">
              <FontAwesomeIcon icon="medal" />
            </WithTooltip>
          }
          {file.name}
        </Style.FileName>
      }

      <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
        {file.labels.map(label =>
          <Tag
            key            = {label.id}
            background     = {`${label.color}20`}
            color          = {label.color}
            tooltipContent = {label.name}
          >
            {label.name}
          </Tag>
        )}
      </div>
    </Style.FileContainer>
  )
}

export default Thumbnail
