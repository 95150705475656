import React, { useState } from 'react'

import List    from './List'
import Wrapper from '../../Wrapper'

import { ICompany, ITask, IUser } from '@interfaces/index'


interface TasksListProps {
  server_tasks:    ITask[],
  ongoing_task:    ITask,
  late_count:      number,
  current_user:    IUser,
  current_company: ICompany
  token:           string,
  locale:          string
}

const ReportMain: React.FC<TasksListProps> = ({ server_tasks, ongoing_task, late_count, current_user, current_company, token, locale }) => {

  const [data, setData] = useState(server_tasks)

  return (
    <Wrapper
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      locale          = {locale}
    >
      <List
        data              = {data}
        setData           = {setData}
        serverOngoingTask = {ongoing_task}
        serverLateCount   = {late_count}
      />
    </Wrapper>
  )
}

export default ReportMain
