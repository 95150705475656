import React from 'react'

import Button           from '@components/Button'
import MeasureDetails   from '../Details'
import MeasureValueForm from './Form'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from '../style'

import IMeasure from '@interfaces/IMeasure.d'
import IRequestResponse from '@interfaces/IRequestResponse.d'

interface MeasureTextProps {
  measure:       IMeasure
  updateMethods: {
    measurment: (data: IRequestResponse) => void
  }
}

const MeasureText: React.FC<MeasureTextProps> = ({ measure, updateMethods }) => {

  const { i18n, showModal, CONSTANTS } = useGlobalContextState()

  return(
    <Style.TextBlock>
      <Style.Header>
        <Style.Name>
          {measure.name}
        </Style.Name>
        <Button
          color      = 'var(--rep-primary)'
          background = 'white'
          hover      = {'var(--rep-primary-light)'}
          icon       = {<FontAwesomeIcon icon="plus" />}
          click      = {() => showModal({
            title:   i18n.t('measurment.actions.add_value'),
            content: <MeasureValueForm
              measure       = {measure}
              updateMethods = {updateMethods}
            />
          })}
        />
      </Style.Header>
      {measure?.last_value
        ? <>
          <Style.TextContent
            onClick  = {() => showModal({
              content: <MeasureDetails measure = {measure} />
            })}
          >
            {measure.last_value.content}
          </Style.TextContent>
          <Style.Date>
            {new Intl.DateTimeFormat(i18n.locale).format(new Date(measure.last_value.date))}
          </Style.Date>
        </>
        : <>
          <Style.TextContent>
            {i18n.t('measurment.no_value')}
          </Style.TextContent>
          <Style.Date>

          </Style.Date>
        </>
      }
    </Style.TextBlock>
  )
}

export default MeasureText
