import React, { useMemo } from 'react'

import SelectDoerForm from '@components/User/SelectDoerForm'

import { useGlobalContextState } from '@context/GlobalContext'

import { IReport, ITask } from '@interfaces/index'
import Form from '@components/Form/Form'

interface AddUserForm {
  task:     ITask,
  report:   IReport
  callback: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const INTERNAL   = 'internal'

const AddUserForm: React.FC<AddUserForm> = ({
  task,
  report,
  callback,
}) => {
  const { current_company } = useGlobalContextState()

  const defaultPeriod = useMemo(() => {
    if (report.maintenance_start_task) {
      const startDate = new Date(report.maintenance_start_task)
      const stopDate  = new Date(report.maintenance_start_task)
      if (task?.duration) { stopDate.setSeconds(stopDate.getSeconds() + task.duration) }
      return [startDate, stopDate]
    } else if (task?.planned_start && task?.planned_end) {
      return [task.planned_start, task.planned_end]
    } else {
      return []
    }
  }, [])

  return (
    <Form callback={callback}>
      <SelectDoerForm
        assignedTo           = {INTERNAL}
        defaultUsers         = {[]}
        defaultPeriod        = {defaultPeriod}
        showTime             = {current_company.permissions.dispatch_task_with_hours || !!report.maintenance_start_task}
      />
    </Form>
  )
}

export default AddUserForm
