import styled from 'styled-components'

export const Header = styled.div`
  font-weight: 600;
  &:hover {
    cursor: ${props => props.clickAction ? 'pointer' : 'default'};
  };
`

export const Content = styled.div`
  font-weight: 600;
  color: var(--rep-neutral-primary);
  flex-grow: 1;
`

export const Item = styled.div`
  display:         flex;
  justify-content: space-between;
  gap:             8px;
  align-items:     center;
  color:           var(--rep-neutral-primary);
  border-bottom:   1px solid var(--rep-neutral-primary-light);
  padding:         4px 0;
  margin:          8px 0;
  border:          1px solid ${props => props.border};
  border-radius:   5px;
  padding:         8px;
`

export const removeIcon = styled.div`
  color: var(--rep-warning);
`
