import React from 'react'
import { buildFilterString } from '@utils/filterString'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FETCH                 = 'AMENITY_FETCH'
const UPDATE                = 'AMENITY_UPDATE'
const ARCHIVE               = 'AMENITY_ARCHIVE'
const UNARCHIVE             = 'AMENITY_UNARCHIVE'
const ADD_TO_PLAN           = 'AMENITY_ADD_TO_PLAN'
const ADD_USERS             = 'AMENITY_ADD_USERS'
const START_USAGE           = 'AMENITY_START_USAGE'
const TAKE_USAGE            = 'AMENITY_TAKE_USAGE'
const STOP_USAGE            = 'AMENITY_STOP_USAGE'
const FETCH_ONGOING_REPORTS = 'AMENITY_FETCH_ONGOING_REPORTS'

export const AMENITY_ACTIONS = {
  FETCH:                 FETCH,
  UPDATE:                UPDATE,
  ARCHIVE:               ARCHIVE,
  UNARCHIVE:             UNARCHIVE,
  ADD_TO_PLAN:           ADD_TO_PLAN,
  ADD_USERS:             ADD_USERS,
  START_USAGE:           START_USAGE,
  TAKE_USAGE:            TAKE_USAGE,
  STOP_USAGE:            STOP_USAGE,
  FETCH_ONGOING_REPORTS: FETCH_ONGOING_REPORTS
}

export function amenityReducer(state, action) {

  const { updateMethods, fetchApi } = state

  const callbacks = action.callbacks || []
  const fieldset  = action.fieldset
  const params    = buildFilterString(action.params)

  switch (action.type) {
    case FETCH:
      fetchApi({
        url:       `/amenities/${action.amenity.id}/rep_show.json?${params}`,
        callback:  updateMethods.amenity,
        followUps: callbacks
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/amenities/${action.amenity.id}/rep_update.json`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        callback:  updateMethods.amenity,
        followUps: callbacks
      })
      return state

    case ARCHIVE:
      fetchApi({
        url:         `/amenities/${action.amenity.id}/rep_archive.json`,
        method:      'PATCH',
        body:        action.data,
        // callback:  updateMethods.amenity,
        followUps:   callbacks,
        withLoading: true
      })
      return state

    case UNARCHIVE:
      fetchApi({
        url:       `/amenities/${action.amenity.id}/rep_unarchive.json`,
        method:    'PATCH',
        body:      action.data,
        // callback:  updateMethods.amenity,
        followUps: callbacks
      })
      return state

    case ADD_TO_PLAN:
      fetchApi({
        url:       `/amenities/${action.amenity.id}/rep_add_to_plan`,
        method:    'POST',
        body:      action.data,
        fieldset,
        followUps: callbacks
      })
      return state

    case ADD_USERS:
      fetchApi({
        url:       `/amenities/${action.amenity.id}/rep_update_users.json`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.amenity(data.response),
        followUps: callbacks
      })
      return state

    case START_USAGE:
      fetchApi({
        url:       '/equipment_usages.json',
        method:    'POST',
        fieldset,
        body:      {...action.data, amenity_id: action.amenity.id },
        callback:  data => updateMethods.startUsage(data.response),
        followUps: callbacks
      })
      return state

    case TAKE_USAGE:
      fetchApi({
        url:       `/equipment_usages/${action.usage_id}/take_over.json`,
        method:    'PATCH',
        fieldset,
        body:      {...action.data, amenity_id: action.amenity.id },
        callback:  data => updateMethods.takeUsage(data.response),
        followUps: callbacks
      })
      return state

    case STOP_USAGE:
      fetchApi({
        url:       `/equipment_usages/${action.usage_id}/stop.json`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        callback:  () => updateMethods.stopUsage(),
        followUps: callbacks
      })
      return state

    case FETCH_ONGOING_REPORTS:
      const page = action.page || action.pagination?.next || 1

      fetchApi({
        url:       `/reports/rep_ongoing?page=${page}&amenity_id=${action.amenity.id}`,
        followUps: callbacks
      })
      return state

    default:
      if (window.env !== 'production') {
        console.error(action)
        toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }
      return state
  }
}
