import React, { useState } from 'react'

import DatePicker   from '@components/Form/DatePicker'
import FileUploader from '@components/Form/FileUploader'
import Form         from '@components/Form/Form'
import Select       from '@components/Form/Select'

import { useGlobalContextState } from '@context/GlobalContext'

import IAmenity from '@interfaces/IAmenity.d'
import IEquipmentUsage from '@interfaces/IEquipmentUsage.d'

interface StartUsageFormProps {
  amenity:  IAmenity
  usage:    IEquipmentUsage
  callback: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}
const StartUsageForm: React.FC<StartUsageFormProps> = ({ amenity, callback, usage}) => {

  const { i18n, current_user } = useGlobalContextState()

  const [files, setFiles] = useState([])

  const addFormData = formData => formData.append('documents', files.map(doc => doc.id).toString())

  return(
    <Form
      callback    = {(data, fieldset) => callback(data, fieldset)}
      addFormData = {addFormData}
    >
      <DatePicker
        name  = {usage?.id ? 'stop' : 'start'}
        date  = {new Date()}
        label = {i18n.t('equipment_usage.starting_date')}
        required
      />

      {usage?.id
        ? <FileUploader
          name            = "documents"
          updateMethods   = {{
            addDocument:    file => setFiles(files => [...files, file]),
            removeDocument: file => setFiles(files => files.filter(f => f != file.id))
          }}
          objectType      = "equipment_usage"
          objectId        = {usage.id}
          files           = {files}
          closeModalAfter = {false}
        />
        : <Select
          name             = 'user_id'
          label            = {i18n.t('equipment_usage.user')}
          searchUrl        = '/users/search'
          defaultValue     = {[current_user]}
          placeholder      = {i18n.t('user.your_users')}
          format           = {{ content: 'name', value: 'id', details: 'role' }}
          detailsLocaleKey = 'shared'
          marginY          = 'M'
          search
          required
        />
      }
    </Form>
  )
}

export default StartUsageForm
