import React, { useRef } from 'react'
import toast from 'react-hot-toast'

import Input from '../Input'

import { useGlobalContextState } from '@context/GlobalContext'

interface FormProps {
  formRef?:     React.RefObject<HTMLElement>
  addFormData?: (FormData) => void,
  validations?:  (data: object) => {
    // Testing data, will trigger an error if true
    error:   boolean,
    // Error message string (already translated)
    message: string
  }[]
  callback:          (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
  children:          React.ReactNode
  submitText?:       string
  submitColor?:      string
  submitBackground?: string
  submitDisabled?:   boolean
}

const Form: React.FC<FormProps> = ({
  formRef,
  addFormData,
  validations,
  callback,
  children,
  submitText,
  submitColor,
  submitBackground,
  submitDisabled = false
}) => {

  const { i18n } = useGlobalContextState()

  const fieldset = useRef(null)

  const sendData = event => {
    // Building data object
    event.preventDefault()
    const formData: FormData = new FormData(event.currentTarget)
    !!addFormData && addFormData(formData)
    const data = Object.fromEntries(formData)

    // Validations (optional)
    // If test if true, will trigger the error
    let errors
    if (validations?.length) {
      errors = validations(data).filter(val => val.error)
    }

    if (errors?.length) return toast(errors.map(err => err.message).join(', '), { icon: '⚠️'})

    // Sending data and fieldset (to reactivate form once we get a response)
    callback(data, fieldset)
  }

  return(
    <form
      onSubmit = {sendData}
      ref      = {formRef}
    >
      <fieldset ref={fieldset}>
        {children}
        <Input
          name         = 'submit'
          type         = 'submit'
          disabled     = {submitDisabled}
          defaultValue = {submitText || i18n.t('actions.save')}
          color        = {submitColor}
          background   = {submitBackground}
        />
      </fieldset>
    </form>

  )
}

export default Form
