import styled, { css, keyframes } from 'styled-components'

const desktopSizes = {
  none: '0',
  M:    '4px 8px;',
  L:    '8px 16px',
  XL:   '16px 32px',
}

const mobileSize = {
  none: '0',
  M:    '8px 16px',
  L:    '16px 32px',
  XL:   '32px 48px;'
}

const spacers = {
  S:       'var(--rep-spacer-s)',
  M:       'var(--rep-spacer-m)',
  L:       'var(--rep-spacer-l)',
  XL:      'var(--rep-spacer-xl)',
  default: '0'
}

const colors = {
  success: 'var(--rep-success)',
  danger:  'var(--rep-danger)',
  warning: 'var(--rep-warning)',
  primary: 'var(--rep-primary)'
}

const alignments = {
  left:   'flex-start;',
  center: 'center',
  right:  'flex-end'
}

const DesktopPadding = css` ${props => desktopSizes[props.size] || '4px 8px;'  } `
const MobilePadding  = css` ${props => mobileSize[props.size]   || '8px 16px;' } `

export const clickAnimation = keyframes`
    0%    { box-shadow: rgba(213, 234, 252, 0) 0px 0px 0px 0px }
    50%   { box-shadow: rgba(213, 234, 252, .3) 0px 0px 0px 5px }
    100%  { box-shadow: rgba(213, 234, 252, 0.1) 0px 0px 0px 5px }
`

export const Button = styled.div`
  display:         flex;
  gap:             8px;
  align-items:     center;
  justify-content: ${props => alignments[props.align] || 'flex-start'};
  align-self:      ${props => props.alingSelf ? props.alingSelf : 'unset'};
  width:           ${props => props.fullWidth ? '100%' : 'fit-content' };
  padding:         ${props => props.isDesktop ? DesktopPadding : MobilePadding};
  margin-top:      ${props => spacers[props.marginY] || 0};
  margin-bottom:   ${props => spacers[props.marginY] || 0};
  background:      ${props => props.confirm ? 'transparent' : (colors[props.type] || (props.isDesktop ? (props.background || 'transparent') : (props.background || props.hover || 'transparent')))};
  /* background:      ${props => colors[props.type] || props.background || props.hover || 'transparent'}; */
  color:           ${props => props.color ? props.color : 'var(--rep-primary)'};
  font-size:       ${props => {
    switch(props.size) {
      case 'M':  return 'initial'
      case 'L':  return '1.1rem'
      case 'XL': return '1.3rem'
      default:   return '.8rem'
    }
  }};
  border:          1px solid ${props => props.confirm ? props.color : (colors[props.type] || (props.border ? props.border : 'none'))};
  border-radius:   5px;
  box-sizing:      border-box;
  transition:      .3s ease;
  user-select:     none;
  box-sizing:      border-box;
  &:hover {
    cursor:     pointer;
    opacity:    .9;
    ${props => props.hover && `background: ${props.hover}`};
  }
  animation-name:            ${props => props.showAnimation ? clickAnimation : null};
  animation-duration:        .4s;
  animation-iteration-count: 1;
`

export const Icon = styled.div`
  svg {
    pointer-events: none;
  }
`

export const Confirm = styled.div`
  /* background:    transparent; */
  /* padding:       2px 8px; */
  /* border-radius: 4px; */
  /* border:        1px solid ${props => props.color}; */
  /* color:         ${props => props.color}; */
`
