import React, { useEffect, useState }  from 'react'

import { useGlobalContextState } from '../../../../context/GlobalContext'

import { IUser } from '../../../../interfaces'

interface InfoUserProps {
  resource: IUser
}

const InfoUser: React.FC<InfoUserProps> = ({ resource, history }) => {

  const { infoResource, setInfoWindowProps, fetchApi } = useGlobalContextState()
  const [user, setUser] = useState<IUser | null>(null)

  const userFetchCallback = data => {
    setUser(data.response)
  }

  useEffect(() => {
    fetchApi({
      url:      `/users/${resource.id}/rep_show`,
      callback: userFetchCallback
    })
  }, [])


  return (
    <div>{user.name}</div>
  )
}

export default InfoUser
