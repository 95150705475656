import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Checkbox     from '@components/Form/Checkbox'
import DatePicker   from '@form/DatePicker'
import FileUploader from '@components/Form/FileUploader'
import Form         from '@components/Form/Form'
import Input        from '@form/Input'
import Select       from '@components/Form/Select'
import Textarea     from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import IAmenity  from '@interfaces/IAmenity.d'
import IContract from '@interfaces/IContract.d'
import IUnit     from '@interfaces/IUnit.d'

interface ContractFormProps {
  contract?:       IContract
  amenity?:        IAmenity
  unit?:           IUnit
  createContract?: (data: { [key: string]: any }, fieldset: React.RefObject<HTMLFieldSetElement>) => void
  updateContract?: (data: { [key: string]: any }, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const ContractForm: React.FC<ContractFormProps> = ({
  contract,
  unit,
  amenity,
  createContract,
  updateContract
}) => {

  const { i18n } = useGlobalContextState()

  const [documents,    setDocuments]    = useState(contract?.documents || [])

  const updateMethods = {
    addDocument:    data => setDocuments(documents => [...documents, data]),
    removeDocument: data => setDocuments(documents => documents.filter(d => d.id !== data.id))
  }

  const callback = contract?.id ? updateContract : createContract
  const addFormData = formData => {
    formData.append('documents', documents.map(doc => doc.id).toString())
    if (contract) { formData.append('id', contract.id) }
  }

  return (
    <Form
      addFormData    = {addFormData}
      callback       = {callback}
      submitText     = {i18n.t('actions.save')}
    >
      <Input name="unit_id"    type="hidden" defaultValue={unit?.id} />
      <Input name="amenity_id" type="hidden" defaultValue={amenity?.id} />

      <FormStyle.Header><FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}</FormStyle.Header>
      <Select
        name         = 'contract_type_id'
        searchUrl    = '/contract_types/rep_search'
        defaultValue = {contract?.contract_type ? [contract.contract_type] : []}
        label        = {i18n.t('contract_type.contract_types')}
        placeholder  = {i18n.t('contract_type.contract_types')}
        format       = {{ content: 'name', value: 'id', details: 'maintenance' }}
        marginY      = 'M'
        search
        withEmpty
      />

      <Select
        name         = 'service_provider_id'
        searchUrl    = '/service_providers/search'
        defaultValue = {contract?.provider ? [contract.provider] : []}
        label        = {i18n.t('service_provider.service_providers')}
        placeholder  = {i18n.t('service_provider.your_service_providers')}
        format       = {{ content: 'name', value: 'id', details: 'expertises' }}
        marginY      = 'M'
        search
        withEmpty
      />

      <Input
        type         = 'text'
        name         = 'contact_user'
        label        = {i18n.t('contract.contact_person')}
        defaultValue = {contract?.contact}
        marginY      = 'M'
      />

      <Input
        type         = 'text'
        name         = 'contract_number'
        label        = {i18n.t('contract.contract_number')}
        defaultValue = {contract?.number}
        marginY      = 'M'
      />

      <Input
        name         = 'htva'
        type         = 'number'
        label        = {i18n.t('contract.cost')}
        defaultValue = {contract?.price}
        marginY      = 'M'
        decimals
      />

      <Textarea
        name         = 'description'
        label        = {i18n.t('shared.description')}
        defaultValue = {contract?.description}
        marginY      = 'M'
      />

      <FormStyle.Header><FontAwesomeIcon icon="user-tie" />{i18n.t('contract.holder')}</FormStyle.Header>
      <Select
        name         = 'holder_id'
        searchUrl    = '/users/search'
        label        = {i18n.t('user.users')}
        defaultValue = {contract?.responsible ? [contract.responsible] : []}
        filters      = {[
          {
            id:      'managers',
            name:    i18n.t('user.role.managers'),
            filters: { role: ['manager', 'local_manager'] }
          },
          {
            id:      'technicians',
            name:    i18n.t('user.role.technicians'),
            filters: { role: ['technician', 'technician_manager'] }
          },
          {
            id:      'employees',
            name:    i18n.t('user.role.employees'),
            filters: { role: ['employee', 'employee_manager'] }
          },
          {
            id:      'all',
            name:    i18n.t('shared.all'),
            filters: {}
          }
        ]}
        defaultFilter    = 'managers'
        placeholder      = {i18n.t('user.your_users')}
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        marginY          = 'M'
        search
      />

      <FormStyle.Header marginY='M'><FontAwesomeIcon icon="dollar-sign" />{i18n.t('contract.contract_rules')}</FormStyle.Header>
      <DatePicker
        name  = 'start_date'
        label = {i18n.t('contract.start_date')}
        date  = {contract?.start ? new Date(contract.start) : new Date()}
        required
      />

      <Input
        name         = 'initial_duration'
        type         = 'number'
        label        = {i18n.t('contract.duration')}
        defaultValue = {contract?.duration || 12}
        marginY      = 'M'
      />


      <Input
        name         = 'renewal_duration'
        type         = 'number'
        label        = {i18n.t('contract.renewal_duration')}
        defaultValue = {contract?.renewal_duration || 1}
        marginY      = 'M'
      />

      <Checkbox
        name         = 'auto_renew'
        defaultValue = {contract?.auto_renew}
        label        = {i18n.t('contract.auto_renew')}
      />

      <Input
        name         = 'notice_period'
        type         = 'number'
        label        = {i18n.t('contract.notice_reminder')}
        defaultValue = {contract?.notice_reminder || 15}
        marginY      = 'M'
      />

      <FormStyle.Header><FontAwesomeIcon icon="file-invoice-dollar" />{i18n.t('document.attached_docs')}</FormStyle.Header>
      <FileUploader
        name            = 'documents'
        updateMethods   = {updateMethods}
        objectType      = 'contract'
        objectId        = {contract?.id}
        files           = {documents}
        closeModalAfter = {false}
        destroyable
        thumbnailDestroy
      />
    </Form>
  )
}

export default ContractForm
