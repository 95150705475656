import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '@components/Avatar'
import Button from '@components/Button'
import Tag    from '@components/Tag'

import * as FormStyle from '@form/FormStyles'
import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { IAmenity, IMachineStop, IUnit } from '@interfaces/index'
import MultiselectItem from '@components/Form/Select/MultiselectItem'
import ReportCard from '@components/Report/ReportCard'

const MachineStop = ({ equipment, stop }: { equipment: IAmenity | IUnit, stop: IMachineStop }) => {

  const { i18n, CONSTANTS } = useGlobalContextState()

  const { MACHINE_STOP } = CONSTANTS

  const status = ({
    [MACHINE_STOP.STATUS.STOPPED]: {
      color:      'var(--rep-danger)',
      colorLight: 'var(--rep-danger-light)',
      icon:       <FontAwesomeIcon icon="explosion" />
    },
    [MACHINE_STOP.STATUS.READY]: {
      color:      'var(--rep-warning)',
      colorLight: 'var(--rep-warning-light)',
      icon:       <FontAwesomeIcon icon="wrench" />
    },
    [MACHINE_STOP.STATUS.RESTARTED]: {
      color:      'var(--rep-success)',
      colorLight: 'var(--rep-success-light)',
      icon:       <FontAwesomeIcon icon="plug-circle-check" />
    },
  })[stop.status] ?? {
    color:      'var(--rep-primary)',
    colorLight: 'var(--rep-primary-light)',
    icon:       <FontAwesomeIcon icon="gear" />,
  }

  return(
    <Style.MachineStop>
      <Style.MachineStopHeader>
        <Tag
          color          = 'var(--rep-neutral-primary)'
          border         = 'var(--rep-neutral-primary)'
          tooltipContent = "Type d'arrêt"
        >
          {stop.type.name}
        </Tag>
        <Tag
          color          = {status.color}
          background     = {status.colorLight}
          icon           = {status.icon}
          tooltipContent = 'Status'
        >
          {i18n.t(`machine_stop.status.${stop.status}`)}
        </Tag>
      </Style.MachineStopHeader>
      <Style.MachineStopHeader>
        <h3>
          {equipment.name}
        </h3>
      </Style.MachineStopHeader>

      {stop.description}

      <Style.MachineStopHeader>
        {stop.fixing_duration &&
          <Tag
            tooltipContent = {i18n.t('machine_stop.kpis.fixing_duration')}
            color          = 'var(--rep-danger)'
            background     = 'var(--rep-danger-light)'
            icon           = {<FontAwesomeIcon icon="stopwatch-20" />}
            marginY        = 'M'
          >
            {stop.fixing_duration}
          </Tag>
        }
        {stop.downtime &&
          <Tag
            tooltipContent = {i18n.t('machine_stop.kpis.total_downtime')}
            color          = 'var(--rep-warning)'
            background     = 'var(--rep-warning-light)'
            icon           = {<FontAwesomeIcon icon="arrow-up-right-dots" rotation={90} />}
            marginY        = 'M'
          >
            {stop.downtime}
          </Tag>
        }
      </Style.MachineStopHeader>

      {!!stop.stopped_at &&
        <MultiselectItem
          name = {i18n.t('machine_stop.status.stopped')}
          icon = {<FontAwesomeIcon icon="explosion" color="var(--rep-danger)"/>}
        >
          <Style.MachineStopLine>
            {new Date(stop.stopped_at).toLocaleDateString(i18n.locale)} {new Date(stop.stopped_at).toLocaleTimeString(i18n.locale)}
            <Avatar
              firstName = {stop.stopped_by.first_name}
              lastName  = {stop.stopped_by.last_name}
              title     = {i18n.t('machine_stop.stopped_by')}
            />
          </Style.MachineStopLine>
        </MultiselectItem>
      }


      {!!stop.ready_at &&
        <MultiselectItem
          name = {i18n.t('machine_stop.status.ready')}
          icon = {<FontAwesomeIcon icon="wrench" color="var(--rep-warning)"/>}
        >
          <Style.MachineStopLine>
            {new Date(stop.ready_at).toLocaleDateString(i18n.locale)} {new Date(stop.ready_at).toLocaleTimeString(i18n.locale)}
            <Avatar
              firstName = {stop.ready_by.first_name}
              lastName  = {stop.ready_by.last_name}
              title     = {i18n.t('machine_stop.ready_by')}
            />
          </Style.MachineStopLine>
        </MultiselectItem>
      }


      {!!stop.restarted_at &&
        <MultiselectItem
          name = {i18n.t('machine_stop.status.restarted')}
          icon = {<FontAwesomeIcon icon="plug-circle-check" color="var(--rep-success)" />}
        >
          {new Date(stop.restarted_at).toLocaleDateString(i18n.locale)} {new Date(stop.restarted_at).toLocaleTimeString(i18n.locale)}
          <Avatar
            firstName = {stop.restarted_by.first_name}
            lastName  = {stop.restarted_by.last_name}
            title     = {i18n.t('machine_stop.restarted_by')}
          />
        </MultiselectItem>
      }


      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="paste" />
        {i18n.t('machine_stop.reports')}
      </FormStyle.Header>

      {stop.reports.map(report => <ReportCard key={report.id} report={report} fromModal />)}
    </Style.MachineStop>
  )
}

export default MachineStop
