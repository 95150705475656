import React from 'react'

import * as Styles from './style'
import ICallout  from './types.d'

/**
 * @description Renders a callout block
 * @example
 * <Callout>
 *  Pay attention to this !!!
 * </Callout>
 */
const Callout: React.FC<ICallout> = ({
  title,
  icon,
  style,
  color,
  border,
  background,
  type = 'primary',
  marginY,
  children
}) => {
  return (
    <Styles.CalloutContainer
      style       = {style}
      border      = {border}
      background  = {background}
      color       = {color}
      marginY     = {marginY}
      type        = {type}
    >
      {!!title && <Styles.CalloutTitle>{!!icon && icon} {title}</Styles.CalloutTitle>}
      <Styles.CalloutContent withTitle={!!title}>
        {!!icon && !title &&
          <div>{icon}</div>
        }
        {children}
      </Styles.CalloutContent>
    </Styles.CalloutContainer>
  )

}

export default Callout
