import styled from 'styled-components'

export const NumberBlock = styled.div`
  position:        relative;
  display:         flex;
  justify-content: space-between;
  align-items:     flex-start;
  flex-direction:  column;
  min-height:      120px;
  min-width:       140px;
  width:           max-content;
  color:           ${props => props.color ? `var(${props.color})` : 'var(--rep-primary)' };
  background:      ${props => props.color === '--rep-success' ? 'white' : `var(${props.color}-light)` };
  border:          1px solid ${props => props.color === '--rep-success' ? 'var(--rep-success-middle)' : 'transparent' };
  border-radius:   4px;
  padding:         8px;
  &:hover { cursor: default }
`
export const MapBlock = styled.div`
  position:        relative;
  display:         flex;
  justify-content: center;
  align-items:     center;
  min-height:      120px;
  min-width:       150px;
  width:           max-content;
  width:       150px;
  background:      ${props => props.color ? `var(${props.color}-light)` : 'white'};
  color:           ${props => props.color ? `var(${props.color})` : 'unset'};
  border-radius:   4px;
  /* &:hover { cursor: default } */
`

export const TextBlock = styled.div`
  position:        relative;
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  flex-direction:  column;
  min-height:      120px;
  width:           140px;
  color:           ${props => props.color ? `var(${props.color})` : 'var(--rep-primary)' };
  border:          1px solid ${props => props.color ? `var(${props.color}-middle)` : 'var(--rep-primary-middle)' };
  border-radius:   4px;
  padding:         8px;
  &:hover { cursor: default }
`


export const Header = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     flex-start;
  width:           100%;
  align-self:      flex-start;
  gap:             8px;
`

export const MapHeader = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     flex-start;
  position:        absolute;
  gap:             8px;
  width:           90%;
  top:             8px;
  /* right:           4px; */
`

export const Difference = styled.div`
  padding:       2px 4px;
  border-radius: 8px;
  display:       flex;
  gap:           4px;
  align-items:   center;
`

export const Number = styled.div`
  font-size:   2.5rem;
  font-weight: 600;
`

export const Icon = styled.div`
`

export const Content = styled.div`
  display:         flex;
  align-items:     baseline;
  justify-content: flex-start;
  &:hover { cursor: pointer }
`

export const Name = styled.div`
  width:              100%;
  font-weight:        200;
  /* margin:             4px 0; */
  display:            -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow:           hidden;
  text-overflow:      ellipsis;
`

export const MapName = styled.div`
  display:            inline-block;
  color:              var(${props => props.color});
  font-weight:        200;
  /* margin:             4px 0; */
  background:        white;
  padding:            2px 4px;
  border-radius:      4px;
  display:            -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow:           hidden;
  text-overflow:      ellipsis;
`

export const Date = styled.div`
  font-weight:  200;
  margin:       4px 0;
  font-size:    .8em;
  justify-self: flex-end;
  align-self:   flex-start;
`

export const TextContent = styled.div`
  align-self:         flex-start;
  display:            -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow:           hidden;
  text-overflow:      ellipsis;
  &:hover { cursor: pointer }
`
