import React, { useState } from 'react'

import Wrapper  from '../../../Wrapper'

import MaintenanceForm from './MaintenanceForm'

import { MaintenancesContextProvider } from '@context/MaintenancesContext'

import { ICompany, IMaintenance, IUser } from '@interfaces/index'

interface MaintenanceFormProps {
  maintenance:     IMaintenance,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  locale:          string,
  fromRails:       boolean
}


const RailsMaintenanceForm: React.FC<MaintenanceFormProps> = ({
  // maintenance,
  fromRails,
  maintenance_id,
  current_user,
  current_company,
  token,
  locale,
}) => {

  return (
    <Wrapper
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      locale          = {locale}
      fromRails       = {fromRails}
      withModal       = {false}
    >
      <MaintenancesContextProvider current_user = {current_user}>
        <MaintenanceForm maintenance_id = {maintenance_id}/>
      </MaintenancesContextProvider>
    </Wrapper>
  )
}

export default RailsMaintenanceForm
