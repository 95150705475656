import React, { useMemo } from 'react'

import WithTooltip from '@components/WithTooltip'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar    from '@components/Avatar'
import Dropdown  from '@components/Dropdown'
import Tag       from '@components/Tag'
import Thumbnail from '@components/Form/FileUploader/Thumbnail'

import ContractForm from './Form'

import * as Style from './style'
import { IContract } from '@interfaces/index'

interface ContractProps {
  contract: IContract,
  update:   (data: { [key: string]: any }, fieldset: React.RefObject<HTMLFieldSetElement>) => void,
  unlink:   (any) => void
}

const ContractCard: React.FC<ContractProps> = ({ contract, update, unlink }) => {

  const { i18n, showModal, current_user } = useGlobalContextState()

  const actions = useMemo(() => {
    const contractOptions = []

    if (contract.permissions.can_update_contract) contractOptions.push({
      icon:    <FontAwesomeIcon icon="pen" />,
      color:   'var(--rep-primary)',
      content: i18n.t('contract.actions.edit'),
      click:   () => showModal({
        title:   contract.name,
        content: <ContractForm
          contract       = {contract}
          updateContract = {update}
        />
      })
    })

    if (contract.permissions.can_unlink_equipment) contractOptions.push({
      icon:    <FontAwesomeIcon icon="link-slash" />,
      color:   'var(--rep-warning)',
      content: i18n.t('contract.actions.remove_asset'),
      click:   () => unlink(contract)
    })

    return contractOptions
  }, [contract])

  return(
    <Style.Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Style.Header>
          {contract.archived
            ? <WithTooltip content={i18n.t('contract.archived')}>
              <FontAwesomeIcon icon="folder" color="var(--rep-warning)" style={{ marginRight: '4px' }}/>
            </WithTooltip>
            : contract.late &&
              <WithTooltip content={i18n.t('contract.late')}>
                <FontAwesomeIcon icon="triangle-exclamation" color="var(--rep-danger)" style={{ marginRight: '4px' }} />
              </WithTooltip>
          }

          <Tag
            color      = 'var(--rep-neutral-primary)'
            background = 'var(--rep-neutral-light)'
            tooltipContent = {i18n.t('contract_type.contract_type')}
          >
            {contract.contract_type.name}
          </Tag>

          <Style.Title>
            <WithTooltip
              title={i18n.t('contract.duration_from', { duration: contract.duration, start: new Date(contract.start).toLocaleDateString(i18n.locale) })}
              content={<div>
                {contract.price ? Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(contract.price) : ''} —
                {contract.description}
              </div>}
            >
              {contract.number}
            </WithTooltip>
          </Style.Title>
        </Style.Header>

        <div style={{ display: 'flex', gap: '4px'}}>
          {contract.provider &&
            <Avatar
              firstName      = {contract.provider.name}
              lastName       = ''
              background     = 'var(--rep-accent-light)'
              color          = 'var(--rep-accent)'
              title          = {`${contract.provider.name} [${i18n.t('favourite_provider.external_prov')}]`}
              tooltipContent = {contract.provider.name}
            />
          }
          {contract.responsible &&
            <Avatar
              firstName      = {contract.responsible?.first_name}
              lastName       = {contract.responsible?.last_name}
              title          = {i18n.t('shared.responsible')}
              background     = {contract.responsible?.id === current_user.id ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
              color          = {contract.responsible?.id === current_user.id ? 'var(--rep-warning)' : 'var(--rep-neutral)'}
              tooltipContent = {
                <>
                  <div>
                    {`${contract.responsible?.name}`}
                  </div>
                  {contract.responsible?.phone &&
                  <div>
                    {<FontAwesomeIcon icon="phone" />} {contract.responsible?.phone}
                  </div>
                  }
                </>
              }
            />
          }
          {!!actions.length &&
          <Dropdown
            options = {actions}
            icon    = {<FontAwesomeIcon icon="bars" />}
            hover   = 'var(--rep-primary-light)'
          />
          }
        </div>
      </div>
      {!!contract.documents.length &&
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridRow: '50px', gap: '8px'}}>
            {contract.documents.map(doc =>
              <Thumbnail
                key           = {doc.id}
                file          = {doc}
                showFileName  = {false}
              />
            )}
          </div>
      }
    </Style.Card>
  )
}

export default ContractCard
