import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PlanEquipments    from './PlanFormEquipments'
import PlanFormDocuments from './PlanFormDocuments'
import PlanFormInfo      from './PlanFormInfo'
import PlanFormResume    from './PlanFormResume'
import PlanFormTasks     from './PlanFormTasks'
import PlanQuickForm     from './PlanQuickForm'

import RecurrencyForm    from '@components/RecurrencyForm'
import Toggle            from '@components/Toggle'
import WizardForm        from '@components/WizardForm'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { parseBoolean }  from '@utils/parser'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import { IMainLine } from '@interfaces/ICalendar.d'
import { IMaintenancePlan } from '@interfaces/index'

interface PlanFormProps {
  plan_id?:       number
  item?:          IMaintenancePlan,
  updatePlan?:    React.Dispatch<React.SetStateAction<IMainLine>>
  // fromRails?:     boolean
  // equipments?:   IUnit[] | IAmenity[]
  setLoading?:    React.Dispatch<React.SetStateAction<boolean>>
  setMainLines?:  React.Dispatch<React.SetStateAction<IMainLine[]>>
  setPagination?: React.Dispatch<React.SetStateAction<any>>
}

const PlanForm: React.FC<PlanFormProps> = ({
  plan_id,
  item,
  updatePlanProps,
  // fromRails = false,
  customCallbacks,
  setLoading,
  setMainLines,
  setPagination
}) => {

  const { i18n, fetchApi, fromRails, closeModal } = useGlobalContextState()
  const { filters, MAINTENANCE_CONSTANTS, permissions } = useMaintenancesContextState()
  const { VIEWS } = MAINTENANCE_CONSTANTS

  const [displayed, setDisplayed] = useState(false)
  // const [fetched, setFetched] = useState(false)
  const [plan, setPlan] = useState(item)

  const [simpleForm, setSimpleForm] = useState(!item?.id && !plan_id)


  const fetchPlan = id => {
    fetchApi({
      url:      `/maintenance_plans/${id}`,
      callback: planData => {
        console.log('fetch done')
        setPlan(planData)
        setDisplayed(true)
      }
    })
  }

  const fetched = useRef(false)

  useEffect(() => {
    if (fetched.current) return
    // Dirty fix to fetch only when RoR modale is open
    const ror_modale = document.querySelector(`#edit-maintenance-plan-react${plan_id}`)
    if (!plan?.id && fromRails && ror_modale) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.attributeName === 'class' && ror_modale.classList.contains('show')) {
            fetched.current = true
            fetchPlan(plan_id)
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(ror_modale, { attributes: true, attributeOldValue: true, childList: false, subtree: false })
    // Normal React behaviour
    } else {
      if (plan_id) {
        fetched.current = true
        fetchPlan(plan_id)
      } else {
        setPlan(item)
        setDisplayed(true)
      }
    }
  }, [])

  const initData = useMemo((): IMaintenancePlan =>
    ({
      id:              plan_id || plan?.id,
      name:            plan?.name,
      description:     plan?.description,
      assignation:     plan?.assignation,
      archived:        plan?.archived,
      group:           plan?.group,
      user:            plan?.user,
      provider:        plan?.provider,
      expertise:       plan?.expertise,
      charge_number:   plan?.charge_number,
      legal:           plan?.legal,
      reminder_period: plan?.reminder_period || 30,
      remind_provider: plan?.remind_provider,
      recurrence:      {
        mode:          plan?.mode,
        dayrecurrency: plan?.recurrence?.dayrecurrency,
        frequency:     plan?.recurrence?.frequency,
        interval:      plan?.recurrence?.interval,
        byweekday:     plan?.recurrence?.byweekday,
        bymonthday:    plan?.recurrence?.bymonthday,
        bymonth:       plan?.recurrence?.bymonth,
        bysetpos:      plan?.recurrence?.bysetpos,
        stopcondition: plan?.recurrence?.stopcondition,
        until:         plan?.recurrence?.until,
        count:         plan?.recurrence?.count,
        exclusions:    plan?.exclusions,
        auto_generate: plan?.recurrence?.auto_generate,
      },
      exclusions:    plan?.exclusions,
      mode:          plan?.mode,
      auto_generate: parseBoolean(plan?.auto_generate),

      tasks:       plan?.tasks     || [],
      documents:   plan?.documents || [],
      amenities:   plan?.amenities || [],
      units:       plan?.units     || [],
      permissions: {
        can_update_maintenance_plan: plan?.permissions?.can_update_maintenance_plan  || false,
        can_update_equipments:       plan?.permissions?.can_update_equipments        || false,
        can_archive_plan:            plan?.permissions?.can_archive_plan             || false,
        can_unarchive_plan:          plan?.permissions?.can_unarchive_plan           || false,
        can_destroy_plan:            plan?.permissions?.can_destroy_plan             || false,
      }
    })
  , [plan])

  const [data, setData] = useState(initData)
  useEffect(() => setData(initData), [initData])

  const [_state, formDispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    pagination: { page: 1, next: 0, last: null },
    closeModal,
    setLoading,
    VIEWS,
    setMainLines,
    setPagination
  })

  const createPlan = data => {
    formDispatch({
      type:      MAINTENANCE_ACTIONS.CREATE_PLAN,
      callback:  closeModal,
      followUps: customCallbacks ? customCallbacks
        : fromRails
          ? [() => window.location.reload()]
          : [() => formDispatch({ type: MAINTENANCE_ACTIONS.FETCH_MAINLINES, filters, pagination: { page: 0, last: null, next: 1 }, setPagination })],
      data,
      fromRails,
    })
  }

  const updatePlan = data => {
    if (updatePlanProps) {
      updatePlanProps(data)
    } else {
      formDispatch({
        type:      MAINTENANCE_ACTIONS.UPDATE_PLAN,
        callbacks: [
          () => {
            if (fromRails) {
              window.location.reload()
            }
          }
        ],
        data: {...data, fromRails },
        fromRails,
      })
    }
  }

  const forms = useMemo(() => {
    const planForms = [
      {
        component: <PlanFormInfo initData={initData} permissions={permissions} />,
        icon:      <FontAwesomeIcon icon='circle-info' />,
        name:      i18n.t('shared.details'),
        checkTag:  ['name']
      },
      {
        component: <RecurrencyForm />,
        icon:      <FontAwesomeIcon icon='calendar-day' />,
        name:      i18n.t('maintenance.recurrence_title'),
        checkTag:  ['recurrence']
      },
    ]
    if (!plan?.id) { planForms.push(
      {
        component: <PlanEquipments />,
        icon:      <FontAwesomeIcon icon='gear' />,
        name:      i18n.t('maintenance.assets'),
        checkTag:  ['amenities', 'units']
      }
    )}
    planForms.push({
      component: <PlanFormTasks />,
      icon:      <FontAwesomeIcon icon='list' />,
      name:      i18n.t('todo.todos'),
      checkTag:  ['tasks']
    })
    planForms.push({
      component: <PlanFormDocuments />,
      icon:      <FontAwesomeIcon icon='file-arrow-up' />,
      name:      i18n.t('shared.documents'),
      checkTag:  ['documents']
    })
    planForms.push({
      component: <PlanFormResume />,
      icon:      <FontAwesomeIcon icon='paper-plane' />,
      name:      i18n.t('shared.summary'),
      checkTag:  []
    })
    return planForms
  }, [plan])

  if (!displayed) return <div>Loading…</div>

  return(
    <div style={{flexGrow: 1 }}>
      {(!item?.id && !plan_id) &&
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '8px'}}>
          <Toggle
            initialOption='left'
            options={{
              left: {
                id:      'simple',
                content: i18n.t('maintenance.form.simple_form'),
                icon:    <FontAwesomeIcon icon="paper-plane" />
              },
              right: {
                id:      'advanced',
                content: i18n.t('maintenance.form.advanced_form'),
                icon:    <FontAwesomeIcon icon="rocket" />

              }
            }}
            callback={value => setSimpleForm(value === 'simple')}
          />
        </div>
      }
      {simpleForm
        ? <PlanQuickForm
          data     = {data}
          setData  = {setData}
          callback = {plan?.id ? updatePlan : createPlan}
        />
        : <WizardForm
          forms       = {forms}
          data        = {data}
          setData     = {setData}
          callback    = {plan?.id ? updatePlan : createPlan}
          buttonValue = {plan?.id ? i18n.t('maintenance.actions.save_maintenance') : i18n.t('maintenance.actions.create_maintenance')}
        />
      }
    </div>
  )
}

export default PlanForm
