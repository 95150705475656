import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n }            from 'i18n-js'
import toast               from 'react-hot-toast'

import CONSTANTS    from '../config/constants.json'
import translations from '../config/translations.json'

import { ICompany, IUser }   from '../interfaces'
import { GlobalContextType } from './types.d'

import fetchApiWithToken, { fetchProps }  from '../utils/fetchApi'
import { buildFilterString } from '../utils/filterString'

declare global {
  interface Window {
    env: any;
  }
}

// create contexts
const GlobalContextState = createContext<GlobalContextType | null>(null)

// context consumer hook
const useGlobalContextState = () => {
  // get the context
  const context = useContext(GlobalContextState)

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useGlobalContextState was used outside of its Provider')
  }
  return context
}

interface GlobalContextProviderProps {
  children:        React.ReactNode,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  isDesktop:       boolean,
  locale:          string
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
  current_user,
  current_company,
  table_display,
  rails_view = false,
  token,
  isDesktop,
}) => {

  const fromRails = useMemo(() => rails_view, [rails_view])

  const [showCommandBar, setShowCommandBar]       = useState(false)
  const [commandBarOptions, setCommandBarOptions] = useState({})

  const [modalDisplay, toggleModal]     = useState(false)
  const [modalContent, setModalContent] = useState({ content: null, title: ''})

  const [alertDisplay, toggleAlert]     = useState(false)
  const [alertContent, setAlertContent] = useState(null)
  const [alertActions, setAlertActions] = useState([])

  const [infoWindowProps,      setInfoWindowProps]         = useState({})
  const [modalInfoWindowProps, setModalInfoWindowProps]    = useState({})

  const showModal = ({ content: content, title: title }) => {
    setModalContent({ content, title })
    toggleModal(true)
  }

  const closeModal = () => {
    setModalContent({ content: null, title: '' })
    setModalInfoWindowProps({})
    toggleModal(false)
  }

  const showAlert = (content, actions) => {
    setAlertContent(content)
    setAlertActions(actions)
    toggleAlert(true)
  }
  const closeAlert = () => {
    setAlertContent(null)
    setAlertActions([])
    toggleAlert(false)
  }

  /* resource muste have a type */

  const i18n = new I18n()
  i18n.store(translations)
  i18n.defaultLocale  = 'fr'
  i18n.locale         = current_user?.preferred_locale || i18n.defaultLocale
  i18n.enableFallback = true

  if (window.env !== 'production') {
    i18n.missingTranslation.register('toast', (i18n, scope) => {
      toast.error(`Translation missing: ${scope} in ${new Intl.DisplayNames(['en'], {type: 'language'}).of(i18n.locale)}`, { id: 'translation-missing', icon: <FontAwesomeIcon icon="skull" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger)' } })
      return `Translation missing: ${scope}`
    })
    i18n.missingBehavior = 'toast'
  }

  useEffect(() => { i18n.locale = current_user.preferred_locale || i18n.defaultLocale }, [current_user])

  /**
   * @borrows fetchApiWithToken as fetchApi
   * @see {@link fetchApiWithToken}
   */
  const fetchApi = (props: fetchProps) => new Promise((resolve, _reject) => resolve(fetchApiWithToken(token, props)))

  async function getReport(id) {
    const filters = buildFilterString({
      tasks:         true,
      costs:         true,
      documents:     true,
      conversations: true,
      charge_number: true,
      machine_stop:  true,
      remarks:       true,
      custom_fields: true,
      permissions:   true,
    })
    const response = await fetch(`/reports/${id}.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())
    return response
  }

  // const getMinimalReport = (id, costs) =>
  //   fetch(`/reports/${id}/rep_show_minimal${costs ? '?costs=true' : ''}`, { cache: 'no-store' })
  //     .then(data => data.json())

  const getConversation = id =>
    fetch(`/conversations/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getConversations = reportId =>
    fetch(`/reports/${reportId}/conversations/rep_index`, { cache: 'no-store' })
      .then(data => data.json())

  const getRemark = id =>
    fetch(`/control_remarks/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getRemarks = (id, filters = '') =>
    fetch(`/reports/${id}/rep_remarks.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  const getDocuments = (type, id, filters) =>
    fetch(`/${type}/${id}/rep_documents.json?${filters}`)
      .then(data => data.json())

  const getTask = id =>
    fetch(`/to_do_items/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getTasks = (id, filters = '') =>
    fetch(`/reports/${id}/rep_tasks.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  const getCost  = (reportId, id) =>
    fetch(`/reports/${reportId}/offer_lines/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getCosts = (id, filters = '') =>
    fetch(`/reports/${id}/rep_costs.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  const getAmenity = (id, filters) =>
    fetch(`/amenities/${id}/rep_show.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  // const getMachineStop = reportId => {}

  const initAPI = ({
    type           = null,
    reportId       = null,
    taskId         = null,
    costId         = null,
    remarkId       = null,
    amenityId      = null,
    conversationId = null,
  }) => { return {
    fetchReport:        async () => await getReport(reportId),
    // fetchMinimalReport: async (costs = false) => await getMinimalReport(reportId, costs),
    fetchTask:          async () => await getTask(taskId),
    fetchTasks:         async filters => await getTasks(reportId, filters),
    fetchCost:          async () => await getCost(reportId, costId),
    fetchCosts:         async filters => await getCosts(reportId, filters),
    fetchConversation:  async () => await getConversation(conversationId),
    fetchConversations: async () => await getConversations(reportId),
    fetchDocuments:     async filters => await getDocuments(type, reportId, filters),
    fetchRemark:        async () => await getRemark(remarkId),
    fetchRemarks:       async filters => await getRemarks(reportId, filters),
    fetchAmenity:       async filters => await getAmenity(amenityId, filters)
    // fetchMachineStop:   async () => await getMachineStop(reportId)
  }}

  return (
    // the Providers gives access to the context to its children
    <GlobalContextState.Provider value={{
      CONSTANTS,
      current_user,
      current_company,
      table_display,
      token,
      i18n,
      fetchApi,
      isDesktop, fromRails,
      showModal, closeModal,
      modalDisplay,
      modalContent,
      showCommandBar, setShowCommandBar,
      commandBarOptions, setCommandBarOptions,
      showAlert, closeAlert,
      alertDisplay,
      alertActions, alertContent,
      setInfoWindowProps, infoWindowProps,
      modalInfoWindowProps, setModalInfoWindowProps,
      initAPI
    }}>
      {children}
    </GlobalContextState.Provider>
  )
}

export { GlobalContextProvider, useGlobalContextState }
