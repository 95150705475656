import styled from 'styled-components'

export const TaskHeader = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  flex-wrap:       wrap;
  width:           100%;
  gap:             8px;
`

export const TaskStatus = styled.div`
  border-radius:   16px;
  font-weight:     300;
  font-size:       .7rem;
  display:         flex;
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items:     center;
  text-transform:  capitalize;
  color: ${props => {
    switch(props.status) {
      case 'new':      return 'var(--rep-primary)'
      case 'assigned': return 'var(--rep-accent)'
      case 'ongoing':  return 'var(--rep-success)'
      case 'done':     return 'var(--rep-warning)'
      case 'closed':   return 'var(--rep-success)'
      default:         return 'var(--rep-neutral)'
    }
  }};
  svg     { margin-right: 2px; }
  &:hover { cursor: default; }
`

export const TaskActions = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  flex-wrap:       wrap;
  gap:             8px;
  margin:          4px 0;
`

export const TaskTopActions = styled.div`
  display:         flex;
  justify-content: flex-end;
  flex-wrap:       wrap;
  align-items:     center;
  gap:             8px;
`

export const TaskTitle = styled.h3`
  font-weight:     bolder;
  font-size:       1rem;
  font-weight:     500;
  text-align:      left;
  font-family:     'Inter', sans-serif;
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  margin-bottom:   0;
  margin-left:     8px;
  &:hover { cursor : pointer }
`

export const Avatars = styled.div`
  display: flex;
  gap:     2px;
`

export const TaskText = styled.div`
  color:       var(--rep-neutral-primary);
  text-align:  left;
  margin-left: 8px;
  white-space: pre-line;
`

export const TaskForms = styled.div`
  display:   flex;
  gap:       8px;
  flex-wrap: wrap;
  margin:    12px 0;
`

export const TaskForm = styled.div`
  display: flex;
`

export const StartStop = styled.div`
  padding:          8px;
  font-size:        1rem;
  border-radius:    5px;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  color:          white;
  flex-shrink:      0;
  display:          flex;
  flex-direction:   column;
  align-items:      center;
  gap:              8px;
  height:           100%;
  /* background:       ${props => props.status === 'start' ? 'linear-gradient(44deg, rgba(149,207,255,1) 0%, rgba(42,153,242,1) 100%)' : 'linear-gradient(44deg, rgba(243,206,136,1) 0%, rgba(255,171,10,1) 100%)' }; */
  background:       ${props => props.status === 'start' ? 'white' : 'linear-gradient(44deg, rgba(243,206,136,1) 0%, rgba(255,171,10,1) 100%)' };
  border: 1px solid ${props => props.status === 'start' ? 'var(--rep-primary-light)' : 'var(--rep-warning-light)'};
  color: ${props => props.status === 'start' ? 'var(--rep-primary)' : 'white'};
  margin: 8px 0;
  transition: background .3s ease;

  span {
    font-size:      .6rem;
    text-transform: uppercase;
    font-weight:    600;
  }
  &:hover {
    cursor:     pointer;
    background: ${props => props.status === 'start' ? 'var(--rep-primary)' : 'linear-gradient(44deg, rgba(243,206,136,1) 0%, rgba(255,171,10,1) 100%)'};
    color: white;
  }
`

export const OngoingTimer = styled.div`
  color:           var(--rep-warning);
  font-weight:     700;
  display:         flex;
  gap:             8px;
  align-items:     center;
  justify-content: center;
`

export const CostLine = styled.div`
  display:               grid;
  grid-template-columns: ${props => props.withPrices ? '5% 35% 10% 10% 10% 10%' : '10% 45% 15% 10% 15%'};
  justify-content:       space-between;
  gap:                   1%;
  align-items:           center;
  margin:                8px 0px;
  padding:               4px 8px;
  padding-top:           16px;
  border-top:            1px solid var(--rep-neutral-primary-light);
  color: ${props => {
    switch(props.status) {
      case 'approved':   return 'var(--rep-success)'
      case 'rejected':   return 'var(--rep-danger)'
      case 'to_approve': return 'var(--rep-neutral)'
      case 'ongoing':    return 'var(--rep-success)'
      default:           return 'var(--rep-neutral)'
    }
  }};
`

export const CostLineActions = styled.div`
  display:         flex;
  justify-content: flex-end;
  gap:             8px;
`

export const TaskBox = styled.div`
  border:        1px solid var(--rep-primary-light);
  padding:       8px;
  border-radius: 5px;
  margin:        8px 0;
  h3 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  p {
    color:         var(--rep-neutral-primary);
    text-align:    left;
    margin-bottom: 0;
  }
`

export const FileListStyles = styled.div`
  display:               grid;
  grid-template-columns: repeat(2, minmax(0,1fr));
  grid-gap:              8px;
  background:            white;
  padding:               8px;
  border-radius:         4px;
  grid-auto-rows:        calc(100px + 1.5rem);
`

export const UnseenMsgCount = styled.div`
  padding-left:  5px;
  padding-right: 5px;
  border-radius: 5px;
  background:    var(--rep-danger);
  color:         white;
  position:      absolute;
  right:         -5px;
  top:           -5px;
  font-size:     10px;
`
