import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Tag from '@components/Tag'

import * as Style from './style'

import { SelectOption }          from '@form/Select/style'
import { useGlobalContextState } from '@context/GlobalContext'

const CustomMaterialOption = ({
  option,
  click,
  selected,
  ariaSelected
}) => {

  const { i18n } = useGlobalContextState()

  /** Options are material_quantities */

  return (
    <SelectOption
      onClick  = {click}
      selected = {selected || ariaSelected}
    >
      <Style.MaterialDescription>
        <Style.MaterialBrand>
          {!!option.location_name &&
            <Tag
              icon   = {<FontAwesomeIcon icon='location-dot' />}
              color  = 'var(--rep-neutral-primary)'
              border = 'var(--rep-neutral)'
            >
              {option.location_name}
            </Tag>
          }
          {!!option.material.internal_ref &&
            <Tag
              icon   = {<FontAwesomeIcon icon='barcode' />}
              border = 'var(--rep-neutral)'
              color  = 'var(--rep-neutral-primary)'
            >
              {option.material.internal_ref}
            </Tag>
          }
          {!!option.material.category &&
            <Tag
              icon   = {<FontAwesomeIcon icon='boxes-stacked' />}
              color  = 'var(--rep-neutral-primary)'
              border = 'var(--rep-neutral)'
            >
              {option.material.category}
            </Tag>
          }
          <Style.MaterialStock>{i18n.t('material.in_stock', { quantity: option.quantity })}</Style.MaterialStock>
        </Style.MaterialBrand>

        <Style.MaterialNomination>
          <Style.MaterialName>{option.material.name}</Style.MaterialName>
          {!!option.material.brand &&
            <Style.MaterialBrand>
              [{option.material.brand}]
            </Style.MaterialBrand>
          }
        </Style.MaterialNomination>

        {!!option.material.description && <Style.MaterialDescriptionDetails>{option.material.description}</Style.MaterialDescriptionDetails>}
        {!!option.material.packaging && <Style.MaterialDescriptionDetails><FontAwesomeIcon icon="temperature-low" /> {option.material.packaging}</Style.MaterialDescriptionDetails>}
      </Style.MaterialDescription>
    </SelectOption>
  )
}

export default CustomMaterialOption
