import React, { useMemo } from 'react'

import { useMediaQuery } from '@uidotdev/usehooks'

import * as Style from './style'

import ISwitch from './types.d'
import Input from '../Form/Input'

const Switch: React.FC<ISwitch> = ({
  name,
  selected,
  callback,
  options,
  marginY,
  allWidth    = false,
  multiselect = false,
  mobileCol   = 2,
  desktopCol  = 4,
  wrap        = false,
}) => {

  const isMobile = useMediaQuery('(max-width: 786px)')

  const gridSizeCalculation = useMemo(() => {
    if (isMobile) return mobileCol
    return desktopCol
  }, [isMobile])

  return (
    <>
      <Input
        name         = {name}
        type         = "hidden"
        defaultValue = {selected}
      />
      <Style.Switch
        allWidth = {allWidth}
        gridSize = {gridSizeCalculation || 2}
        marginY  = {marginY}
        wrap     = {wrap}
      >
        {options.map((opt, key) =>
          <Style.SwitchOption
            key         = {key}
            allWidth    = {allWidth}
            selected    = {multiselect && Array.isArray(selected) ? selected.includes(opt.value) : selected === opt.value}
            multiselect = {multiselect}
            onClick     = {() => callback(opt.value)}
            marginY     = 'none'
          >
            {opt.icon} {opt.content}
          </Style.SwitchOption>
        )}
      </Style.Switch>
    </>
  )
}

export default Switch
