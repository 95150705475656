import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Column       from '@components/Column'
import { Filter }   from '@components/Column/Filter'
import ReportCard   from '@components/Report/ReportCard'

import * as Style from './style'

import { ReportContextProvider } from '@context/ReportContext'
import { useGlobalContextState } from '@context/GlobalContext'

const ReportList = ({
  reports,
  listType,
  filterUrl,
}) => {
  const {
    i18n,
    fetchApi,
    isDesktop
  } = useGlobalContextState()

  const [reportsData, setReportsData] = useState(reports)
  const [loading,     setLoading]     = useState(true)

  const fetchData = async filterPagination => await fetch(`/reports.json?list_type=${listType}&${filterUrl}&${filterPagination}`, { cache: 'no-store' })
    .then(data => data.json())

  useEffect(() => {
    window.history.replaceState(null, '', `${window.location.pathname}?${filterUrl}`)
  }, [filterUrl])

  const pagination = useMemo(() => new Filter({
    totalFilteredCount: reportsData.total,
    perPage:            25,
    totalPages:         reportsData.total_pages,
    fetch:              fetchData,
    pageCallback:       data => setReportsData(reportsData => ({...reportsData, response: [...reportsData.response, ...data] })),
    callback:           setReportsData
  }), [filterUrl])

  const updateReportsInfo = (data) => {
    setReportsData(data)
    pagination.setPageExternal(1)
    pagination.setTotalPagesExternal(data.total_pages)
    setLoading(false)
  }

  useEffect(() => {
    if (/initial_render/.test(filterUrl)) return
    setLoading(true)
    pagination.setPageExternal(1)
    pagination.setTotalPagesExternal(1)
    pagination.setTotalFilteredCount(0)
    fetchApi({
      url:      `/reports.json?list_type=${listType}&${filterUrl}`,
      method:   'GET',
      callback: updateReportsInfo
    })
  }, [filterUrl])



  const emptyCard = useMemo(() => {
    return(
      <Style.EmptyList>
        <FontAwesomeIcon icon="ticket" />
        {i18n.t('report.no_reports')}
      </Style.EmptyList>
    )
  }, [])

  return (
    <Column
      title                = {i18n.t(`report.status.${listType}`)}
      totalCount           = {reportsData.total}
      currentCount         = {reportsData.response.length}
      totalPages           = {reportsData.total_pages}
      totalFiltered        = {reportsData.total}
      maxHeight            = {isDesktop ? '90vh' : '83vh'}
      filters              = {pagination}
      headerActions        = {[]}
      showEmptyContent     = {!reportsData?.total}
      emptyContent         = {emptyCard}
      loading              = {loading}
      setLoading           = {setLoading}
      overflow             = 'auto'
      grow
      paginated
    >

      {reportsData.response.map(report =>
        <ReportContextProvider
          key          = {report.id}
          serverReport = {report}
        >
          <ReportCard report={report}/>
        </ReportContextProvider>
      )
      }
    </Column>
  )
}

export default ReportList
