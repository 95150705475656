import React from 'react'

import Wrapper  from '../../Wrapper'

import PlanForm from './PlanForm'

import { MaintenancesContextProvider } from '@context/MaintenancesContext'

import { IAmenity, ICompany, IMaintenancePlan, IUnit, IUser } from '@interfaces/index'
import { useGlobalContextState } from '@context/GlobalContext'

interface PlanFormProps {
  item:            IMaintenancePlan,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  locale:          string,
  fromRails:       boolean
  maintainables:   IAmenity | IUnit,
  equipments:      IAmenity | IUnit
}

const MaintenancePlanForm: React.FC<PlanFormProps> = ({
  item,
  plan_id,
  fromRails,
  current_user,
  current_company,
  token,
  locale,
  maintainables,
  equipments,
}) => {

  return (
    <Wrapper
      current_user    = {current_user}
      current_company = {current_company}
      token           = {token}
      locale          = {locale}
      withModal       = {false}
      withInfoWindow  = {false}
      fromRails       = {fromRails}
    >
      <MaintenancesContextProvider
        current_user        = {current_user}
        serverMaintainables = {maintainables}
      >
        <PlanForm plan_id = {plan_id} />
      </MaintenancesContextProvider>
    </Wrapper>
  )
}

export default MaintenancePlanForm
