import Chart from 'react-apexcharts'
import React from 'react'

const Line = ({ categories, data, stepline = false }) => {
  const options = {
    xaxis: {
      labels:     { show: false },
      axisBorder: { show: false },
      crosshairs: { show: false },
      axisTicks:  { show: false },
      categories: categories,
      // type:       'datetime'
      // tooltip:    { enabled: false },
      // title: { style: { color: 'red' } },
    },
    yaxis: {
      labels: { show: false },
      // axisBorder: { show: false }
    },
    chart: {
      // color:   'red',
      toolbar: {
        tools: {
          download:  true,
          selection: false,
          zoom:      false,
          zoomin:    false,
          zoomout:   false,
          pan:       false,
          reset:     false
        },
      },
      legend: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    grid:    { show: false },
    tooltip: {
      marker: { show: false }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      colors:   ['var(--rep-success)'],
      stepline: stepline
    },
    fill: {
      type:     'gradient',
      colors:   ['var(--rep-success)'],
      gradient: {
        gradientToColors: ['white', 'var(--rep-success-light)'],
        shadeIntensity:   1,
        opacityFrom:      1,
        opacityTo:        0.5,
      }
    },
  }

  return(
    <Chart
      options = {options}
      series  = {data}
      type    = 'area'
      style   = {{ borderRadius: '5px' }}
      height  = '100px'
      width   = '100%'
    />
  )
}

export default Line
