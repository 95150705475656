import styled from 'styled-components'

export const OrderTag = styled.div`
  display:       ${props => props.inline ? 'inline-flex' : 'flex'};
  align-items:   center;
  width:         auto;
  padding:       4px 8px;
  background:    ${props => props.background || 'transparent'};
  color:         ${props => props.color ? props.color : 'black'};
  border-radius: 5px;
  border:        ${props => props.border ? '1px' : '0px'} solid ${props => props.border ? props.border : 'black'};
  font-weight:   450;
  user-select:   none;
  box-sizing:    border-box;
  font-size:     .8rem;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};

  white-space:   nowrap;
  text-overflow: ellipsis;
  transition:    background .3s ease;

  &:hover {
    cursor: pointer;
  }
`

export const OrderTagIcon = styled.div`
  margin-left: 8px;
`
