import React, { useEffect, useState } from 'react'

import IUnit from '@interfaces/IUnit.d'

const UnitContextState = React.createContext(null)

const useUnitContextState = () => {
  const context = React.useContext(UnitContextState)

  if (context === undefined) {
    throw new Error('useUnitContextState was used outside of its Provider')
  }
  return context
}

interface UnitContextProviderProps {
  children:   React.ReactNode,
  serverUnit: IUnit
}

const UnitContextProvider: React.FC<UnitContextProviderProps> = ({ children, serverUnit }) => {

  const [unit, setUnit] = useState(serverUnit)

  useEffect(() => setUnit(currentUnit => currentUnit.id !== serverUnit.id ? serverUnit : currentUnit), [serverUnit])

  const updateUnit     = data     => {
    if (data.response)
      setUnit(_unit => data.response)
    else
      setUnit(data)
  }
  const machineStop    = data     => setUnit(unit => ({ ...unit, machine_stop: data }))
  const addDocument    = data     => setUnit(unit => ({ ...unit, documents: [data, ...unit.documents], }))
  const updateDocument = document => setUnit(unit => ({ ...unit, documents: unit.documents.map(d => d.id === document.id ? document : d) }))
  const removeDocument = data     => setUnit(unit => ({ ...unit, documents: unit.documents.filter(d => d.id !== data.id) }))

  const updateMethods = {
    unit:           updateUnit,
    addDocument:    addDocument,
    removeDocument: removeDocument,
    document:       updateDocument,
    machineStop:    machineStop,
  }

  return (
    <UnitContextState.Provider value={{
      unit, setUnit, updateMethods
    }}>
      {children}
    </UnitContextState.Provider>
  )
}

export { UnitContextProvider, useUnitContextState }
