import React, { useMemo, useState } from 'react'

import 'mapbox-gl/dist/mapbox-gl.css'

import Map, { Marker, Popup } from 'react-map-gl'

import Button           from '@components/Button'
import MeasureDetails   from '../Details'
import MeasureValueForm from './Form'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from '../style'

import IMeasure         from '@interfaces/IMeasure.d'
import IRequestResponse from '@interfaces/IRequestResponse.d'

interface MeasureLocationProps {
  measure:       IMeasure
  updateMethods: {
    measurment: (data: IRequestResponse) => void
  }
}

const MeasureLocation: React.FC<MeasureLocationProps> = ({ measure, updateMethods }) => {

  const { i18n, showModal } = useGlobalContextState()

  const [showPopup, setShowPopup] = useState(false)

  const color = useMemo(() => {
    if (measure.last_value?.state === 'danger') {
      return '--rep-danger'
    } else if (measure.last_value?.state === 'warning') {
      return '--rep-warning'
    } else {
      return '--rep-success'
    }
  }, [measure])

  return(
    <Style.MapBlock color='--rep-primary'>
      {measure.last_value
        ? <Map
          mapboxAccessToken = 'pk.eyJ1IjoiYmVydHJhbmRtb3JlbCIsImEiOiJjbGN1Z2o5a3cwbmk1M3dxdG91azJjZmZxIn0.mKd_D3yQCWmGnLC6TAvITw'
          initialViewState  = {{
            longitude: measure.last_value.content[1],
            latitude:  measure.last_value.content[0],
            zoom:      12
          }}
          style = {{
            position:     'absolute',
            borderRadius: '4px',
            top:          0,
            bottom:       0,
            width:        '100%',
            height:       '100%',
          }}
          mapStyle           = "mapbox://styles/mapbox/light-v11"
          attributionControl = {false}
        >
          <Marker
            longitude = {measure.last_value.content[1]}
            latitude  = {measure.last_value.content[0]}
            anchor    = "bottom"
            color     = {`var(${color})`}
            offset    = {[0, 0]}
            onClick   = {() => setShowPopup(popup => !popup)}
          />
          {showPopup &&
            <Popup
              longitude = {measure.last_value.content[1]}
              latitude  = {measure.last_value.content[0]}
              anchor    = "bottom"
              onClose   = {() => setShowPopup(false)}
            >
              {new Intl.DateTimeFormat(i18n.locale).format(new Date(measure.last_value.date))}
            </Popup>
          }
        </Map>
        : <>
          <div></div>
          <Style.Content>
            <div>No value</div>
          </Style.Content>
          <div></div>
        </>
      }
      <Style.MapHeader>
        <Style.MapName
          color={color}
          onClick  = {() => showModal({
            title:   i18n.t('measurment.history_of', { measurment: measure.name, equipment: ((measure.amenity && measure.amenity.name) || (measure.unit && measure.unit.name))}),
            content: <MeasureDetails measure = {measure}/>
          })}
        >
          {measure.name}
        </Style.MapName>
        <Button
          color      = 'var(--rep-primary)'
          background = 'white'
          icon       = {<FontAwesomeIcon icon="plus" />}
          hover      = {`var(${color}-light)`}
          click      = {() => showModal({
            title:   i18n.t('measurment.actions.add_value'),
            content: <MeasureValueForm
              measure       = {measure}
              updateMethods = {updateMethods}
            />
          })}
        />
      </Style.MapHeader>
    </Style.MapBlock>
  )
}

export default MeasureLocation
